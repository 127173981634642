<template>
  <div class="inner_body_wr">
    <div class="container">
      <h1>Умови та положення</h1>
      <p>
        Реєструючись на цьому веб-сайті, ви погоджуєтеся з Умовами обслуговування та Політикою конфіденційності. Якщо ви
        не погоджуєтеся, будь ласка, не відвідуйте та/або не використовуйте <b>agot-board-game.com</b>. Ми можемо
        змінювати ці умови час від часу, але ми зробимо все можливе, щоб повідомити вас. Однак, розумно переглядати їх
        регулярно, оскільки ваше продовження використання <b>agot-board-game.com</b> після змін означає, що ви
        погоджуєтеся з Умовами обслуговування та Політикою конфіденційності.
      </p>

      <p>
        Ви погоджуєтеся не публікувати будь-який зловмисний, непристойний, вульгарний, наклепницький, ненависний,
        загрозливий, сексуально орієнтований або будь-який інший матеріал, який може порушувати закони вашої країни,
        країни, де розміщено цей сайт, або міжнародні закони. Порушення може призвести до негайного та постійного бану.
        Як описано в нашій <a href="#privacy_policy">Політиці конфіденційності</a>, Ваша IP-адреса записується для
        забезпечення цих умов. Ви погоджуєтеся, що ми маємо право видаляти, редагувати або переміщати будь-який пост в
        будь-який час, якщо вважаємо це за потрібне.
      </p>

      <p>
        Як користувач, ви погоджуєтесь на зберігання будь-якої інформації, яку ви ввели, в базі даних. Хоча ця
        інформація не буде розголошена третім особам без вашої згоди, ні ми, ні хост веб-сайту, не несемо
        відповідальності за будь-які спроби зламу, які можуть призвести до компрометації даних.
      </p>

      <p>
        Будь ласка, прочитайте також наші <a href="#terms_of_use">Умови обслуговування</a>, щоб зрозуміти, що
        <b>agot-board-game.com</b> очікує від своїх користувачів.
      </p>

      <h2>Контактна інформація</h2>
      <p>Якщо у вас є будь-які питання або занепокоєння щодо умов, будь ласка, зв'яжіться з нами за адресою:</p>
      <p>Email: <strong>karasinho555@gmail.com</strong></p>

      <h2 id="terms_of_use">Умови використання та положення</h2>
      <div class="scroll_section">
        <p>Остання редакція: 20 вересня 2024 року</p>

        <h3>Загальна заява</h3>
        <p>
          Будь ласка, уважно прочитайте цю сторінку. Вона містить умови та положення щодо доступу та використання
          веб-сайту <b>agot-board-game.com</b> та його послуг:
        </p>

        <ol>
          <li>
            <strong>Прийняття</strong>
            <p>
              Ваш доступ та використання веб-сайту <b>agot-board-game.com</b> свідчать про ваше прийняття цих Умов та
              Положень. Якщо ви не приймаєте ці Умови та Положення, ви не повинні використовувати веб-сайт
              <b>agot-board-game.com</b>.
            </p>
          </li>
          <li>
            <strong>Оновлення Умов та Положень</strong>
            <p>
              <b>agot-board-game.com</b> може час від часу переглядати та змінювати ці Умови та Положення, оновлюючи цю
              сторінку. Дата останнього оновлення цих Умов та Положень буде вказана у верхній частині цієї сторінки.
              Ваше подальше використання веб-сайту <b>agot-board-game.com</b> після дати останнього оновлення свідчить
              про ваше прийняття оновлених Умов та Положень. Якщо ви не приймаєте оновлені Умови та Положення, ви не
              повинні продовжувати використовувати веб-сайт <b>agot-board-game.com</b>.
            </p>
          </li>
          <li>
            <strong>Поведінка зареєстрованих учасників</strong>
            <p>
              Зареєстровані учасники веб-сайту <b>agot-board-game.com</b> повинні дотримуватись відповідних Правил Гри
              та мають обов'язок поводитися розумно і справедливо по відношенню до інших зареєстрованих учасників у
              будь-якій грі, яка в даний час грається. Відповідні Правила Гри зазначені на сторінці
              <router-link :to="`/info_page`"> {{ $t('message.info') }}</router-link>
              веб-сайту <b>agot-board-game.com</b>, які можуть час від часу оновлюватися. Зареєстровані учасники не
              повинні прямо чи опосередковано порушувати обов'язок поводитися розумно і справедливо, включаючи, але не
              обмежуючися: образами інших учасників, виходом з гри або її зіпсуванням, "королівською грою" або
              використанням кількох облікових записів. Учасники слід повідомляти про порушення цих правил адміністрацію.
            </p>
          </li>
          <li>
            <strong>Припинення або призупинення використання</strong>
            <p>
              Адміністрація <b>agot-board-game.com</b> залишає за собою право припинити або призупинити доступ та
              використання веб-сайту будь-якого зареєстрованого учасника на свій розсуд, без надавання попередження або
              пояснення.
            </p>
          </li>
          <li>
            <strong>Запитання</strong>
            <p>
              Будь-які запитання щодо доступу та використання веб-сайту <b>agot-board-game.com</b> та його послуг
              повинні бути надіслані на: <strong>karasinho555@gmail.com</strong>.
            </p>
          </li>
          <li>
            <strong>Відмова від відповідальності</strong>
            <p>
              Адміністрація <b>agot-board-game.com</b> не може гарантувати безперервну функціональність веб-сайту і не
              несе відповідальності за будь-які пошкодження або втрати, що виникають внаслідок припинення
              функціональності.
            </p>
          </li>
        </ol>

        <p>© Авторське право 2024</p>

        <p>
          Інтелектуальна власність цих сторінок, а також будь-яка інформація та матеріали, що з'являються тут, належать
          здебільшого <b>Fantasy Flight Publishing, Inc.</b> Джерельний код та концепція цього веб-сайту є
          інтелектуальною власністю <b>agot-board-game.com</b>. Користувачі зобов'язуються не відтворювати, не
          змінювати, не розповсюджувати або не продавати вміст цього веб-сайту, включаючи його код, графіку, кнопки та
          логотипи, без явного дозволу.
        </p>
      </div>
      <h2>Інформація облікового запису та Політика конфіденційності</h2>
      <p>
        Ваш обліковий запис буде містити унікальний id, ім'я, особистий пароль і дійсну електронну адресу. Ваша
        інформація облікового запису захищена законами про захист даних, що діють у країні, де розташований сайт, та
        Загальним регламентом захисту даних ЄС (GDPR). Публічно буде відображатися лише ваше ім'я та аватари, які ви
        можете змінювати на свій розсуд.
      </p>

      <h2 id="privacy_policy">Політика конфіденційності <b>agot-board-game.com</b></h2>
      <div class="scroll_section">
        <p>Остання редакція: 20 вересня 2024 року</p>

        <h3>Заява про Політику конфіденційності</h3>
        <p>
          <b>agot-board-game.com</b> зобов'язується захищати вашу конфіденційність. Ми дотримуємося Загального
          регламенту захисту даних (GDPR) ЄС і прагнемо повністю відповідати йому. Ця Політика конфіденційності
          застосовується до <b>agot-board-game.com</b> та його піддоменів. Вона регулює збір, використання та обробку
          ваших даних на наших веб-сайтах та послугах. Вона не застосовується до жодних інших онлайн чи офлайн сайтів,
          продуктів чи послуг. Будь ласка, уважно прочитайте цю Політику конфіденційності, щоб зрозуміти, як ми збираємо
          та використовуємо ваші дані.
        </p>

        <h3>Особисті дані</h3>
        <p>
          Особисті дані - це будь-які дані, які можуть бути використані для прямої або непрямої ідентифікації особи.
          Прикладами є ваше ім'я, IP-адреса, електронна пошта або інші ідентифікатори. Ми збираємо мінімальні особисті
          дані для надання наших послуг та дотримання вимог GDPR. Збір та використання особистих даних чітко викладено в
          цій політиці.
        </p>

        <h3>Суб'єкт даних</h3>
        <p>
          Суб'єктом даних є будь-яка особа, чиї особисті дані збираються, зберігаються чи обробляються. Це включає
          користувачів нашого веб-сайту. Використовуючи <b>agot-board-game.com</b>, ви можете надати особисті дані, які
          ми обробляємо відповідно до GDPR.
        </p>

        <h3>Реєстрація облікового запису користувача</h3>
        <p>
          <b>agot-board-game.com</b> не збирає жодної інформації від відвідувачів. Тільки зареєстровані користувачі
          мають доступ до наших повних послуг. Для зареєстрованих користувачів ми збираємо мінімальну інформацію, таку
          як ім'я користувача, електронна адреса та основні дані сесії (наприклад, IP-адреса, тип браузера). Користувачі
          можуть видалити свої облікові записи та всі супутні дані в будь-який час.
        </p>

        <h3>Збір особистих даних</h3>
        <p>
          Як некомерційний сайт, <b>agot-board-game.com</b> лише збирає необхідні особисті дані, такі як ваша електронна
          адреса, ім'я користувача (яке може бути прізвиськом) та анонімізовані IP-адреси.
        </p>

        <h3>Використання особистих даних</h3>
        <p>
          Ваша електронна пошта використовується в основному для ідентифікації облікового запису, відновлення та
          скидання пароля. Також можуть надсилатися сповіщення для форумів, повідомлень або проблем у нашій системі на
          вашу електронну пошту.
        </p>

        <h3>Безпека та захист особистих даних</h3>
        <p>
          Ми використовуємо захищені веб-протоколи (HTTPS) для захисту ваших даних. Всі дані сесії та чутлива інформація
          зберігаються у зашифрованому вигляді для забезпечення безпеки ваших особистих даних. Сесії та файли cookie
          автоматично видаляються через 30 днів для безпеки.
        </p>

        <h3>Контроль над особистими даними</h3>
        <p>
          Ви маєте повний контроль над збором, використанням та розподілом своїх особистих даних. Ви можете зв'язатися з
          нами за адресою <strong>karasinho555@gmail.com</strong> з будь-якими запитами або запитами для отримання своїх
          особистих даних. Інформація буде надіслана на вашу зареєстровану електронну адресу. Ми забезпечимо обробку
          запитів на дані у відповідності до терміна, що відповідає GDPR, протягом чотирьох тижнів.
        </p>

        <h3>Право доступу суб'єкта даних</h3>
        <p>Ви маєте доступ до своїх особистих даних у будь-який час через налаштування свого облікового запису.</p>

        <h3>Право на заперечення та право на видалення</h3>
        <p>
          Згідно з GDPR, ви маєте право заперечувати проти обробки своїх даних. Ви можете скасувати реєстрацію або
          запросити видалення даних у будь-який час. Якщо ви запитуєте негайне видалення даних, зв'яжіться з нами,
          використовуючи нашу електронну пошту. Процес перевірки права власності на обліковий запис повинен бути
          завершений перед обробкою запиту.
        </p>

        <h3>Передача даних третім особам</h3>
        <p>
          <b>agot-board-game.com</b> НЕ передає ваші особисті дані третім особам і НЕ продає ваші дані. Ваша
          конфіденційність є нашим пріоритетом.
        </p>

        <h3>Відстеження користувачів</h3>
        <p>
          Ми лише локально відстежуємо користувачів через IP та файли cookie для забезпечення безпеки сесії та належного
          використання наших послуг. Відстеження обмежене забезпеченням наших Умов використання та захистом користувачів
          від несанкціонованого доступу до облікових записів.
        </p>

        <h3>Аналізуючі інструменти</h3>
        <p>
          <b>agot-board-game.com</b> не використовує стороннє аналітичне програмне забезпечення, таке як Google
          Analytics. Ми цінуємо вашу конфіденційність і вважаємо, що немає виправдань для відстеження наших користувачів
          без їхньої явної згоди.
        </p>

        <h3>Зовнішні посилання</h3>
        <p>
          Наш сайт може містити посилання на зовнішні веб-сайти. Ми не несемо відповідальності за зміст або Політику
          конфіденційності цих сайтів. Ми рекомендуємо вам переглядати Політики конфіденційності будь-якого зовнішнього
          сайту, який ви відвідуєте.
        </p>

        <h3>Донати через PayPal</h3>
        <p>
          Ми пропонуємо PayPal як метод для підтримки нашого проекту. Якщо ви виберете можливість донатів через PayPal,
          будь ласка, перегляньте Політику конфіденційності PayPal. Ви не зобов'язані використовувати PayPal, і ми також
          приймаємо донати Bitcoin та ETH для тих, хто віддає перевагу більшій конфіденційності.
        </p>

        <h3>Конфіденційність дітей</h3>
        <p>
          <b>agot-board-game.com</b> призначений для користувачів віком 16 років і старше, як це передбачено GDPR. Діти
          до 16 років повинні отримати дозвіл від свого законного представника для використання нашої платформи та
          подання особистих даних.
        </p>

        <h2>Контактна інформація</h2>
        <p>
          Якщо у вас є запитання щодо цієї Політики конфіденційності, будь ласка, зв'яжіться з нами за адресою
          <strong>karasinho555@gmail.com</strong>. Ми будемо раді відповісти на ваші запитання.
        </p>
      </div>

      <h3>Зміни до цієї Політики</h3>
      <p>
        <b>agot-board-game.com</b> залишає за собою право оновлювати цю Політику конфіденційності на основі змін
        законодавства або відгуків користувачів. Будь ласка, регулярно переглядайте цю Політику на предмет оновлень.
      </p>
    </div>
  </div>
</template>
<script>
  export default {}
</script>
<style lang="scss" scoped>
  .scroll_section {
    box-sizing: border-box;
    padding: 1rem;
    height: 75dvh;
    overflow: auto;
    background-color: rgb(172, 171, 171);
    -webkit-box-shadow: inset 0px 0px 31px -5px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: inset 0px 0px 31px -5px rgba(0, 0, 0, 0.75);
    box-shadow: inset 0px 0px 31px -5px rgba(0, 0, 0, 0.75);
    border-radius: 1rem;
  }

  .scroll_section::-webkit-scrollbar {
    width: 1rem;
  }

  .scroll_section::-webkit-scrollbar-track {
    background: grey;
    border-left: 0.45rem solid $theme_background;
    border-right: 0.45rem solid $theme_background; /* color of the tracking area */
  }

  .scroll_section::-webkit-scrollbar-thumb {
    background: grey; /* color of the scroll thumb */
    border-radius: 0.6rem; /* roundness of the scroll thumb */

    border-left: 0.3rem solid $theme_background;
    border-right: 0.3rem solid $theme_background;
  }
</style>
