<template>
  <div id="modal_base" class="modal_base" @click="close">
    <div class="games_log_container">
      <div class="table_wr">
        <table>
          <tr class="table-head">
            <th>#</th>
            <th>{{ $t(`message.date`) }}</th>
            <th>{{ $t(`message.game_name`) }}</th>
            <th>{{ $t(`message.game_type`) }}/{{ $t(`message.max_players`) }}</th>
            <th>{{ $t(`message.home`) }}</th>
            <th>{{ $t(`message.start_round`) }}</th>
            <th>{{ $t(`message.end_round`) }}</th>
            <th>{{ $t(`message.full`) }}</th>
            <th>{{ $t(`message.game_duration`) }}</th>
            <th>{{ $t(`message.result`) }}</th>
            <th>{{ $t(`message.money_earned`) }}</th>
          </tr>
          <tr v-for="(log, idx) in games_log" :key="log.id" class="log_wr">
            <td class="log_num">{{ idx + 1 }}</td>
            <td class="log_date">{{ log.time }}</td>
            <td class="log_name">{{ log.game_name }}</td>
            <td>{{ $t(`message.${log.game_type}`) }}/{{ log.players_count }}</td>
            <td class="house_token"><img :src="`/images/tokens/${log.home}_token.png`" :alt="log.home" /></td>
            <td>{{ log.start_round }}</td>
            <td>{{ log.end_round || 'playing' }}</td>
            <td :class="{ green_icon: log.full, full_icon: true }">
              <span class="material-icons">{{ getGameFullSighn(log.full) }}</span>
            </td>
            <td>{{ log.game_duration }}</td>
            <td>
              <div v-if="+log.result == 1" class="log_result_wr">
                <img src="/images/awards/award_icon.png" alt="award_icon" />
                <span>{{ log.result }}</span>
              </div>
              <span v-else>{{ log.result }}</span>
            </td>
            <td>{{ log.earned_money || '-' }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapMutations, mapGetters } from 'vuex'
  export default {
    data() {
      return {
        houses: ['stark', 'lannister', 'baratheon', 'greyjoy', 'tyrell', 'martell'],
      }
    },

    computed: {
      ...mapState('general_store', ['data_for_modal']),
      ...mapGetters('general_store', ['user']),

      processed_user() {
        return this.data_for_modal.processed_user
      },

      games_log() {
        if (!this.processed_user) return
        return this.processed_user.game_logs || []
      },
    },

    methods: {
      ...mapMutations('general_store', ['processModal']),
      close(e) {
        if (e.target.id !== 'modal_base') return
        this.processModal({ name: 'games_log', type: 'close' })
      },

      getGameFullSighn(flag) {
        if (flag) {
          return 'check_circle'
        }
        return 'dangerous'
      },
    },
  }
</script>

<style lang="scss" scoped>
  .modal_base {
    position: fixed;
    width: 100dvw;
    height: 100dvh;
    background-color: $modals_background_color;
    z-index: 21;
    top: 0;
    left: 0;
    pointer-events: all;
  }
  .games_log_container {
    display: flex;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 1rem;
    box-sizing: border-box;
    border-radius: 0.4rem;
    width: auto;
    max-width: $modals_max_width;
    max-height: $modals_max_height;
    height: 90dvh;
    align-items: center;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

    background-color: $modals_color;
    -webkit-box-shadow: -1px -4px 97px 31px rgba(163, 173, 97, 1);
    -moz-box-shadow: -1px -4px 97px 31px rgba(163, 173, 97, 1);
    box-shadow: -1px -4px 97px 31px rgba(163, 173, 97, 1);
  }

  .table_wr {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: auto;
  }
  table {
    position: relative;
    background-color: rgb(32, 7, 7);
    border-collapse: separate;
    border-spacing: 0;
    width: auto;
    font-size: 1rem;
  }

  th {
    color: rgb(199, 193, 237);
    padding: 0.5rem;
    position: sticky;
    z-index: 2;
    background: rgb(113, 29, 29);
    top: 0;
    border-top: 2px solid;
    border-bottom: 2px solid;
    border-right: 2px solid;
  }

  td {
    position: relative;
    color: white;
    text-align: center;
    vertical-align: center;
    padding: 0.5rem;
    border-bottom: 2px solid rgba(255, 255, 255, 0.625);
    border-right: 2px solid rgba(255, 255, 255, 0.625);
  }

  table th:first-child,
  table td:first-child {
    /* Apply a left border on the first <td> or <th> in a row */
    border-left: 2px solid rgba(255, 255, 255, 0.625);
  }

  .house_token {
    img {
      width: 30px;
    }
  }
  .full_icon {
    color: red;
  }
  .green_icon {
    color: green;
  }
  .log_name {
    color: rgb(209, 241, 52);
    font-size: 1rem;
  }

  .log_date {
    color: rgb(236, 107, 107);
  }
  .log_num {
    color: rgba(186, 184, 184, 0.737);
  }

  .log_result_wr {
    position: relative;
    img {
      width: 2rem;
    }
    span {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      color: black;
      font-size: 1.3rem;
      font-weight: 800;
    }
  }
</style>
