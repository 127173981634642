<template>
  <div class="top_tools_container prevent-select">
    <div class="tools_inner_container">
      <div class="left_side_tools">
        <span
          class="material-icons logout-btn tool_item"
          @click="logOutModalOpen"
          @pointerenter="hint_label_hover($event, { msg: 'logout' })"
          @pointerleave="resetHint"
        >
          logout
        </span>
        <span class="material-icons tool_item drop_area" @click="dropMenuFunc('show_menu')"> list </span>
        <div class="person_icon_wr">
          <img :src="`/images/avatars/${user?.avatars?.[0]}`" alt="ava" @click="processModal({ name: `person` })" />
        </div>
        <span class="tool_username" @click.prevent="routeToAdminInit"> {{ user?.name }}</span>
      </div>
      <div v-if="phase_name" class="tool_item phase_name_wr">
        <div class="phase_title_wr">
          <span v-if="phase_name === 'GameOver'" class="material-icons openModalIcon" @click="showInfoGameOverModal">
            info
          </span>
          <span v-else>{{ $t(`phase.${phase_name}`) }}</span>
        </div>
      </div>
      <div v-if="current_watch_id || current_game_id" class="right_side_tools">
        <div class="houses_drop_btn tool_item" @click="dropMenuFunc('show_houses')">
          <img
            class="home_img drop_area"
            :class="{ cursor_pointer: isAdmin }"
            :src="`/images/tokens/${_home || 'watcher'}_token.png`"
            alt="home"
          />
        </div>

        <span class="material-icons tool_item drop_area info_btn" @click="dropMenuFunc('show_game_info')"> info </span>
        <span
          class="material-icons tool_item"
          @click="leaveRoom(current_watch_id || current_game_id)"
          @pointerenter="hint_label_hover($event, { msg: 'LeaveGame', left: 50 })"
          @pointerleave="resetHint"
        >
          logout
        </span>
        <span
          v-if="!isWatcher"
          class="material-icons tool_item"
          @click="openDoor"
          @pointerenter="hint_label_hover($event, { msg: 'open_door_room', left: 140 })"
          @pointerleave="resetHint"
        >
          transfer_within_a_station
        </span>
      </div>
    </div>
    <div class="drop_menu_container drop_area" :class="{ show_menu }">
      <div class="audio_player">
        <div class="title_wr">
          <span class="song_title drop_area">{{ current_track_name }}</span>
          <div class="btns">
            <span @click="playPrevTrack" class="material-icons drop_area"> skip_previous </span>
            <span @click="playNextTrack" class="material-icons drop_area"> skip_next </span>
          </div>
        </div>

        <audio ref="main_sound" class="drop_area" controls @ended="playNextTrack"></audio>
      </div>
      <span class="material-icons tool_item drop_area" @click="processModal({ name: `sound_settings` })">
        music_note
      </span>

      <div class="local drop_area">
        <img src="/images/ukraine.png" alt="uk" class="ukraine drop_area" />
        <input v-model="eng_lang" type="checkbox" class="check drop_area" @change="changeLocale" />
        <img src="/images/united-kingdom.png" alt="us" class="britain drop_area" />
      </div>
      <div class="btns_wr drop_area">
        <div v-if="inside_room" class="tool_item drop_area">
          <span
            class="material-icons drop_area"
            @click="rotateMap"
            @pointerenter="hint_label_hover($event, { msg: 'rotate_map' })"
            @pointerleave="resetHint"
          >
            crop_rotate
          </span>
        </div>
        <div class="tool_item drop_area">
          <span
            class="material-icons drop_area"
            @click="reloadPage"
            @pointerenter="hint_label_hover($event, { msg: 'page_reload' })"
            @pointerleave="resetHint"
          >
            restore_page
          </span>
        </div>
        <div v-if="inside_room" class="tool_item drop_area">
          <span
            class="material-icons drop_area"
            @click="centerMap"
            @pointerenter="hint_label_hover($event, { msg: 'center_map' })"
            @pointerleave="resetHint"
          >
            filter_center_focus
          </span>
        </div>
        <div v-if="isAdmin && inside_room" class="tool_item drop_area">
          <span class="material-icons drop_area" @click="startTime"> timer </span>
        </div>
        <div v-if="isAdmin && inside_room" class="tool_item drop_area">
          <span class="material-icons drop_area" @click="stopTime"> alarm_off </span>
        </div>
        <div v-if="isAdmin && inside_room" class="tool_item drop_area">
          <span class="material-icons drop_area" @click="startGame"> restart_alt </span>
        </div>
        <div v-if="inside_room" class="tool_item drop_area">
          <span class="material-icons drop_area" @click="setTempState({ direction: `reset`, game })">
            mode_standby
          </span>
        </div>
        <div v-if="inside_room" class="tool_item drop_area">
          <span class="material-icons drop_area" @click="setTempState({ direction: `back`, game })"> west </span>
        </div>
        <div v-if="inside_room" class="tool_item drop_area">
          <span class="material-icons drop_area" @click="setTempState({ direction: `forward`, game })"> east </span>
        </div>
        <div v-if="isAdmin && inside_room" class="tool_item drop_area">
          <span class="material-icons drop_area" @click="applyHistoryState"> approval </span>
        </div>
      </div>
    </div>
    <div v-if="game" class="houses_drop_container drop_area" :class="{ show_houses }">
      <div
        v-for="h in houses"
        :key="h"
        class="btn drop_area"
        :class="{ inactive: !active_players.includes(h) }"
        :style="{
          transform: h === home ? 'scale(1.4)' : 'none',
        }"
        @click="changePlayer(h)"
      >
        <img :src="`/images/tokens/${h}_token.png`" alt="home" class="drop_area" />
      </div>
    </div>

    <div v-if="game" class="drop_info_container drop_area" :class="{ show_game_info }">
      <span class="game_name">{{ this.game?.game_options.name }}</span>
      <span class="game_status">{{ $t(`message.${this.game?.state.status}`) }}</span>
      <div class="main_info">
        <div
          v-for="field in info_fields"
          :key="field[0]"
          class="info_field prevent-select"
          @pointerenter="hint_label_hover($event, { msg: field[0] })"
          @pointerleave="resetHint"
        >
          <span class="material-icons" :style="{ color: field[2] }">{{ field[1] }}</span>
          <span class="span">{{ getFieldData(field[0], game) }}</span>
        </div>
      </div>
      <div class="open_cards_heep_btn drop_area" @click="processModal({ name: 'all_cards' })">
        {{ $t(`message.all_cards`) }}
      </div>

      <!-- <span>{{ JSON.stringify(this.game.game_options, null, 4) }}</span> -->
    </div>
  </div>
</template>

<script>
  import { searching_timer } from '#static_data'
  import { mapGetters, mapMutations, mapState } from 'vuex'

  export default {
    setup() {
      function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1))
          ;[array[i], array[j]] = [array[j], array[i]]
        }
        return array
      }
      const tracks = [
        { src: '/audio/bleed_it_out.mp3', name: 'Bleed It Out' },
        { src: '/audio/crawling.mp3', name: 'Crawling' },
        { src: '/audio/easier_to_run.mp3', name: 'Easier To Run' },
        { src: '/audio/faint.mp3', name: 'Faint' },
        { src: '/audio/from_the_inside.mp3', name: 'From The Inside' },
        { src: '/audio/in_the_end.mp3', name: 'In The End' },
        { src: '/audio/lying_from_you.mp3', name: 'Lying From You' },
        { src: '/audio/numb.mp3', name: 'Numb' },
        { src: '/audio/one_more_light.mp3', name: 'One More Light' },
        { src: '/audio/papercut.mp3', name: 'Papercut' },
        { src: '/audio/somewhere_i_belong.mp3', name: 'Somewhere I Belong' },
        { src: '/audio/the_messanger.mp3', name: 'The Messanger' },
        { src: '/audio/until_its_gone.mp3', name: "Until It's Gone" },
        { src: '/audio/waiting_for_the_end.mp3', name: 'Waiting For The End' },
        { src: '/audio/what_ive_done.mp3', name: "What I've Done" },
      ]
      return {
        tracks: shuffleArray(tracks),
      }
    },
    inject: ['socket'],
    emits: ['exec'],
    data() {
      return {
        screenWidth: window?.innerWidth || '500',
        hint_delay: 250,
        clear_hint_delay: null,
        hint_timer_delay: null,
        viewportWidth: '',
        show_menu: false,
        show_game_info: false,
        show_houses: false,
        eng_lang: false,
        info_fields: [
          ['bank.buy_in', 'paid', 'green'],
          ['password', 'lock', 'white'],
          ['time_to_move', 'more_time', '#babae2'],
          ['timebank', 'query_builder', '#babae2'],
          ['random_force', 'library_add', 'red'],
          ['scull', 'cancel', 'white'],
          ['charm', 'favorite', 'red'],
          ['intelligence', 'psychology', '#babae2'],
          ['honor', 'stars', 'orange'],
        ],

        audio_elements: [],

        current_track_index: 0,
        current_track_name: '',
        currently_playing: null,
        paused_time: 0,
        audio_loaded: false,
      }
    },

    computed: {
      ...mapGetters('general_store', [
        'game',
        'phase_name',
        'current_game_id',
        'inside_room',
        'active_players',
        'isHistoryModeOn',
        'isAdmin',
        'user',
        'home',
        'inside_watch_room',
        'show_game_map',
        'current_watch_id',
      ]),
      ...mapState('general_store', ['sid', 'timers', 'temp_gui_state']),
      ...mapState('gui_store', ['mapPosition', 'freeze']),
      searching() {
        return this.game?.state.status === 'searching'
      },
      isMyTurn() {
        if (this.phase_name === 'GameOver') return false
        if (this.active_players?.includes(this.home) && !this.isHistoryModeOn && !this.freeze.freeze) {
          return true
        }
        return false
      },

      houses() {
        const houses = this.game?.state.states.map((st) => st.home)
        return houses
      },
      user_locale() {
        return this.user?.locale
      },

      searching_timer() {
        return this.formatTimer(this.timers[this.game.id] || searching_timer)
      },

      isWatcher() {
        return this.home === 'watcher' || this.inside_watch_room
      },
      _home() {
        const cur_watching_game = this.user.current_games.find((g) => g.room_id === this.current_watch_id)
        if (cur_watching_game) {
          return cur_watching_game.home
        }
        return this.home
      },
      sound() {
        return this.user?.sound_settings
      },
    },

    mounted() {
      this.eng_lang = this.user?.locale === 'uk' ? false : true

      document.addEventListener(
        'click',
        function (e) {
          if (!e.target.classList.contains('drop_area')) {
            this.show_menu = false
            this.show_houses = false
            this.show_game_info = false
          }
        }.bind(this)
      )

      this.audio_elements = this.tracks.map((track) => {
        const audio = new Audio(track.src)
        audio.preload = 'auto'
        return audio
      })

      this.$refs.main_sound.addEventListener(
        'canplaythrough',
        () => {
          this.audio_loaded = true
          this.updateTrack()
          this.playAudio()
        },
        { once: true }
      )
      this.updateTrack()
    },

    watch: {
      sound: {
        handler(newSettings, oldSettings) {
          if (!oldSettings || !newSettings) return
          const changedKeys = Object.keys(newSettings).reduce((acc, key) => {
            if (newSettings[key] !== oldSettings[key]) {
              acc.push(key)
            }
            return acc
          }, [])
          changedKeys.forEach((key) => {
            if (key !== 'main') return
            if (newSettings[key]) {
              this.playAudio()
            } else {
              this.pauseAudio()
            }
          })
        },
        deep: true,
      },
    },

    methods: {
      ...mapMutations('gui_store', ['setMainSideTab', 'setHintLabel', 'setMapPosition', 'setFreeze', 'setGameSideTab']),
      ...mapMutations('general_store', [
        'setTempState',
        'setPlayer',
        'processModal',
        'setDataForModal',
        'setInsideRoom',
      ]),

      updateTrack() {
        this.current_track_name = `${this.tracks[this.current_track_index].name}`
        this.$refs.main_sound.src = this.tracks[this.current_track_index].src
      },

      playAudio() {
        if (!this.sound?.main) return
        if (!this.audio_loaded) return
        if (this.$refs.main_sound.src !== this.tracks[this.current_track_index].src) {
          this.updateTrack()
        }

        const current_audio = this.$refs.main_sound

        if (this.currently_playing) {
          this.currently_playing.pause()
          this.currently_playing.currentTime = 0 // Reset the current time
        }

        if (this.paused_time > 0) {
          current_audio.currentTime = this.paused_time
          this.paused_time = 0 // Reset paused time after resuming
        }

        current_audio.play()
        this.currently_playing = current_audio
      },

      pauseAudio() {
        this.$refs.main_sound.pause()
        this.paused_time = this.currently_playing?.currentTime || 0
      },

      playNextTrack() {
        // Pause any currently playing audio
        if (this.currently_playing) {
          this.currently_playing.pause()
          this.currently_playing.currentTime = 0 // Reset the current time
        }

        // Update the current track index
        this.current_track_index = this.current_track_index + 1
        if (this.current_track_index > this.tracks.length - 1) {
          this.current_track_index = 0
        }
        this.updateTrack()

        // Play the new track
        this.playAudio()
      },
      playPrevTrack() {
        // Pause any currently playing audio
        if (this.currently_playing) {
          this.currently_playing.pause()
          this.currently_playing.currentTime = 0 // Reset the current time
        }

        // Update the current track index
        this.current_track_index = this.current_track_index - 1
        if (this.current_track_index < 0) {
          this.current_track_index = this.tracks.length - 1
        }
        this.updateTrack()

        // Play the new track
        this.playAudio()
      },

      routeToAdminInit(e) {
        if (e.shiftKey && (e.ctrlKey || e.metaKey)) {
          this.$router.push({ name: 'admin_init' })
          return
        }
        if (e.shiftKey) {
          this.$router.push({ name: 'home' })
          return
        }
        this.processModal({ name: `person` })
      },

      reloadPage() {
        window.location.reload()
      },
      centerMap() {
        const map = document.getElementById('zoom')
        const map_styles = window.getComputedStyle(map)
        const transform_matrix = map_styles.getPropertyValue('transform')
        const matrixValues = transform_matrix.match(/matrix\(([^)]+)\)/)[1].split(', ')
        const scale = matrixValues[0]
        const map_width = parseFloat(map_styles.getPropertyValue('width'))
        const map_height = parseFloat(map_styles.getPropertyValue('height'))
        const window_width = window.innerWidth
        const window_height = window.innerHeight
        const map_width_after = map_width * scale
        const map_height_after = map_height * scale
        const height_delta = window_height + 40 - map_height_after

        this.setMapPosition({
          x: window_width / 2 - map_width_after / 2,
          y: height_delta / 2,
          scale,
          centerMap: true,
        })
      },

      rotateMap() {
        const rotate_value = this.mapPosition.rotate
        let res = rotate_value + 90
        if (res > 360) {
          res = 0
          this.setMapPosition({
            rotate: 0,
          })

          return
        }
        this.setMapPosition({
          rotate: res,
        })
      },

      changePlayer(home) {
        if (!this.isAdmin) return
        if (home === this.home) return
        this.setPlayer({
          game_id: this.current_game_id,
          user: this.user,
          home,
        })
        this.show_houses = false
      },

      showInfoGameOverModal(e) {
        if (this.phase_name !== 'GameOver') return
        this.processModal({ name: 'game_over' })
      },

      formatTimer(duration) {
        const hrs = ~~(duration / 3600)
        const mins = ~~((duration % 3600) / 60)
        const secs = ~~duration % 60

        // Output like "1:01" or "4:03:59" or "123:03:59"
        let ret = ''

        if (hrs > 0) {
          ret += '' + hrs + ':' + (mins < 10 ? '0' : '')
        }

        ret += '' + mins + ':' + (secs < 10 ? '0' : '')
        ret += '' + secs

        return ret
      },

      changeLocale(e) {
        const locale = this.eng_lang ? 'en' : 'uk'
        this.socket.emit('client_action', {
          action_type: 'SetLocale',
          data: {
            user_id: this.user.id,
            locale,
          },
        })
      },

      logOutModalOpen() {
        this.setDataForModal({
          data: { action_type: 'LeaveApp', sid: this.sid, processed_user: this.user },
        })
        this.processModal({ name: 'leave_room' })
      },

      leaveRoom(room_id) {
        if (this.isWatcher) {
          this.socket.emit('client_action', {
            action_type: 'LeaveGame',
            data: {
              room_id,
              user_id: this.user.id,
              processed_user: this.user,
              sid: this.sid,
            },
          })
          return
        }

        this.setDataForModal({
          data: { action_type: 'LeaveGame', room_id, processed_user: this.user, sid: this.sid },
        })
        this.processModal({ name: 'leave_room' })
      },

      openDoor() {
        const cur_game = this.user.current_games.find((g) => g.room_id === this.current_game_id)
        if (!cur_game || cur_game.sid !== this.sid) return

        this.socket.emit('client_action', {
          action_type: 'OpenDoor',
          data: {
            room_id: this.current_game_id,
            user_id: this.user.id,
          },
        })
      },

      resetHint() {
        clearTimeout(this.hint_timer_delay)
        clearTimeout(this.clear_hint_delay)

        this.clear_hint_delay = null
        this.hint_timer_delay = null
        this.setHintLabel({ reset: true })
      },
      hint_label_hover(e, item) {
        if (e.pointerType !== 'mouse') return
        if (!item) return
        this.resetHint()

        this.hint_timer_delay = setTimeout(() => {
          const coords = e.target.getBoundingClientRect()
          const hint_max_width = 140
          let delta = hint_max_width - (window.innerWidth - coords.left)
          if (delta <= 0) {
            delta = 0
          }

          const gap_from_top = 40

          const logYpos = `translate(${+coords.left - delta}px, ${coords.top + gap_from_top}px)`
          this.setHintLabel({
            msg: item.msg,
            coords: logYpos,
          })

          this.clear_hint_delay = setTimeout(() => {
            this.resetHint()
          }, 2000)
        }, this.hint_delay)
      },
      startGame() {
        if (this.freeze.freeze) return
        this.socket.emit('client_action', {
          action_type: 'RedactorApply',
          data: {
            phase_name: 'redactor',
            data: {
              start: true,
              room_id: this.game.id,
            },
          },
        })
        this.setFreeze({ freeze: true, color: 'pink' })
      },

      applyHistoryState() {
        if (!this.temp_gui_state.snapshot_id) return
        this.socket.emit('client_action', {
          action_type: 'RedactorApply',
          data: {
            phase_name: 'redactor',
            data: {
              history_snapshot_id: this.temp_gui_state.snapshot_id,
              room_id: this.game.id,
            },
          },
        })
        this.setTempState({ direction: 'reset', game: this.game })
        this.setFreeze({ freeze: true, color: 'pink' })
      },

      stopTime() {
        if (this.freeze.freeze) return
        this.socket.emit('client_action', {
          action_type: 'RedactorApply',
          data: {
            phase_name: 'redactor',
            data: {
              stopTime: true,
              room_id: this.game.id,
            },
          },
        })

        this.setFreeze({ freeze: true, color: 'pink' })
      },

      startTime() {
        if (this.freeze.freeze) return
        this.socket.emit('client_action', {
          action_type: 'RedactorApply',
          data: {
            phase_name: 'redactor',
            data: {
              startTime: true,
              room_id: this.game.id,
            },
          },
        })

        this.setFreeze({ freeze: true, color: 'pink' })
      },

      dropMenuFunc(key) {
        if (key === 'show_houses') {
          if (!this.isAdmin) return
          if (!this.home || this.home === 'watcher') return
          if (!this.show_houses) {
            this.show_menu = false
            this.show_game_info = false
            this.show_houses = true
          } else {
            this.show_houses = false
          }
        } else {
          if (this[key]) {
            this[key] = false
          } else {
            this.show_menu = false
            this.show_game_info = false
            this.show_houses = false
            this[key] = true
          }
        }
      },

      getFieldData(key, game) {
        if (!game) return
        // const game = this.rooms.find((r) => r.id === room_id);
        let key_1 = key,
          key_2
        if (key.includes('.')) {
          const keys = key.split('.')
          key_1 = keys[0]
          key_2 = keys[1]
        }
        let data
        if (key_2) {
          data = game.game_options[key_1][key_2]
        } else {
          data = game.game_options[key_1]
        }

        if (key_1 === 'password' && data) {
          return '***'
        }
        if (key_1 === 'random_force' && data === '0') {
          if (game.game_options.scull) {
            return '0'
          }
          return '-'
        }

        if (key_1 !== 'charm' && (!data || data === '0')) {
          return '-'
        }
        return data
      },
    },
  }
</script>

<style lang="scss" scoped>
  .top_tools_container {
    box-sizing: border-box;
    position: absolute;
    z-index: 30;
    width: 100%;
    pointer-events: all;
  }

  .tools_inner_container {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    height: $nav_buttons_top_height;
    background-color: $theme_background;
    z-index: 29;
    pointer-events: all;
    padding-top: env(safe-area-inset-top);
    padding-right: env(safe-area-inset-right);
    padding-left: env(safe-area-inset-left);

    @media (min-aspect-ratio: 4/3) {
      height: $nav_buttons_top_height_shrinked;
    }

    .left_side_tools {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 1rem;
      width: 30%;
      color: white;
      @media screen and (max-width: 400px) {
        justify-content: flex-start;
        gap: 0.6rem;
      }

      .person_icon_wr {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        cursor: pointer;
        height: 100%;
        width: fit-content;
        img {
          height: 80%;
          border-radius: 0.2rem;
        }
      }

      .tool_username {
        display: block;
        color: white;
        pointer-events: all;
        font-size: 1rem;
        cursor: pointer;
        @media screen and (max-width: 800px) {
          font-size: 0.8rem;
        }
        @media screen and (max-width: 500px) {
          display: none;
        }
      }
    }

    .phase_name_wr {
      width: fit-content;
      max-width: 40%;
      display: flex;
      color: rgb(147, 221, 255);
      align-items: center;
      justify-content: center;
      overflow: visible;
      // white-space: nowrap;
      // gap: 1rem;
      .openModalIcon {
        cursor: pointer;
        color: rgb(129, 214, 235);
      }
      .phase_title_wr {
        text-align: center;
        span {
          font-size: 1rem;
          @media (max-aspect-ratio: 4/3) and (max-width: 600px) {
            font-size: 0.9rem;
          }
        }
      }
    }

    .right_side_tools {
      box-sizing: border-box;
      display: flex;
      width: 30%;
      align-items: center;
      justify-content: flex-end;
      gap: 1rem;
      color: white;

      @media (max-aspect-ratio: 4/3) {
        gap: 0.7rem;
      }

      span {
        display: block;
        cursor: pointer;
      }
      .houses_drop_btn {
        height: 100%;
        box-sizing: border-box;
        text-align: center;
        display: flex; /* Enable flexbox */
        align-items: center; /* Center vertically */
        justify-content: center;
        width: fit-content;

        .home_img {
          height: 80%;
          overflow: hidden;
          @media (max-aspect-ratio: 4/3) {
            height: 60%;
          }
        }
      }
      .info_btn {
        @media (max-aspect-ratio: 4/3) {
          display: none;
        }
      }
      .cursor_pointer {
        cursor: pointer;
      }
    }
  }

  .drop_info_container {
    box-sizing: border-box;
    position: absolute;
    z-index: 18;
    display: flex;
    flex-direction: column;
    justify-content: center;

    align-items: center;
    gap: 0.4rem;
    height: fit-content;
    max-height: 100dvh;
    padding: 1rem;
    padding-top: $nav_buttons_top_height;
    transform: translateY(-100%);
    right: 3rem;
    background-color: $theme_background;
    border-radius: $theme_border_radius;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    transition: all 0.35s;
    color: white;
    .game_name {
      font-size: 1.1rem;
      word-break: break-all;
    }
    .game_status {
      font-size: 1rem;
      word-break: break-all;
    }
    .main_info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      overflow-y: auto;
      overflow-x: hidden;
      flex-wrap: wrap;
      gap: 0.5rem;
      width: 100%;

      .info_field {
        color: White;
        display: flex;
        align-items: center;
        width: 20%;
        gap: 1rem;
        justify-content: space-between;
        span {
          font-size: 1rem;
        }
        .span {
          font-size: 1rem;
        }
      }
    }
    .open_cards_heep_btn {
      cursor: pointer;
      background-color: rgb(103, 103, 185);
      padding: 0.7rem;
      border-radius: $theme_border_radius;
      &:hover {
        background-color: rgb(51, 91, 137);
      }
    }
  }

  .drop_menu_container {
    box-sizing: border-box;
    width: 100%;
    position: absolute;
    z-index: 18;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding-top: $nav_buttons_top_height;
    padding-bottom: 0.4rem;
    transform: translateY(-100%);
    // background-color: #b47272;
    background-color: $theme_background;
    border-radius: $theme_border_radius;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    transition: transform 0.35s;

    @media (min-aspect-ratio: 4/3) {
      flex-direction: column;
    }

    .audio_player {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 0.5rem;
      .title_wr {
        align-self: center;
        display: flex;
        align-items: center;
        gap: 1rem;
        .btns {
          display: flex;
          span {
            font-size: 2rem;
            color: white;
            cursor: pointer;
            &:hover {
              color: rgb(160, 159, 159);
            }
          }
        }
        .song_title {
          font-size: 1.2rem;
          text-align: center;
          color: rgb(93, 154, 218);
        }
      }
    }

    .local {
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: fit-content;
      height: auto;
      gap: 0.4rem;

      img {
        width: 2rem;
      }
    }
    .check {
      cursor: pointer;
      position: relative;
      width: 2rem;
      &:before {
        content: '';
        position: absolute;
        width: 2.4rem;
        height: 1.4rem;
        background-color: #ecea6c;
        border-radius: 1rem;
        top: -3px;
        left: -3px;
      }
      &:checked:before {
        background: #00a1ff;
      }
      &:checked:after {
        left: 0.8rem;
        border: 2px solid #00a1ff;
      }

      &:after {
        content: '';
        position: absolute;
        left: -3px;
        top: -3px;
        width: 1.4rem;
        height: 1.4rem;
        background-color: #fff;
        border-radius: 1rem;
        border: 2px solid #ecea6c;
        transition: 0.25s;
        box-sizing: border-box;
      }
    }
    .tool_item {
      color: white;
      font-size: 1.5rem;
      cursor: pointer;
      overflow: visible;
      &:hover {
        color: rgb(228, 101, 101);
      }
    }

    .btns_wr {
      display: flex;
      transition: all 0.5s linear;
      width: 100%;
      justify-content: space-around;
    }
  }

  .houses_drop_container {
    box-sizing: border-box;
    position: absolute;
    z-index: 18;
    transform: translateY(-100%);
    right: 4rem;
    transition: all 0.35s;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.4rem;
    padding: 0.5rem;
    padding-top: $nav_buttons_top_height;
    border-radius: $theme_border_radius;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-color: $theme_background;

    @media (min-aspect-ratio: 4/3) {
      right: 7rem;
    }

    .btn {
      transition: transform 250ms;
      position: relative;
      img {
        height: 1.5rem;
      }

      &:hover {
        cursor: pointer;
      }
    }
    .inactive::before {
      position: absolute;
      width: 1.5rem;
      height: 1.5rem;
      left: 0;
      top: 0;
      content: '';
      background-image: url('/images/forbidden.png');
      background-size: 1.5rem 1.5rem;
      background-repeat: no-repeat;
      pointer-events: none;
    }
  }

  .show_menu {
    transform: translateY(0);
  }
  .show_houses {
    transform: translateY(0);
  }
  .show_game_info {
    transform: translateY(0);
  }

  .game_over_btn {
    width: auto;
    cursor: pointer;
  }

  .prevent-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }

  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.35s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }

  .slide-enter-active,
  .slide-leave-active {
    transition: all 0.5s;
    opacity: 1;
  }
  .slide-enter,
  .slide-leave-to {
    transform: translateY(0);
    opacity: 0;
  }
</style>
