<template>
  <div id="modal_base" class="modal_base" @click="closeModal">
    <div class="modal_wraper">
      <div class="round_wr">
        <img src="/images/round_token.png" alt="round" />
        <span>{{ game?.state?.phase.round }}</span>
      </div>
      <div class="cards_wr">
        <img v-for="n of 3" :key="n" :src="getWesterosCard(n)" alt="card" />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapMutations, mapState } from 'vuex'

  export default {
    computed: {
      ...mapState('general_store', ['data_for_modal']),
      ...mapGetters('general_store', ['mainRoom', 'game', 'user']),

      locale() {
        return this.user.locale
      },
    },
    methods: {
      ...mapMutations('general_store', ['processModal']),

      closeModal(e) {
        if (e.target.id !== 'modal_base') return
        this.processModal({ name: 'round_changed', type: 'close' })
      },
      getWesterosCard(n) {
        if (this.game?.state[`westeros_${n - 1}`]) {
          return `/images/westeros_cards/westeros_${n - 1}/${this.locale}/${this.game?.state[`westeros_${n - 1}`]}.png`
        } else {
          return `/images/westeros_cards/westeros_${n - 1}/westeros_${n - 1}_back.png`
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .modal_base {
    position: absolute;
    width: 100dvw;
    height: 100dvh;
    background-color: $modals_background_color;
    pointer-events: all;
    z-index: 300;
    top: 0;
    left: 0;
  }
  .modal_wraper {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: absolute;
    left: 50%;
    top: 50%;
    padding: 1rem;
    transform: translate(-50%, -50%);
    width: 90dvw;
    height: 90dvh;
    max-width: 90dvw;
    max-height: $modals_max_height;

    justify-content: flex-start;
    align-items: center;
    // background-color: $modals_color;
    background-color: rgb(155, 92, 92);
    border-radius: 0.5rem; // border: 4px solid rgb(174, 173, 173);
    -webkit-box-shadow: -1px -4px 97px 31px rgba(111, 138, 179, 1);
    -moz-box-shadow: -1px -4px 97px 31px rgba(111, 138, 179, 1);
    box-shadow: -1px -4px 97px 31px rgba(111, 138, 179, 1);

    @media (min-aspect-ratio: 3/4) {
      min-height: 60%;
      height: fit-content;
    }
  }
  .cards_wr::-webkit-scrollbar {
    display: none;
  }

  .cards_wr {
    scrollbar-width: none;
  }

  .round_wr {
    height: 10%;
    display: flex;
    align-items: center;
    color: white;
    img {
      width: 3rem;
    }
    span {
      font-size: 2rem;
    }
  }

  .cards_wr {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    width: 100%;
    height: 90%;
    align-items: center;
    justify-content: center;
    @media (max-aspect-ratio: 3/4) {
      flex-direction: column;
    }
    img {
      height: 30%;
      width: auto;
      @media (min-aspect-ratio: 3/4) {
        height: auto;
        width: 30%;
        background-color: rgb(70, 177, 88);
      }
    }
  }
</style>
