<template>
  <div id="confirm_modal_wr" class="confirm_modal_wr" @click="close">
    <div class="confirm_wr">
      <div v-if="data_for_modal.select_home" class="confirm-item">
        <span>{{ $t(`message.select_home`) }}:</span>
        <span>{{ price.select_home }}</span>
      </div>
      <div class="confirm-item">
        <span>{{ $t(`message.buyin`) }}:</span>
        <span>{{ data_for_modal.buyin }}</span>
      </div>

      <button class="button" role="button" @click="confirm">{{ $t(`message.pay`) }}</button>
    </div>
  </div>
</template>

<script>
  import { price } from '#static_data'
  import { mapMutations, mapState } from 'vuex'

  export default {
    inject: ['socket'],

    data() {
      return {
        price,
      }
    },

    computed: {
      ...mapState('general_store', ['data_for_modal']),
    },

    methods: {
      ...mapMutations('general_store', ['setDataForModal', 'processModal']),
      close(e) {
        if (e.target.id !== 'confirm_modal_wr') return
        this.data_for_modal?.cb(false)

        this.processModal({ name: 'join_game', type: 'close' })
      },
      confirm() {
        this.data_for_modal?.cb(true)

        this.processModal({ name: 'join_game', type: 'close' })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .confirm_modal_wr {
    position: fixed;
    width: 100dvw;
    height: 100dvh;
    pointer-events: all;
    background-color: $modals_background_color;
    z-index: 21;
    top: 0;
  }
  .confirm_wr {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    box-sizing: border-box;
    border-radius: 0.4rem;
    left: 50%;
    top: 50%;
    padding: 2rem;
    transform: translate(-50%, -50%);
    width: auto;
    height: auto;
    max-width: $modals_max_width;
    max-height: $modals_max_height;
    gap: 1rem;
    background-color: $modals_color;
    align-items: center;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

    -webkit-box-shadow: -1px -4px 97px 31px rgb(32, 111, 71);
    -moz-box-shadow: -1px -4px 97px 31px rgb(32, 111, 71);
    box-shadow: -1px -4px 97px 31px rgb(32, 111, 71);
  }

  @media (max-width: 500px) {
    .confirm_wr {
      width: 85dvw;
    }
  }
  .confirm-item {
    font-size: 1.3rem;
    color: gold;
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 1rem;
  }

  .button {
    background-color: #c2fbd7;
    border-radius: 2rem;
    box-shadow: rgba(44, 187, 99, 0.2) 0 -25px 18px -14px inset, rgba(44, 187, 99, 0.15) 0 1px 2px,
      rgba(44, 187, 99, 0.15) 0 2px 4px, rgba(44, 187, 99, 0.15) 0 4px 8px, rgba(44, 187, 99, 0.15) 0 8px 16px,
      rgba(44, 187, 99, 0.15) 0 16px 32px;
    color: green;
    cursor: pointer;
    display: inline-block;
    font-family: CerebriSans-Regular, -apple-system, system-ui, Roboto, sans-serif;
    padding: 7px 20px;
    text-align: center;
    text-decoration: none;
    transition: all 250ms;
    border: 0;
    font-size: 1rem;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }

  .button:hover {
    box-shadow: rgba(44, 187, 99, 0.35) 0 -25px 18px -14px inset, rgba(44, 187, 99, 0.25) 0 1px 2px,
      rgba(44, 187, 99, 0.25) 0 2px 4px, rgba(44, 187, 99, 0.25) 0 4px 8px, rgba(44, 187, 99, 0.25) 0 8px 16px,
      rgba(44, 187, 99, 0.25) 0 16px 32px;
    transform: scale(1.05) rotate(-1deg);
  }
</style>
