<template>
  <div id="modal_base" class="modal_base" @click="close">
    <div class="confirm_wr">
      <h2>{{ $t(`message.delete_user`) }}</h2>
      <div class="user_wr">
        <img :src="`/images/avatars/${processed_user.avatars[0]}`" alt="money" />
        <span>{{ processed_user.name }}</span>
      </div>
      <div class="btns-wr">
        <button class="btn_confirm" @click="confirm">{{ $t(`message.delete`) }}</button>
        <button class="btn_deny" @click="deny">
          {{ $t(`message.cancel`) }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapMutations, mapState, mapGetters } from 'vuex'
  import { price } from '#static_data'
  export default {
    inject: ['socket'],

    emits: ['close'],

    data() {
      return {
        price,
      }
    },

    computed: {
      ...mapState('general_store', ['data_for_modal']),
      ...mapGetters('general_store', ['user']),

      processed_user() {
        return this.data_for_modal?.processed_user
      },
    },

    methods: {
      ...mapMutations('gui_store', ['setHintLabel']),
      ...mapMutations('general_store', ['processModal', 'setDataForModal']),

      close(e) {
        if (e.target.id !== 'modal_base') return
        this.processModal()
        this.setDataForModal({ reset: true })
      },
      confirm() {
        this.socket.emit('client_action', {
          action_type: 'DeleteUser',
          data: {
            user_id: this.processed_user.id,
          },
        })
        this.processModal()
        this.setDataForModal({ reset: true })
      },
      deny() {
        this.processModal()
        this.setDataForModal({ reset: true })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .modal_base {
    position: absolute;
    pointer-events: all;
    width: 100dvw;
    height: 100dvh;
    background-color: $modals_background_color;
    z-index: 20;
    top: 0;
    left: 0;
  }
  .confirm_wr {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    box-sizing: border-box;
    left: 50%;
    top: 50%;
    padding: 2rem;
    transform: translate(-50%, -50%);
    width: auto;
    height: auto;
    max-width: $modals_max_width;
    max-height: $modals_max_height;
    align-items: center;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

    border-radius: 0.4rem;
    background-color: $modals_color;
    -webkit-box-shadow: -1px -4px 97px 31px rgb(147, 54, 54);
    -moz-box-shadow: -1px -4px 97px 31px rgb(147, 54, 54);
    box-shadow: -1px -4px 97px 31px rgb(147, 54, 54);
  }

  h2 {
    color: white;
  }

  .user_wr {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    img {
      width: 90px;
    }
    span {
      color: yellow;
      font-size: 1.5rem;
      text-align: center;
    }
  }

  .btns-wr {
    margin-top: 10px;
    display: flex;
    gap: 1rem;
  }

  button {
    margin: 0;
    line-height: 1.15;
    padding: 10px 20px;
    border-radius: 6px;
    border-width: 2px;
    border-style: solid;
    font-size: 1rem;
    font-family: 'Ubuntu', sans-serif;
    cursor: pointer;
    transition: 0.25s ease;
    text-transform: none;
  }

  .btn_confirm {
    color: #fff;
    background: #2196f3;
    border-color: #2196f3;
    &:hover {
      background: #065291;
      border-color: #065291;
    }
  }
  .btn_deny {
    color: #fff;
    background: #e94242;
    border-color: #e94242;
    &:hover {
      background: #aa0000;
      border-color: #aa0000;
    }
  }
</style>
