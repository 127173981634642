<template>
  <div class="tab_players_list_container">
    <div class="tab_players_list_scroll_container">
      <div class="players_wr" ref="players_wr">
        <div
          v-for="(_user, i) in players"
          :key="i"
          class="player_wr"
          :class="{ myself: user?.id === _user.id }"
          :style="{
            backgroundColor: `var(--${_user.home}_color_light)`,
          }"
        >
          <div class="avatar_container" :style="{ backgroundColor: `var(--${_user.home}_color_dark)` }">
            <div v-if="_user.home !== 'watcher'" class="timer">
              {{ get_user_timer(_user) }}
            </div>
            <div class="image_wr">
              <div class="ava_img_wr">
                <img
                  :src="`/images/avatars/${_user?.avatars?.[0]}`"
                  alt="ava"
                  class="ava_img"
                  :class="{ offline: !getOnlineStatus(_user.id) }"
                />
                <div class="home_label_wr">
                  <img :src="`/images/tokens/${_user.home}_token.png`" :alt="_user.home || 'watcher'" />
                </div>
              </div>
            </div>
            <div class="info_container" :style="{ backgroundColor: `var(--${_user.home}_color_dark)` }">
              <span v-if="showJoinGameBtn(_user)" class="material-icons join_btn" @click="joinGame(_user.home)">
                login
              </span>
              <span
                v-if="showPrivateMsgButton(_user)"
                class="material-icons private_chat_btn"
                @click="openPrivateChatTab($event, _user.home)"
              >
                mail
              </span>
              <span
                v-if="_user.home !== 'watcher'"
                class="material-icons player_log_btn"
                @click="openPlayersLogModal($event, _user)"
              >
                toc
              </span>
            </div>
          </div>
          <div class="body_container">
            <div class="name_wr" :style="{ color: `var(--${_user.home}_color_text)` }">
              <span class="user_name">{{ _user.name || '---' }}</span>
            </div>
            <div class="units_wr" :style="{ backgroundColor: `var(--${_user.home}_color_dark)` }">
              <div class="lands_wr">
                <img src="/images/lands_stat.png" alt="lands_stat" />
                <span>{{ getNormalizedInvadedLands(_user.home) }}| {{ lands_no_ports_no_seas.size }}</span>
              </div>
              <div class="token_wr">
                <img :src="`/images/tokens/${_user.home}_token.png`" alt="tokens" />
                <span>{{
                  getHomeState(_user.home).tokens.length + `|${game.state.pool_tokens[_user.home].length}`
                }}</span>
              </div>
              <div class="unit_items_wr">
                <div v-for="(val_, unitType) in getHomeState(_user.home)?.units" :key="unitType" class="unit_wr">
                  <img :src="`/images/units/${_user.home}/new/${unitType}_${_user.home}.png`" :alt="unitType" />
                  <span>{{ val_?.length + '|' + game.state.pool_units[_user.home][unitType]?.length }}</span>
                </div>
              </div>
            </div>
            <div class="cards_wr">
              <div class="card_row">
                <div
                  v-for="card in getHomeState(_user.home)?.cards.slice(0, 4)"
                  :key="card.title"
                  class="mask"
                  @click="cardClickHandler($event, card)"
                  :data-home="_user.home"
                >
                  <div class="card_power">
                    <span :data-home="_user.home">{{ card.power }}</span>
                  </div>

                  <img
                    v-if="showTrueStatus(card)"
                    src="/images/forbidden.png"
                    alt="forbiden"
                    class="disable_label"
                    :data-home="_user.home"
                  />

                  <img
                    class="card_img"
                    :data-home="_user.home"
                    :src="`/images/cards/${card.home}/faces/${card.title}.png`"
                    :alt="card.title"
                    :class="{
                      hint_my_card: card.home === home && card.active,
                      grey_mask: showTrueStatus(card),
                    }"
                  />
                </div>
              </div>
              <div class="card_row">
                <div
                  v-for="card in getHomeState(_user.home)?.cards.slice(4, 7)"
                  :key="card.title"
                  class="mask"
                  @click="cardClickHandler($event, card)"
                  :data-home="_user.home"
                >
                  <div class="card_power">
                    <span :data-home="_user.home">{{ card.power }}</span>
                  </div>

                  <img
                    v-if="showTrueStatus(card)"
                    src="/images/forbidden.png"
                    alt="forbiden"
                    class="disable_label"
                    :data-home="_user.home"
                  />

                  <img
                    class="card_img"
                    :data-home="_user.home"
                    :src="`/images/cards/${card.home}/faces/${card.title}.png`"
                    :alt="card.title"
                    :class="{
                      hint_my_card: card.home === home && card.active,
                      grey_mask: showTrueStatus(card),
                    }"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="users_wr">
        <div v-for="(_user, i) in game.users" :key="i" class="unit" :class="{ myself: user?.id === _user.id }">
          <div class="avatar">
            <img
              :src="`/images/avatars/${_user?.avatars?.[0]}`"
              alt="ava"
              class="ava_img"
              :class="{ offline: !getOnlineStatus(_user.id) }"
            />

            <div v-if="_user.home !== 'watcher'" class="home_label">
              <img :src="`/images/tokens/${_user.home}_token.png`" :alt="_user.home || 'watcher'" />
            </div>
          </div>
          <div class="info">
            <span v-if="_user.name" class="user_name">{{ _user.name }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapMutations, mapState } from 'vuex'
  import { genPrivateChatId } from '../utils/utilsFrontend.js'
  import Sortable from 'sortablejs'
  import clone from 'clone'
  export default {
    inject: ['socket'],
    computed: {
      ...mapGetters('general_store', [
        'mainRoom',
        'current_game_id',
        'game_private_chats',
        'main_private_chats',
        'isAdmin',
        'user',
        'isMainAdmin',
        'home',
        'game',
        'lands',
        'states',
        'enemyRole',
        'phase_name',
      ]),
      ...mapState('general_store', ['timers', 'sid']),

      ...mapState({
        main_users(state) {
          return state.general_store.users
        },
      }),

      lands_no_ports_no_seas() {
        const lands = this.lands
        return Object.keys(lands).reduce((set, land_name) => {
          const _land_obj = lands[land_name]
          if (_land_obj.sea || land_name.includes('_port')) return set
          set.add(land_name)
          return set
        }, new Set())
      },

      users() {
        return this.game?.players.concat(this.game?.users)
      },
      players() {
        return clone(this.game.players)
      },
    },

    mounted() {
      this.initSortables()
    },

    methods: {
      ...mapMutations('gui_store', ['setGameSideTab', 'setHintLabel', 'setFreeze']),
      ...mapMutations('general_store', ['setTempPrivateChat', 'selectPrivateChat', 'setDataForModal', 'processModal']),
      showTrueStatus(card) {
        if (this.phase_name !== 'SelectCard') {
          return !card.active
        }

        const card_cand = this.enemyRole.card?.title
        if (card_cand === card.title) {
          return false
        }
        return !card.active
      },
      cardClickHandler(e, card) {
        this.setDataForModal({ data: { card: { ...card } } })
        this.processModal({ name: 'card_hint' })
      },
      getHomeState(home) {
        return this.states.find((st) => st.home === home)
      },

      getNormalizedInvadedLands(home) {
        const home_state = this.states.find((st) => st.home === home)
        const inv_lands = home_state.lands
        const res = inv_lands.reduce((set, land_name) => {
          const land_obj = this.lands[land_name]
          if (land_obj.sea || land_name.includes('_port')) return set
          set.add(land_name)
          return set
        }, new Set())
        return res.size
      },

      initSortables(keys) {
        function swapElements(arr, i1, i2) {
          if (!arr) return
          ;[arr[i1], arr[i2]] = [arr[i2], arr[i1]]
        }

        const elem = this.$refs.players_wr

        new Sortable(elem, {
          delay: 155,
          delayOnTouchOnly: true,
          onChoose: function (evt) {
            evt.item.style.borderTop = '2px solid red'
          },
          onUnchoose: function (/**Event*/ evt) {
            evt.item.style.borderTop = ''
          },
          onEnd: function (evt) {
            swapElements(this.players, evt.oldIndex, evt.newIndex)
            evt.item.style.borderTop = ''
          }.bind(this),
        })
      },
      openPlayersLogModal(e, player) {
        this.setDataForModal({
          data: {
            player,
          },
        })
        this.processModal({
          name: 'players_log',
        })
      },
      joinGame(home) {
        if (this.user.ban.play.value) {
          this.socket.emit('client_action', {
            action_type: 'BanUser',
            data: {
              user_id: this.user.id,
              target_user_id: this.user.id,
              update: true,
            },
          })
          return
        }

        let av_houses = this.game.players.reduce((acc, player) => {
          if (!player.id) {
            acc.push(player.home)
          }
          return acc
        }, [])
        const buy = av_houses.length === 1 ? false : true

        if (buy || this.game.game_options.bank.buy_in) {
          this.setDataForModal({
            data: {
              buyin: this.game.game_options.bank.buy_in,
              select_home: buy,
              cb: (result) => {
                if (!result) return
                this.socket.emit('client_action', {
                  action_type: 'JoinGame',
                  data: {
                    room_id: this.game.id,
                    user_id: this.user.id,
                    home,
                    sid: this.sid,
                    buy,
                  },
                })
              },
            },
          })
          this.processModal({ name: 'join_game' })
          return
        }

        this.socket.emit('client_action', {
          action_type: 'JoinGame',
          data: {
            room_id: this.game.id,
            user_id: this.user.id,
            home,
            sid: this.sid,
            buy,
          },
        })
      },

      getOnlineStatus(user_id) {
        const user = this.main_users.find((u) => u.id === user_id)
        return user?.online
      },

      showJoinGameBtn(user) {
        if (user.home === 'watcher') return false
        if (this.home) return false
        if (this.user.role.value.includes('admin')) {
          if (user.id) return false
        }
        if (!this.current_game_id && this.game?.state.status === 'end') {
          return false
        }
        if (user.id) return false

        return true
      },

      showPrivateMsgButton(user) {
        if (!user.id) return
        if (this.home === 'watcher') return false

        if (!this.home) return false
        if (user.home === 'watcher') return false
        if (user.id === this.user.id) return false
        return true
      },

      openPrivateChatTab(e, target_user_id) {
        if (target_user_id === this.home) return

        const chat_id = genPrivateChatId(target_user_id, this.home)

        let existed_pr_chat = this.game_private_chats.find((ch) => ch.users.includes(target_user_id))
        if (!existed_pr_chat) {
          this.setTempPrivateChat({ room_id: this.game.id, target_user_id, chat_id, my_home: this.home })
          existed_pr_chat = this.game_private_chats.find((ch) => ch.users.includes(target_user_id))
        }
        this.selectPrivateChat({ chat_id: existed_pr_chat?.id || chat_id, room_id: this.game.id })
        this.setGameSideTab({ tab: 'privateGameChatActive' })
      },

      get_user_timer(user) {
        return this.formatTimer(this.timers[user.home])
      },
      formatTimer(duration) {
        const hrs = ~~(duration / 3600)
        const mins = ~~((duration % 3600) / 60)
        const secs = ~~duration % 60

        // Output like "1:01" or "4:03:59" or "123:03:59"
        let ret = ''

        if (hrs > 0) {
          ret += '' + hrs + ':' + (mins < 10 ? '0' : '')
        }

        ret += '' + mins + ':' + (secs < 10 ? '0' : '')
        ret += '' + secs

        return ret
      },
    },
  }
</script>

<style lang="scss" scoped>
  .tab_players_list_container {
    box-sizing: border-box;
    // align-items: center;
    height: 100%;
    color: white;
    padding-right: env(safe-area-inset-right);
    padding-bottom: env(safe-area-inset-bottom);
    padding-top: $space_for_topbtns_and_logger;

    @media (min-aspect-ratio: 4/3) {
      padding-top: $space_for_topbtns_and_logger_shrinked;
    }

    .tab_players_list_scroll_container {
      height: 100%;
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      display: flex;
      flex-direction: column;

      &::-webkit-scrollbar {
        width: 0;
      }

      .players_wr {
        width: 100%;
        margin-bottom: 3rem;

        .player_wr {
          box-sizing: border-box;
          margin-top: 0.4rem;
          display: flex;
          justify-content: flex-start;
          padding: 0.4rem;
          gap: 0.2rem;
          height: 185px;
          border: 2px solid white;
          border-right: 0;
          border-left: 0;
          width: 100%;

          .avatar_container {
            position: relative;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            width: 20%;
            min-width: 20%;
            border-radius: $theme_border_radius;

            .info_container {
              box-sizing: border-box;
              width: auto;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: center;
              flex: 0.7;
              span {
                font-size: 1.4rem;
              }
            }

            .timer {
              color: rgb(233, 227, 227);
              font-weight: 600;
              position: absolute;
              bottom: 0.4rem;
              right: 30%;
            }

            .image_wr {
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              .ava_img_wr {
                position: relative;
                width: 80%;

                .home_label_wr {
                  position: absolute;
                  bottom: 0;
                  transform: translateY(50%);
                  width: 50%;
                  img {
                    width: 100%;
                  }
                }
                .ava_img {
                  border-radius: $theme_border_radius;
                  width: 100%;
                  -webkit-box-shadow: 0px 0px 8px 6px rgba(38, 204, 29, 0.75);
                  -moz-box-shadow: 0px 0px 8px 6px rgba(38, 204, 29, 0.75);
                  box-shadow: 0px 0px 8px 6px rgba(38, 204, 29, 0.75);
                }

                .offline {
                  -webkit-box-shadow: 0px 0px 8px 6px rgba(250, 10, 30, 0.79);
                  -moz-box-shadow: 0px 0px 8px 6px rgba(250, 10, 30, 0.79);
                  box-shadow: 0px 0px 8px 6px rgba(250, 10, 30, 0.79);
                }
              }
            }
          }
          .body_container {
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            gap: 0.4rem;
            height: 100%;
            width: auto;

            .name_wr {
              width: 100%;
              text-align: left;
              font-size: 1.2rem;
              font-weight: 600;
            }
            .units_wr {
              box-sizing: border-box;
              display: flex;
              height: 25%;
              width: 100%;
              padding: 0.2rem 0;
              gap: 0.4rem;
              border-radius: $theme_border_radius;

              .lands_wr {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                justify-self: start;
                align-items: center;
                height: 100%;
                width: 100%;

                img {
                  height: auto;
                  max-height: 70%;
                  width: auto;
                }
                span {
                  font-size: 0.75rem;
                }
              }
              .token_wr {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                justify-self: start;
                height: 100%;
                width: 100%;

                img {
                  height: auto;
                  max-height: 70%;
                  width: auto;
                }
                span {
                  font-size: 0.7rem;
                }
              }
              .unit_items_wr {
                display: flex;
                justify-content: space-between;
                height: 100%;
                width: 100%;
                .unit_wr {
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: center;
                  height: 100%;
                  width: 100%;

                  img {
                    height: auto;
                    max-height: 70%;
                    width: auto;
                  }
                  span {
                    font-size: 0.7rem;
                  }
                }
              }
            }
            .cards_wr {
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
              flex-wrap: wrap;
              width: 100%;
              height: 100%;
              // background-color: rgba(121, 160, 59, 0.575);

              .card_row {
                display: flex;
                justify-content: flex-start;
                gap: 0.6rem;
                align-items: flex-start;
                &::nth-child(0) {
                  gap: 0;
                  justify-content: space-between;
                }

                .mask {
                  position: relative;
                  width: 20%;
                  height: auto;
                  margin: 0;

                  .card_img {
                    transition: 0.35s;
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                    object-fit: cover;
                    border-radius: $theme_border_radius;
                  }

                  .hint_my_card {
                    &:hover {
                      transform: scale(1.1);
                    }
                  }

                  .disable_cursor {
                    &:hover {
                      border: 2px solid rgb(204, 28, 28);
                      margin: -2px;
                      cursor: no-drop;
                    }
                  }
                  .grey_mask {
                    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
                    filter: grayscale(100%);
                  }
                  .disable_label {
                    position: absolute;
                    z-index: 12;
                    top: 40%;
                    right: 40%;
                    width: 1rem;
                  }

                  .card_power {
                    position: absolute;
                    cursor: pointer;
                    width: 20%;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 11;
                    border-radius: 20%;
                    background-color: black;
                    font-weight: 700;

                    span {
                      font-size: 20 !important;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .users_wr {
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        width: 100%;
        gap: 1rem;

        .unit {
          border-bottom: 2px solid rgb(241, 234, 234);
          border-top: 2px solid rgb(241, 234, 234);
          overflow: hidden;
          padding: 1rem;
          width: 100%;
          word-wrap: break-word;
          display: flex;
          background-color: rgb(73, 73, 73);
          height: auto;

          .avatar {
            width: 30%;
            .ava_img {
              border-radius: $theme_border_radius;
              width: 100%;
              -webkit-box-shadow: 0px 0px 8px 6px rgba(38, 204, 29, 0.75);
              -moz-box-shadow: 0px 0px 8px 6px rgba(38, 204, 29, 0.75);
              box-shadow: 0px 0px 8px 6px rgba(38, 204, 29, 0.75);
            }

            .offline {
              -webkit-box-shadow: 0px 0px 8px 6px rgba(250, 10, 30, 0.79);
              -moz-box-shadow: 0px 0px 8px 6px rgba(250, 10, 30, 0.79);
              box-shadow: 0px 0px 8px 6px rgba(250, 10, 30, 0.79);
            }
          }

          .info {
            height: 100%;
            width: 100%;
            align-self: center;
            display: flex;
            position: relative;
            justify-content: center;
            align-items: center;

            .user_name {
              font-size: 1.2rem;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
</style>
