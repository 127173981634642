<template>
  <div class="sidebar_right_container" :style="{ transform: container_hide }">
    <NavButtonsRight />
    <TabPlayersList
      :class="{
        hide: hide('playersActive'),
        fast_hide: menu_open,
        tab: true,
      }"
    />

    <TabChat
      :class="{ hide: hide('chatActive'), fast_hide: menu_open, tab: true, shrinkWidth: true }"
      :room="game"
      :tab-type="'game_chat'"
    />
    <TabPrivateChat
      :tab-type="'game_private_chat'"
      :class="{
        hide: hide('privateGameChatActive'),
        fast_hide: menu_open,
        tab: true,
      }"
      :room="game"
    />
    <TabWar
      :class="{
        hide: hide('warActive'),
        fast_hide: menu_open,
        tab: true,
      }"
      @exec="$emit('exec')"
    />

    <TabRedactorPhase
      v-if="isAdmin"
      :class="{
        hide: hide('stateRedactorActive'),
        fast_hide: menu_open,
        tab: true,
      }"
    />
  </div>
</template>

<script>
  import { mapState, mapMutations, mapGetters } from 'vuex'
  export default {
    props: {
      handler: Object,
    },
    emits: ['exec'],

    computed: {
      ...mapGetters('general_store', ['game', 'crusade_state', 'isAdmin']),
      ...mapState('gui_store', ['game_side_bar']),

      container_hide() {
        if (this.menu_open) {
          if (window.innerWidth / window.innerHeight > 4 / 3 && window.innerHeight < 400) {
            return `translateX(-300px)`
          }
          return `translateX(-100%)`
        }

        return `none`
      },

      menu_open() {
        return Object.keys(this.game_side_bar).some((k) => this.game_side_bar[k] === true)
      },

      users() {
        return this.game.players.concat(this.game.users)
      },
    },

    watch: {
      'crusade_state.conflict_land'(newValue) {
        if (this?.crusade_state?.conflict_land) {
          this.setGameSideTab('warActive')
        }
      },

      menu_open() {
        if (this.menu_open) {
          if (window.innerWidth < 1285) {
            this.setMainSideTab()
          }
        }
      },
    },

    methods: {
      ...mapMutations('gui_store', ['setMainSideTab', 'setGameSideTab']),

      hide(type) {
        if (this.game_side_bar[type]) {
          return false
        }
        return true
      },
    },
  }
</script>

<style lang="scss" scoped>
  .sidebar_right_container {
    position: absolute;
    display: flex;
    justify-content: flex-start;
    height: 100%;
    z-index: 15;
    bottom: 0;
    width: 400px;
    right: -400px;
    box-sizing: border-box;
    transition: all 1s;
    background-color: $theme_background;
    border: 3px solid white;
    border-top: none;
    pointer-events: all;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none;
    @media screen and (max-width: 700px) {
      right: -65%;
      width: 65%;
    }
    @media screen and (max-width: 400px) {
      right: -80%;
      width: 80%;
    }
    .tab {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      @media (min-aspect-ratio: 4/3) and (max-height: 400px) {
        width: 300px;
      }
    }
  }

  .fast_show {
    transition-delay: 0s;
    transition-property: transform;
  }
  .hide {
    transition-delay: 1s;
    transition-property: transform;
    transform: translateX(220%);
  }
  .fast_hide {
    transition-delay: 0s;
    transition-property: transform;
  }
</style>
