<template>
  <div id="modal_base" class="modal_base" @click="close">
    <div class="pass_wr">
      <div v-if="!failure_donate && sucsess_donate" class="donate_status sucsess_wr">
        <span class="material-icons"> verified </span>
      </div>
      <div v-if="!sucsess_donate && failure_donate" class="donate_status failure_wr">
        <span class="material-icons"> do_disturb_on </span>
      </div>
      <div v-if="!failure_donate && !sucsess_donate" class="range_wr">
        <input v-model="donate_value" type="range" min="100" max="2000" step="50" />
        <div class="donate_value">
          <span class="tokens_value">{{ donate_value }} tokens</span>
          <span class="dollars_value">(${{ donate_value / 10 }})</span>
        </div>
      </div>

      <div v-if="!failure_donate && !sucsess_donate" id="paypal-button-container">
        <pulse-loader :loading="loading" color="white" size="20px"></pulse-loader>
      </div>
    </div>
  </div>
</template>

<script>
  import { loadScript } from '@paypal/paypal-js'
  import { mapGetters, mapMutations } from 'vuex'
  export default {
    inject: ['socket'],

    data() {
      return {
        donate_value: 100,
        loading: true,
        sucsess_donate: false,
        failure_donate: false,
      }
    },

    computed: {
      ...mapGetters('general_store', ['user']),
    },

    async mounted() {
      const paypal = await loadScript({
        'client-id': 'AaMB0M8agQTCzFssd9Opk569tAeHxgyDDr7ca5uBUSUYxf3Btvq7nn46XJ4NfWXEv29xDoLNRoc9DvwZ',
      })

      paypal
        .Buttons({
          createOrder: this.createOrder,

          onApprove: this.onApprove,
          style: {
            color: 'silver',
          },
        })
        .render('#paypal-button-container')
      this.loading = false
    },

    methods: {
      ...mapMutations('general_store', ['processModal']),
      async createOrder() {
        return fetch('/app/create-paypal-order', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },

          body: JSON.stringify({
            cart: [
              {
                sku: 'tokens',
                quantity: +this.donate_value / 10,
              },
            ],
          }),
        })
          .then((response) => response.json())
          .then((order) => {
            return order.id
          })
      },
      async onApprove(data) {
        return fetch('/app/capture-paypal-order', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            orderID: data.orderID,
            user_id: this.user.id,
          }),
        })
          .then((response) => response.json())
          .then((orderData) => {
            // Successful capture! For dev/demo purposes:

            this.sucsess_donate = true
          })
      },

      async onCancel() {
        this.failure_donate = true
      },
      async onError() {
        this.failure_donate = true
      },

      close(e) {
        if (e.target.id !== 'modal_base') return
        this.processModal({ name: 'donate', type: 'close' })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .modal_base {
    position: fixed;
    pointer-events: all;
    z-index: 50;
    background-color: $modals_background_color;
    width: 100dvw;
    height: 100dvh;
    top: 0;
    left: 0;
  }

  .pass_wr {
    position: absolute;
    left: 50%;
    top: 50%;
    padding: 2rem;
    transform: translate(-50%, -50%);
    color: white;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    gap: 0.5rem;
    width: auto;
    height: auto;
    max-height: $modals_max_height;
    max-width: $modals_max_width;
    border-radius: 0.4rem;
    background-color: $modals_color;
    -webkit-box-shadow: -1px -4px 97px 31px rgb(95, 211, 134);
    -moz-box-shadow: -1px -4px 97px 31px rgb(95, 211, 134);
    box-shadow: -1px -4px 97px 31px rgb(95, 211, 134);
  }

  button {
    &:hover {
      cursor: pointer;
    }
  }
  input {
    border: 1px solid white;
    height: 20px;
    margin-bottom: 15px;
    width: 50%;
    background-color: #292828;
    color: white;
    font-size: 12px;
  }

  .range_wr {
    margin-top: 15px;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    input {
      width: 100%;
    }
  }
  .donate_value {
    display: flex;
    gap: 1rem;
  }

  .tokens_value {
    color: yellow;
  }
  .dollars_value {
    color: rgb(1, 171, 4);
  }

  .donate_status {
    span {
      font-size: 3rem;
      color: green;
    }
  }
  .failure_wr {
    span {
      color: red;
    }
  }

  input[type='text'] {
    width: 70%;
  }
  input[type='radio'] {
    width: 20%;
  }
  input:hover {
    border-color: rgb(87, 194, 236);
  }
  input:focus {
    outline: none;
    border-color: rgb(87, 194, 236);
  }
  input:invalid {
    border: 2px dashed red;
  }
</style>
