<template>
  <div>
    <div
      v-for="(land, name) in lands"
      :id="name + '-drop'"
      :ref="`${name}-drop`"
      :key="name"
      class="drop_zone"
      :data-source="name"
    >
      <div class="inner_relative_wrapper">
        <div
          v-if="drag_state.from === name && selected_units.size"
          class="dest_selector_container"
          data-type="select_input"
        >
          <div class="dropdown_wrapper">
            <div class="dropdown_btn" @pointerdown="showDropdown">
              <span>{{ $t(`message.select_land`) }}</span> <span class="arrow_down">▼</span>
            </div>
            <ul v-show="show_dropdown">
              <li
                v-for="land_name in available_lands"
                :value="land_name"
                :key="land_name"
                @pointerdown="setDragState($event, land_name)"
                data-type="dropDownItem"
              >
                <span> {{ $t(`land.${land_name}`) }} </span>
                <div class="img_wr">
                  <img
                    v-if="lands[land_name].home"
                    :src="`/images/tokens/${lands[land_name].home}_token.png`"
                    alt="home"
                  />
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div
          v-for="u in land.units"
          :id="u?.id"
          :key="u?.id"
          :class="{
            unit_wr: true,
            pawn_upgrade_hint: pawn_upgrade_hint(u?.id),
            gap: gap_unit(name),
            retreated: u?.retreated,
            selectedUnit: selected_unit(u),
            highlighted: highlighted(u),
            invisible_unit: invisible_unit(u),
            cursor_pointer: handler?.allowCursorPointer?.(u),
          }"
          :ref="u?.id"
          :data-id="u.id"
          :data-source="name"
          :data-house="u.owner"
          :data-force="strokes[u.type].force"
          data-type="unit"
          :data-unit_type="u.type"
          @dragstart="dragStart"
          @pointerdown="pointerdownHandler"
        >
          <img v-if="u?.type" :src="`/images/units/${u.owner}/new/${u.type}_${u.owner}.png`" :alt="u.type" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapMutations, mapState } from 'vuex'

  import { units_stroke as strokes } from '#static_data'

  export default {
    props: {
      handler: Object,
    },

    setup() {
      return {
        strokes,
      }
    },
    data() {
      return {
        show_dropdown: false,
      }
    },
    computed: {
      ...mapGetters('general_store', [
        'lands',
        'isHistoryModeOn',
        'phase_name',
        'active_players',
        'home',
        'crusade_state',
      ]),
      ...mapState('gui_store', [
        'available_lands',
        'pawns_for_upgrade',
        'selected_units',
        'highlighted_units',
        'dragging_units',
        'gap_lands',
      ]),
      ...mapState('game_store', ['drag_state']),

      unitsCount() {
        if (!this.lands) return
        const lands = Object.keys(this.lands)
        return lands?.reduce((acc, l) => {
          if (this.lands[l].units.length) {
            acc += this.lands[l].units.length
          }
          return acc
        }, 0)
      },
    },

    watch: {
      'drag_state.from'(newValue) {
        this.handler?.resetDragParams?.()
      },
    },

    methods: {
      ...mapMutations('game_store', ['setCurrentArea']),

      showDropdown(e) {
        if (this.show_dropdown) {
          this.show_dropdown = false
          return
        }
        this.show_dropdown = true
        setTimeout(() => {
          document.addEventListener(
            'pointerdown',
            (e) => {
              if (e.target.dataset?.type === 'dropDownItem') {
                setTimeout(() => {
                  document.addEventListener(
                    'pointerdown',
                    (e) => {
                      if (e.target.dataset?.type === 'dropDownItem') {
                        return
                      }
                      this.show_dropdown = false
                    },
                    { once: true }
                  )
                }, 0)

                return
              }
              this.show_dropdown = false
            },
            { once: true }
          )
        }, 0)
      },

      setDragState(e, land_name) {
        const land = this.lands[land_name]
        if (!land) {
          return
        }
        this.setCurrentArea({ land_name: land_name, owner: land.home })
        this.dragEnd()
        this.show_dropdown = false
      },

      gap_unit(land_name) {
        if (this.gap_lands.has(land_name)) {
          return true
        }
        return false
      },

      pawn_upgrade_hint(id) {
        return this.pawns_for_upgrade.has(id)
      },
      highlighted(u) {
        return this.highlighted_units.has(u?.id)
      },

      selected_unit(u) {
        return this.selected_units.has(u?.id)
      },
      invisible_unit(u) {
        return this.dragging_units.has(u?.id)
      },

      pointerdownHandler(e) {
        if (this.isHistoryModeOn) return
        this.handler?.pointerdownHandler?.(e)
      },

      dragStart(e) {
        return false
      },
      dragEnd(e) {
        if (this.isHistoryModeOn) return
        this.handler?.dragEnd?.(e)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .dest_selector_container {
    position: absolute;
    bottom: -5rem;
    left: 3rem;
    overflow: visible;
    display: flex;
    z-index: 20;
    pointer-events: all;
    height: auto;
    width: fit-content;

    .dropdown_wrapper {
      cursor: pointer;

      .dropdown_btn {
        box-sizing: border-box;
        background-color: rgba(0, 0, 0, 0.629);
        padding: 0.3rem;
        color: white;
        border-radius: 0.3rem;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        font-size: 1.3rem;
        display: flex;
        transition: all 0.3s;
        width: 200px;
        height: 50px;
        &:hover {
          background-color: rgb(47, 47, 47);
        }
        span {
          font-size: 1.6rem;
          white-space: nowrap;
        }
        .arrow_down {
          font-size: 0.8rem;
        }
      }

      ul {
        position: absolute;
        margin: 0;
        padding: 0;
        list-style: none;
        background-color: rgba(68, 11, 95, 0.701);
        color: rgb(255, 255, 255);
        border-radius: 0.4rem;

        li {
          transition: all 0.3s;
          width: 100%;
          text-align: left;
          box-sizing: border-box;
          padding: 0.5rem 0.3rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 1rem;

          &:hover {
            background-color: antiquewhite;
          }
          span {
            pointer-events: none;
            font-size: 1.6rem;
          }
          .img_wr {
            pointer-events: none;
            width: 2rem;
            height: 2rem;
            img {
              pointer-events: none;
              width: 100%;
            }
          }
        }
      }
    }
  }

  .drop_zone {
    // border-radius: 20%;
    width: auto;
    position: absolute;
    border: none;
  }

  .inner_relative_wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    .invisible_unit {
      opacity: 0;
    }
  }
  $colorr: rgba(53, 244, 46, 0.977);
  .highlighted {
    -webkit-filter: drop-shadow(2px 2px 0 $colorr) drop-shadow(-2px -2px 0 $colorr);
    filter: drop-shadow(2px 2px 0 $colorr) drop-shadow(-2px -2px 0 $colorr);
    // cursor: pointer;
  }

  .unit_wr {
    position: relative;
    width: 50px;
    height: 50px;
    // background-color: rgba(127, 255, 212, 0.618);
    margin-left: -20px;
    transition: margin 350ms;
    // cursor: pointer;
    touch-action: none;
    pointer-events: all;

    img {
      touch-action: none;
      pointer-events: none;
    }
  }

  .cursor_pointer {
    cursor: pointer;
  }

  .unit_wr_margin {
    margin: 0 20px;
  }

  .shipDrop {
    img {
      margin-left: -20px;
    }
  }
  .lite_shipDrop {
    img {
      margin-left: -25px;
    }
  }
  .retreated {
    // padding-top: 20px;
    img {
      margin-left: -15px;

      transform: rotate(-65deg) !important;
      -webkit-filter: drop-shadow(2px 2px 0 rgb(234, 91, 91)) drop-shadow(-2px -2px 0 rgb(210, 118, 118));
      filter: drop-shadow(2px 2px 0 rgb(201, 90, 90)) drop-shadow(-2px -2px 0 rgb(210, 96, 96));
    }
  }

  .selectedUnit {
    img {
      -webkit-filter: drop-shadow(2px 2px 0 rgb(255, 255, 255)) drop-shadow(-2px -2px 0 white);
      filter: drop-shadow(2px 2px 0 white) drop-shadow(-2px -2px 0 rgb(255, 255, 255));
    }
  }

  .gap {
    margin-left: 0px;
  }

  .pawn_upgrade_hint::after {
    position: absolute;
    top: 15px;
    width: 100px;
    height: 100px;
    left: 0px;
    content: '';
    background-image: url('/images/arrow_up.png');
    background-size: 50px 50px;
    background-repeat: no-repeat;
    pointer-events: none;
  }

  //stark
  //stark
  //stark
  //stark

  #bay_of_ice-drop {
    left: 86px;
    top: 452px;
  }

  #winterfell-drop {
    left: 613px;
    top: 711px;
  }
  #winterfell_port-drop {
    left: 380px;
    top: 500px;
  }
  #castle_black-drop {
    right: 940px;
    top: 269px;
  }
  #karhold-drop {
    right: 750px;
    top: 437px;
  }

  #white_harbor-drop {
    left: 817px;
    top: 838px;
  }
  #white_harbor_port-drop {
    left: 883px;
    top: 1040px;
  }

  #the_eyrie-drop {
    right: 816px;
    top: 1541px;
  }

  #karhold-drop {
    right: 778px;
    top: 527px;
  }

  #castle_black-drop {
    right: 1096px;
    top: 337px;
  }

  #bay_of_ice-drop {
    left: 77px;
    top: 581px;
  }

  #the_stony_shore-drop {
    left: 351px;
    top: 835px;
  }

  #moat_cailin-drop {
    left: 606px;
    top: 1173px;
  }

  #the_twins-drop {
    left: 659px;
    top: 1359px;
  }

  #the_fingers-drop {
    left: 968px;
    top: 1298px;
  }

  #the_mountains_of_the_moon-drop {
    left: 832px;
    top: 1477px;
  }

  #widows_watch-drop {
    right: 844px;
    top: 862px;
  }

  #the_shivering_sea-drop {
    right: 543px;
    top: 650px;
  }

  //baratheon
  //baratheon
  //baratheon
  //baratheon

  #shipsrearer_bay-drop {
    right: 530px;
    top: 2151px;
  }

  #the_narrow_sea-drop {
    right: 543px;
    top: 1161px;
  }

  #dragonstone-drop {
    right: 510px;
    top: 1792px;
  }
  #crackclaw_point-drop {
    right: 957px;
    top: 1740px;
  }

  #dragonstone_port-drop {
    right: 538px;
    top: 1890px;
  }

  #kings_landing-drop {
    left: 890px;
    top: 1949px;
  }
  #kingswood-drop {
    left: 879px;
    top: 2196px;
  }
  #blackwater_bay-drop {
    left: 1088px;
    top: 1822px;
  }

  //greyjoy
  //greyjoy
  //greyjoy
  //greyjoy

  #greywater_watch-drop {
    left: 464px;
    top: 1138px;
  }

  #sunset_sea-drop {
    left: -5px;
    top: 1723px;
  }
  #ironmans_bay-drop {
    left: 105px;
    top: 1567px;
  }
  #pyke-drop {
    left: 127px;
    top: 1402px;
  }
  #pyke_port-drop {
    left: 313px;
    top: 1354px;
  }
  #flints_finger-drop {
    left: 290px;
    top: 1178px;
  }
  #seagard-drop {
    left: 530px;
    top: 1382px;
  }

  //lannister
  //lannister
  //lannister
  //lannister

  #riverrun-drop {
    left: 613px;
    top: 1534px;
  }
  #harrenhal-drop {
    left: 745px;
    top: 1724px;
  }
  #stoney_sept-drop {
    left: 550px;
    top: 1780px;
  }
  #lannisport-drop {
    left: 364px;
    top: 1714px;
  }
  #lannisport_port-drop {
    left: 242px;
    top: 1737px;
  }
  #the_golden_sound-drop {
    left: 107px;
    top: 1801px;
  }
  #searoad_marches-drop {
    left: 309px;
    top: 1977px;
  }
  #blackwater-drop {
    left: 668px;
    top: 1909px;
  }

  //tyrell
  //tyrell
  //tyrell
  //tyrell

  #the_reach-drop {
    left: 707px;
    top: 2136px;
  }
  #dornish_marches-drop {
    left: 526px;
    top: 2333px;
  }
  #highgarden-drop {
    left: 334px;
    top: 2178px;
  }
  #oldtown-drop {
    left: 266px;
    top: 2438px;
  }
  #oldtown_port-drop {
    left: 152px;
    top: 2411px;
  }
  #three_towers-drop {
    left: 344px;
    top: 2625px;
  }
  #redwyne_straights-drop {
    left: 83px;
    top: 2549px;
  }
  #the_arbor-drop {
    left: 94px;
    top: 2779px;
  }
  #west_summer_sea-drop {
    left: 199px;
    top: 2873px;
  }

  //martell
  //martell
  //martell
  //martell

  #starfall-drop {
    left: 533px;
    top: 2680px;
  }
  #princes_pass-drop {
    left: 556px;
    top: 2506px;
  }
  #yronwood-drop {
    left: 690px;
    top: 2610px;
  }
  #the_boneway-drop {
    left: 734px;
    top: 2389px;
  }
  #sea_of_dorne-drop {
    left: 1131px;
    top: 2469px;
  }
  #sunspear-drop {
    left: 1085px;
    top: 2600px;
  }
  #sunspear_port-drop {
    left: 1295px;
    top: 2625px;
  }
  #salt_shore-drop {
    left: 915px;
    top: 2701px;
  }
  #east_summer_sea-drop {
    left: 1174px;
    top: 2807px;
  }
  #storms_end-drop {
    right: 780px;
    top: 2349px;
  }
  #storms_end_port-drop {
    right: 730px;
    top: 2274px;
  }
</style>
