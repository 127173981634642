<template>
  <div v-if="game" class="log_container no_select" ref="log_wr" @mousewheel.prevent="handleMouseWheel">
    <div class="logger_inner_scrollable_wr no_select">
      <div
        v-for="(log_item, idx) in short_log"
        :key="log_item.id"
        :ref="log_item.id"
        class="log_item"
        @pointerenter="logLabelHover($event, log_item)"
        @pointerleave="logLabelHover($event)"
        @click.prevent
      >
        <img
          v-if="log_item"
          class="log_label no_select"
          :class="{ hint: idx <= delta_logs, history_hint: log_item.id === this.currentLogItem?.id }"
          :src="getLogImg(log_item)"
          :alt="log_item.phase_name"
        />
        <img
          v-if="log_item?.home"
          class="home_label no_select"
          :src="getHomeLabel(log_item.home)"
          :alt="log_item.home"
        />

        <span
          v-if="log_item?.sub_type || log_item?.type"
          class="log_label_span material-icons log_label_shift no_select"
          >{{ getLogLabel(log_item.sub_type || log_item.type) }}</span
        >
      </div>
    </div>
  </div>
</template>
<script>
  import clone from 'clone'
  import { nextTick } from 'vue'
  import { mapGetters } from 'vuex'
  import GameLogger from '../locales/GameLogger.js'

  export default {
    emits: ['logTrigger'],
    data() {
      return {
        log_id: '',
        log_msg: '',
        logYpos: '',
        delta_logs: 0,
        clear_hint_delay: null,
        hint_delay: 150,
      }
    },

    computed: {
      ...mapGetters('general_store', ['game', 'currentLogItem', 'generatedLogs']),

      short_log() {
        if (!this.logs) return []
        const arr = clone(this.logs).reverse()

        return arr.reduce((logs, log_item) => {
          const generated_log = this.game_logger?.generateLog(log_item)
          if (!generated_log) return logs
          logs.push(generated_log)
          return logs
        }, [])
      },
      logs() {
        return this.game?.state?.log
      },
    },

    mounted() {
      this.$refs.log_wr?.scrollTo({ left: this.$refs.log_wr?.scrollWidth, behavior: 'smooth' })
    },

    watch: {
      async short_log(newValue, oldValue) {
        const delta = newValue?.length - oldValue?.length
        if (delta) {
          this.delta_logs = delta - 1
          await nextTick()
          this.scrollLog()
        }
      },
      async currentLogItem(newV, oldV) {
        if (oldV && !newV) {
          this.$refs.log_wr?.scrollTo({ left: this.$refs.log_wr?.scrollWidth, behavior: 'smooth' })
          return
        }
        if (!this.currentLogItem) return
        const i_id = this.currentLogItem.id
        const $log = this.$refs[i_id]
        if (!$log) return

        $log[0].scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        })
      },

      logYpos() {
        this.$emit('logTrigger', {
          log_id: this.log_id,
          log_msg: this.log_msg,
          logYpos: this.logYpos,
        })
      },
    },

    beforeCreate() {
      this.game_logger = new GameLogger({ t: this.$t, tc: this.$tc, root: this.$root.$i18n }, this.game?.state)
    },

    methods: {
      scrollLog() {
        const deltaScroll = this.$refs.log_wr?.scrollWidth - +window.innerWidth
        this.$refs.log_wr?.scrollTo({ left: deltaScroll, behavior: 'smooth' })
      },
      handleMouseWheel(event) {
        const delta = Math.max(-1, Math.min(1, event.deltaY || -event.detail))
        if (this.$refs.log_wr) {
          this.$refs.log_wr.scrollLeft += delta * 100
        }
      },
      logLabelHover(e, log_item) {
        if (e.pointerType === 'touch') {
          if (e.type === 'pointerleave') {
            clearTimeout(this.clear_hint_delay)
            this.clear_hint_delay = setTimeout(() => {
              this.log_id = ''
              this.log_msg = ''
              this.logYpos = ''
            }, 2300)

            clearTimeout(this.pressTimerHint)
            this.pressTimerHint = null
            return
          }
          if (e.type === 'pointerenter') {
            clearTimeout(this.clear_hint_delay)
            clearTimeout(this.pressTimerHint)
            this.pressTimerHint = setTimeout(() => {
              this.log_id = log_item.id
              this.log_msg = log_item.msg
              const coords = e.target.getBoundingClientRect(e.target)
              const msg_width = 250

              const edge_right = coords.left + msg_width

              clearTimeout(this.clear_hint_delay)
              this.clear_hint_delay = setTimeout(() => {
                this.log_id = ''
                this.log_msg = ''
                this.logYpos = ''
              }, 2300)
              if (edge_right > window.innerWidth) {
                this.logYpos = `translateX(${window.innerWidth - msg_width}px)`
                return
              }
              if (coords.left < msg_width / 2) {
                this.logYpos = `translateX(${0}px)`
                return
              }
              this.logYpos = `translateX(${coords.left - msg_width / 2}px)`
            }, this.hint_delay)
          }
          return
        }

        if (e.pointerType === 'mouse') {
          if (e.type === 'pointerleave') {
            clearTimeout(this.clear_hint_delay)
            this.clear_hint_delay = null
            this.log_id = ''
            this.log_msg = ''
            this.logYpos = ''
            return
          }
          this.log_id = log_item.id
          this.log_msg = log_item.msg
          const coords = e.target.getBoundingClientRect(e.target)
          const msg_width = 250

          const edge_right = coords.left + msg_width

          clearTimeout(this.clear_hint_delay)
          this.clear_hint_delay = setTimeout(() => {
            this.log_id = ''
            this.log_msg = ''
            this.logYpos = ''
          }, 3000)

          if (edge_right > window.innerWidth) {
            this.logYpos = `translateX(${window.innerWidth - msg_width}px)`
            return
          }
          if (coords.left < msg_width / 2) {
            this.logYpos = `translateX(${0}px)`
            return
          }
          this.logYpos = `translateX(${coords.left - msg_width / 2}px)`
        }
      },

      getLogImg(log_item) {
        if (!log_item) return
        if (log_item.label_img) {
          return `/images/logger/${log_item.label_img}.png`
        }
        return `/images/logger/${log_item.phase_name}.png`
      },
      getHomeLabel(home) {
        return `/images/tokens/${home}_token.png`
      },
      getLogLabel(type) {
        switch (type) {
          case 'autoAction': {
            return 'build'
          }
          case 'cancel': {
            return 'cancel'
          }
          case 'active_players': {
            return 'search'
          }
          case 'change_order': {
            return 'published_with_changes'
          }
          case 'dig_card': {
            return 'low_priority'
          }
          case 'check_card': {
            return 'visibility'
          }
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .no_select {
    -webkit-user-select: none; /* Для iOS и Safari */
    -moz-user-select: none; /* Для Firefox */
    -ms-user-select: none; /* Для старых версий Edge */
    user-select: none; /* Для предотвращения выделения */
  }
  .log_container {
    box-sizing: border-box;
    position: absolute;
    z-index: 16;
    top: $nav_buttons_top_height;
    height: $logger_height;
    align-items: center;
    pointer-events: all;
    width: 100%;
    overflow-y: visible;
    overflow-x: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
    user-select: none;

    @media (min-aspect-ratio: 4/3) {
      height: $logger_height_shrinked;
      top: $nav_buttons_top_height_shrinked;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .logger_inner_scrollable_wr {
      position: relative;
      box-sizing: border-box;
      width: fit-content;
      display: flex;
      gap: 0.1rem;
      flex-direction: row-reverse;
      justify-content: flex-end;
      overflow: visible;
      height: $logger_height;

      @media (min-aspect-ratio: 4/3) {
        height: $logger_height_shrinked;
      }

      .log_item {
        pointer-events: all;
        box-sizing: border-box;
        pointer-events: all;
        position: relative;
        height: 90%;
        width: auto;
        cursor: pointer;
        transition: background-color 0.3s;
        img {
          width: 100%;
        }
        :hover {
          background-color: rgb(51, 52, 52);
        }
        .log_label {
          pointer-events: none;
          box-sizing: border-box;
          width: auto;
          height: 100%;
          padding: 0.1rem;
          border-radius: $theme_border_radius;
          img {
            height: 100%;
          }
        }

        .home_label {
          width: 1rem;
          position: absolute;
          left: 0;
          bottom: 0;
          pointer-events: none;

          @media (min-aspect-ratio: 4/3) {
            width: 0.7rem;
          }
        }
        .log_label_span {
          font-size: 1rem;
          right: 0.7rem;
          bottom: 0;
          width: 0.5rem;
          position: absolute;
          // left: 1rem;
          // top: 2rem;
          z-index: 151;
          pointer-events: none;
          color: red;
          @media (min-aspect-ratio: 4/3) {
            width: 0.4rem;
            right: 0.5rem;
            bottom: -0.2rem;
          }
        }

        .hint {
          border-radius: $theme_border_radius;
          background-color: rgba(10, 122, 115, 0.5);
          transition: background-color 0.3s;
        }

        .history_hint {
          border-radius: $theme_border_radius;
          background-color: rgba(191, 12, 57, 0.616);
          transition: background-color 0.3s;
        }
      }
    }
  }

  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.35s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }
</style>
