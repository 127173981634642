import { store_modals } from '#static_data'
import clone from 'clone'

const state_obj = {
  socket: null,
  my_user_id: '',
  sid: '',
  authenticated: false,
  rooms: [],
  users: [],
  private_chats: [],
  selected_game_private_chat: {},
  selected_main_private_chat: {},
  error: false,
  hint_modal: false,
  cur_gui_cache: null,
  temp_gui_state: {},
  modals: { ...store_modals },
  arrows_on_board: [],
  data_for_modal: null,
  timers: {},
  gathering_timers: {},
  active_lands_beckup: {},
}

export default {
  namespaced: true,
  state: () => state_obj,
  getters: {
    socket_from_store(state) {
      return state.socket
    },
    mainRoom(state) {
      return state.rooms.find((r) => r.id === 'mainRoom')
    },
    current_watch_id(state, getters) {
      const user = getters.user
      if (!user) return ''
      const cur_game = user.current_games?.find((g) => g.sid === state.sid && g.home === 'watcher')
      return cur_game?.room_id || ''
    },
    inside_watch_room(state, getters) {
      const user = getters.user
      if (!user) return false
      const cur_game = user.current_games?.find((g) => g.sid === state.sid && g.home === 'watcher')
      return cur_game?.inside
    },
    current_game_id(state, getters) {
      const user = getters.user
      if (!user) return ''
      const cur_game = user.current_games?.find((g) => g.sid === state.sid && g.home !== 'watcher')
      return cur_game?.room_id || ''
    },
    inside_room(state, getters) {
      const user = getters.user
      if (!user) return false
      const cur_game = user.current_games?.find((g) => g.sid === state.sid)
      return cur_game?.inside
    },

    show_game_map(state, getters) {
      if ((getters.current_watch_id && getters.inside_watch_room) || (getters.current_game_id && getters.inside_room))
        return true
      return false
    },

    home(state, getters) {
      const user = getters.user
      if (!user) return ''
      const cur_game = user.current_games?.find((g) => g.room_id === getters.current_game_id)
      if (!cur_game) return ''

      return cur_game.home || ''
    },

    user(state) {
      const res = state.users?.find((u) => u.id === state.my_user_id)
      return res || mock_user
    },
    isAdmin(state, getters) {
      const user = getters.user
      return user?.role.value.includes('admin')
    },
    sound(state, getters) {
      const user = getters.user
      return user?.sound_settings
    },
    isMainAdmin(state, getters) {
      const user = getters.user
      return user?.role.value === 'admin'
    },

    main_private_chats: (state) => {
      return state.private_chats.find((wr) => wr.room_id === 'mainRoom')?.private_chats || []
    },
    game_private_chats: (state, getters) => {
      const currentGameID = getters.current_game_id

      if (!currentGameID) return []

      return state.private_chats.find((wr) => wr.room_id === currentGameID)?.private_chats || []
    },

    state(state, getters) {
      const room = getters.game
      return room?.state
    },
    arrow_list(state, getters) {
      let _state
      if (getters.isHistoryModeOn) {
        _state = state.temp_gui_state
      } else {
        _state = getters.state
      }

      return _state?.phase.arrow_list
    },

    wildingsState(state, getters) {
      return getters.state?.wildings_state
    },

    crusade_state(state, getters) {
      let _state
      if (getters.isHistoryModeOn) {
        _state = state.temp_gui_state
      } else {
        _state = getters.state
      }

      if (_state?.phase?.crusade_state?.conflict_land) {
        return _state.phase.crusade_state
      }
      return _state?.phase?.last_battle_snapshot
    },

    lands(state, getters) {
      let _state
      if (getters.isHistoryModeOn) {
        _state = state.temp_gui_state
      } else {
        _state = getters.state
      }

      return _state?.lands || {}
    },

    round(state, getters) {
      let _state
      if (getters.isHistoryModeOn) {
        _state = state.temp_gui_state
      } else {
        _state = getters.state
      }

      return _state?.phase.round
    },
    original_round(state, getters) {
      let _state = getters.game?.state

      return _state?.phase?.round
    },
    tracks(state, getters) {
      let _state
      if (getters.isHistoryModeOn) {
        _state = state.temp_gui_state
      } else {
        _state = getters.state
      }
      return _state?.tracks
    },
    states(state, getters) {
      let _state
      if (getters.isHistoryModeOn) {
        _state = state.temp_gui_state
      } else {
        _state = getters.state
      }
      return _state?.states
    },
    westerosCards(state, getters) {
      let _state
      if (getters.isHistoryModeOn) {
        _state = state.temp_gui_state
      } else {
        _state = getters.state
      }

      return [_state?.westeros_0, _state?.westeros_1, _state?.westeros_2]
    },
    lastWildingsCard(state, getters) {
      let _state
      if (getters.isHistoryModeOn) {
        _state = state.temp_gui_state
      } else {
        _state = getters.state
      }

      return _state?.last_wildings_card || ''
    },
    game(state, getters) {
      if (!getters.current_watch_id && !getters.current_game_id) {
        return null
      }
      let room = state.rooms.find((r) => r.id === getters.current_watch_id)
      if (!room) {
        room = state.rooms.find((r) => r.id === getters.current_game_id)
      }
      return room
    },

    active_players(state, getters) {
      let _state
      if (getters.isHistoryModeOn) {
        _state = state.temp_gui_state
      } else {
        _state = getters.state
      }
      return _state?.phase?.active_players || []
    },
    phase_name(state, getters) {
      let _state
      if (getters.isHistoryModeOn) {
        _state = state.temp_gui_state
      } else {
        _state = getters.state
      }

      return _state?.phase.name
    },
    enemyRole(state, getters) {
      let _state
      if (getters.isHistoryModeOn) {
        _state = state.temp_gui_state
      } else {
        _state = getters.state
      }

      const crusade_state = _state?.phase.crusade_state
      if (!crusade_state?.agressor) return null
      return crusade_state.agressor.home !== getters.home ? crusade_state.agressor : crusade_state.victim
    },
    myRole(state, getters) {
      let _state
      if (getters.isHistoryModeOn) {
        _state = state.temp_gui_state
      } else {
        _state = getters.state
      }

      if (!_state) return
      const crusade_state = _state.phase.crusade_state
      if (!crusade_state?.agressor) return null
      return crusade_state.agressor.home === getters.home ? crusade_state.agressor : crusade_state.victim
    },
    isHistoryModeOn(state) {
      return state.temp_gui_state.id ? true : false
    },
    currentLogItem(state, getters) {
      const isHistoryModeOn = getters.isHistoryModeOn
      if (!isHistoryModeOn) return false
      return state.temp_gui_state.log?.[0]
    },
  },
  mutations: {
    set_socket(state, socket) {
      state.socket = socket
    },
    resetState(state) {
      state = Object.assign(state, state_obj)
    },
    logOut(state) {
      state.authenticated = false
      state.sid = ''
      state.my_user_id = ''
      state.socket = null
    },
    logIn(state, { sid, user_id }) {
      state.authenticated = true
      state.sid = sid
      state.my_user_id = user_id
    },
    setUserId(state, user_id) {
      state.my_user_id = user_id
    },

    setTimer(state, timer) {
      const { home, time, reset, type, room_id } = timer
      if (reset) {
        state.timers = {}
        return
      }
      if (type === 'gathering') {
        if (reset) {
          state.gathering_timers[room_id] = null
          return
        }
        state.gathering_timers[room_id] = time
        return
      }
      // if (home !== state.home) return;
      state.timers[home] = time
    },

    setBeckupLands(state, { lands = {} }) {
      state.active_lands_beckup = Object.assign(state.active_lands_beckup, clone(lands))
    },

    deleteRoom(state, { room_id }) {
      state.rooms = state.rooms.filter((r) => r.id !== room_id)
    },

    setInsideRoom(state, { value, room_id }) {
      const user = state.users?.find((u) => u.id === state.my_user_id)

      if (!user) return
      const gg = user.current_games.find((g) => g.room_id === room_id)
      if (!gg) return
      gg.inside = value
    },

    processArrows(state, { arrows, arrow_id, reset = false }) {
      if (arrows) {
        state.arrows_on_board = arrows
        return
      }
      if (arrow_id) {
        state.arrows_on_board = state.arrows_on_board.filter((arrow) => arrow.id !== arrow_id)
        return
      }
      if (reset) {
        state.arrows_on_board = []
        return
      }
    },
    processUser(state, { room_id, type, user }) {
      if (!user) {
        console.error(`user was not received in args`)
        return
      }
      if (!type) {
        console.error(`type was not received in args`)
        return
      }
      if (!room_id) {
        switch (type) {
          case 'add': {
            if (state.users.some((u) => u.id === user.id)) return
            state.users.push(user)
            break
          }
          case 'update': {
            const user_idx = state.users.findIndex((u) => u.id === user.id)
            if (user_idx === -1) break
            const oldUser = state.users[user_idx]
            const newUser = Object.assign(oldUser, user)
            state.users.splice(user_idx, 1, newUser)
            break
          }
          case 'del': {
            state.users = state.users.filter((u) => u.id !== user.id)

            break
          }
          default: {
            break
          }
        }
        return
      }

      const room = state.rooms.find((r) => r.id === room_id)
      if (!room) {
        console.error(`can't find room with id ${room_id}`)
        return
      }
      switch (type) {
        case 'add': {
          room.users.push(user)
          break
        }
        case 'update': {
          const user_idx = room.users.findIndex((u) => u.id === user.id)
          if (user_idx === -1) break
          room.users.splice(user_idx, 1, user)
          break
        }
        case 'del': {
          room.users = room.users.filter((u) => u.id !== user.id)
          break
        }
        default: {
          break
        }
      }
    },

    updateUsers(state, { users }) {
      if (!users) {
        console.error(`users is invalid: ${users}`)
        return
      }
      if (!users?.length) {
        return
      }

      users.forEach((user) => {
        const user_cand_idx = state.users.findIndex((u) => u.id === user.id)
        if (user_cand_idx === -1) {
          state.users.push(user)
          return
        }
        const oldUser = state.users[user_cand_idx]
        const newUser = Object.assign(oldUser, user)
        state.users.splice(user_cand_idx, 1, newUser)
      })
    },

    updatePlayer(state, { room_id, player }) {
      if (!room_id) {
        console.error(`room_id was not received in args`)
        return
      }
      if (!player) {
        console.error(`player was not received in args`)
        return
      }
      const room = state.rooms.find((r) => r.id === room_id)
      if (!room) {
        console.error(`can't find room with id ${room_id}`)
        return
      }
      const player_idx = room.players.findIndex((p) => p?.home === player.home)
      if (player_idx === -1) return
      room.players.splice(player_idx, 1, player)
    },

    processMessage(state, { type, message, chat_id, last_update }) {
      if (!type) {
        console.error(`type was not received in args`)
        return
      }
      if (!message) {
        console.error(`message was not received in args`)
        return
      }
      let room
      if (chat_id) {
        const chatsWrapper = state.private_chats.find((wr) => wr.room_id === message.room_id)
        if (!chatsWrapper) return
        room = chatsWrapper.private_chats.find((chat) => chat.id === chat_id)

        //   const chatsWrapper = state.private_chats.find((ch) => ch.room_id === room_id);
        // if (!chatsWrapper) return;

        //   let chat = chatsWrapper.private_chats?.find((ch) => ch.id === chat_id);
        // if (!chat) {
        //   chat = chatsWrapper.private_chats?.find((ch) => ch.users.includes(target_user_id));
        // }

        delete room?.temp
        if (!room) {
          const message_key = message.room_id === 'mainRoom' ? 'sender_id' : 'sender_home'
          const message_target_key = message.room_id === 'mainRoom' ? 'target_id' : 'target_home'
          chatsWrapper.private_chats?.push({
            id: chat_id,
            messages: [],
            users: [message[message_key], message[message_target_key]],
          })
          // console.error(`can't find room with id ${message.room_id}`);
          // return;
          room = chatsWrapper.private_chats.find((chat) => chat.id === chat_id)
        }
      } else {
        room = state.rooms.find((r) => r.id === message.room_id)
      }
      if (!room) {
        console.error(`can't find room with id ${message.room_id}`)
        return
      }
      switch (type) {
        case 'add': {
          if (room.messages[room.messages.length - 1]?.temp) {
            room.messages.pop()
          }
          room.messages.push(message)
          if (chat_id && type === 'add') {
            room.last_update = last_update
          }
          break
        }
        case 'update': {
          const message_idx = room.messages.findIndex((m) => m.id === message.id)

          if (message_idx === -1) break
          room.messages.splice(message_idx, 1, message)
          break
        }
        case 'del': {
          room.messages = room.messages.filter((m) => m.id !== message.id)
          break
        }
        default: {
          break
        }
      }
    },

    updateMessages(state, { room_id, chat_id, messages }) {
      if (!room_id) {
        console.error(`room_id was not received in args`)
        return
      }
      if (!messages?.length) {
        return
      }
      let room
      if (chat_id) {
        const chatWrapper = state.private_chats.find((wr) => wr.room_id === room_id)
        room = chatWrapper.private_chats.find((chat) => chat.id === chat_id)
      } else {
        room = state.rooms.find((r) => r.id === room_id)
      }
      if (!room) {
        console.error(`can't find room with id ${room_id}`)
        return
      }
      messages.forEach((msg) => {
        const message_cand_idx = room.messages.findIndex((m) => m.id === msg.id)
        if (message_cand_idx === -1) return
        room.messages.splice(message_cand_idx, 1, msg)
      })
    },
    deleteMessages(state, { room_id, chat_id }) {
      if (!room_id) {
        console.error(`room_id was not received in args`)
        return
      }
      let room
      if (chat_id) {
        const chatsWrapper = state.private_chats.find((wrapper) => wrapper.room_id === room_id)
        chatsWrapper.private_chats = chatsWrapper.private_chats.filter((chat) => chat.id !== chat_id)
        return
      } else {
        room = state.rooms.find((r) => r.id === room_id)
      }
      if (!room) {
        console.error(`can't find room with id ${room_id}`)
        return
      }
      room.messages = []
    },

    deleteTempMessage(state, { chat_id, room_id }) {
      let chat
      if (chat_id) {
        let chats = state.private_chats.find((ch) => ch.room_id === room_id)?.private_chats
        chat = chats?.find((ch) => ch.id === chat_id)
      } else {
        chat = state.rooms.find((r) => r.id === room_id)
      }
      if (!chat) {
        return
      }
      if (chat.messages[chat.messages.length - 1]?.temp) {
        chat.messages.pop()
      }
    },

    setTempPrivateChat(state, { chat_id, del, room_id, target_user_id, my_home }) {
      let chatsWrapper = state.private_chats.find((ch) => ch.room_id === room_id)
      const my_user_id = room_id === 'mainRoom' ? state.my_user_id : my_home
      if (!chatsWrapper) {
        state.private_chats.push({
          room_id: room_id,
          private_chats: [],
        })
        chatsWrapper = state.private_chats.find((ch) => ch.room_id === room_id)
      }

      if (del) {
        chatsWrapper.private_chats = chatsWrapper.private_chats?.filter((ch) => !ch.temp)
        return
      }
      if (!chat_id) return

      let chat = chatsWrapper.private_chats?.find((ch) => ch.id === chat_id)
      if (!chat) {
        chat = chatsWrapper.private_chats?.find((ch) => ch.users.includes(target_user_id))
      }

      if (chat) return

      chatsWrapper.private_chats?.push({
        id: chat_id,
        temp: true,
        messages: [],
        users: [my_user_id, target_user_id],
      })
    },

    delPrivateChat(state, { chat_id, room_id }) {
      const chatsWrapper = state.private_chats.find((ch) => ch.room_id === room_id)
      if (!chatsWrapper) return
      chatsWrapper.private_chats = chatsWrapper.private_chats.filter((ch) => ch.id !== chat_id)
    },

    selectPrivateChat(state, { chat_id, room_id, reset }) {
      if (reset) {
        if (room_id === 'mainRoom') {
          state.selected_main_private_chat.chat_id = ''
          state.selected_main_private_chat.room_id = ''
        } else {
          state.selected_game_private_chat.chat_id = ''
          state.selected_game_private_chat.room_id = ''
        }
        return
      }
      if (room_id === 'mainRoom') {
        state.selected_main_private_chat.chat_id = chat_id
        state.selected_main_private_chat.room_id = room_id
      } else {
        state.selected_game_private_chat.chat_id = chat_id
        state.selected_game_private_chat.room_id = room_id
      }
    },

    newRoom(state, { room_obj }) {
      if (isUnic(state.rooms, 'id', room_obj)) {
        state.rooms.push(room_obj)
        state.private_chats.push({
          room_id: room_obj.id,
          private_chats: [],
        })
      }
    },

    restoreRoomsAndPrivateChats(state, { rooms, chats = [] }) {
      state.rooms = [...rooms]
      state.private_chats = []

      rooms.forEach((room) => {
        const _chats = room.id === 'mainRoom' ? chats : []
        state.private_chats.push({
          room_id: room.id,
          private_chats: [..._chats],
        })
      })
    },

    setTempState(state, payload) {
      // if (!state.inside_room) return;
      const { game, direction } = payload
      const room_obj = game
      if (!room_obj) return
      const history = room_obj.history
      const current_temp_state = state.temp_gui_state
      if (direction === 'reset') {
        state.temp_gui_state = {}
      }

      if (history?.length < 2) return

      let current_snap_idx = history.findIndex((_snap) => _snap.snapshot_id === current_temp_state?.snapshot_id)
      if (current_snap_idx < 0) {
        current_snap_idx = history.length - 1
      }
      if (direction === 'back') {
        if (history.length > 1) {
          current_snap_idx--
        }
        if (current_snap_idx < 0) return
        // if (!state.cur_gui_cache) {
        //   state.cur_gui_cache = clone(room_obj.state.lands);
        // }
        const snap = history[current_snap_idx]
        state.temp_gui_state = Object.assign(state.temp_gui_state, snap)
        return
      }

      if (direction === 'forward') {
        current_snap_idx++
        if (current_snap_idx >= history.length - 1) {
          // room_obj.state.lands = Object.assign(room_obj.state.lands, state.cur_gui_cache);
          state.temp_gui_state = {}
          // state.cur_gui_cache = null;
          return
        }
        const snap = history[current_snap_idx]

        state.temp_gui_state = Object.assign(state.temp_gui_state, snap)
        return
      }
    },

    restorePrivateChats(state, { room_id, private_chats = [] }) {
      const chatsWrapper = state.private_chats.find((wrapper) => wrapper.room_id === room_id)

      if (!chatsWrapper) {
        state.private_chats.push({
          room_id,
          private_chats: [...private_chats],
        })
      } else {
        chatsWrapper.private_chats = [...private_chats]
      }
    },
    joinGame(state, payload) {
      const { room_id, type, chats } = payload
      if (!room_id) {
        console.error('room_id didnt get in args')
        return
      }
      if (type === 'leave_room') {
        state.private_chats = state.private_chats.filter((ch) => ch.room_id !== room_id)
      } else {
        if (!chats) {
          console.error('chats didnt get in args')
          return
        }

        const existed = state.private_chats.find((ch) => ch.room_id === room_id)
        if (existed) {
          existed.private_chats = chats
        } else {
          state.private_chats.push({
            room_id,
            private_chats: [...chats],
          })
        }
      }
    },

    setToken(state, { land_name, token, room_id }) {
      const room_obj = state.rooms.find((room_obj) => room_obj.id === room_id)
      const lands = room_obj.state.lands
      if (lands[land_name].token) {
        lands[land_name].token = ''
      } else {
        lands[land_name].token = token
        lands[land_name].house = token
      }
    },
    setOrder(state, { order, room_id }) {
      const { land_name, title } = order
      const room_obj = state.rooms.find((room_obj) => room_obj.id === room_id)
      const lands = room_obj.state.lands
      lands[land_name].order = title
    },

    setPlayer(state, { home, user, game_id }) {
      const cur_game = user.current_games?.find((g) => g.room_id === game_id)
      if (!cur_game) return ''
      cur_game.home = home
    },

    moveUnits(state, { room_id, drag_state }) {
      if (!drag_state) return
      const room_obj = state.rooms.find((room_obj) => room_obj.id === room_id)

      const { units, from } = drag_state
      units.forEach((u) => {
        if (!u.land_name || u.land_name === from) return
        const roomUnits = room_obj.state.lands[u.land_name].units
        if (roomUnits.some((_u) => _u.id === u.id)) return
        room_obj.state.lands[u.land_name].units.push(u)
        room_obj.state.lands[from].units = [
          ...room_obj.state.lands[from].units.filter((_u) => {
            return _u.id !== u.id
          }),
        ]
      })
    },

    setTrack(state, { homes, track_name, room_id }) {
      const room_obj = state.rooms.find((room_obj) => room_obj.id === room_id)
      room_obj.state.tracks[track_name] = [...homes]
    },

    showError(state, { error }) {
      state.error = error
    },
    showHintModal(state, { data }) {
      state.hint_modal = data
    },

    beckupLands(state, { room_id, lands }) {
      const room_obj = state.rooms.find((room_obj) => room_obj.id === room_id)
      if (!room_obj) return
      room_obj.state.lands = Object.assign(room_obj.state.lands, clone(lands))
      for (let player_state of room_obj.state.states) {
        player_state.units = { pawn: [], knite: [], kata: [], ship: [] }
      }
      for (let land_name in room_obj.state.lands) {
        const land_obj = room_obj.state.lands[land_name]
        const home = land_obj.home

        if (!home) continue
        if (home === 'neutral') continue
        if (home === 'neutral_disabled') continue

        const player_state = room_obj.state.states.find((st) => st.home === home)
        if (!player_state) continue
        land_obj.units.forEach((unit) => {
          player_state.units[unit.type].push(unit)
        })
      }
    },

    updateRoomFields(state, { room_id, fields }) {
      if ('state' in fields) {
        state.active_lands_beckup = Object.assign(state.active_lands_beckup, clone(fields.state.lands))
      }

      const room = state.rooms.find((room_obj) => room_obj.id === room_id)
      Object.keys(fields).forEach((str) => {
        const _fields = str.split('.')
        let _room_prop = room
        for (let field of _fields) {
          if (_room_prop && _room_prop.hasOwnProperty(field)) {
            _room_prop = _room_prop[field]
          } else {
            _room_prop = undefined
            break
          }
        }
        if (_room_prop) {
          if (Array.isArray(_room_prop)) {
            _room_prop.splice(0, _room_prop.length, ...fields[str])
          } else if (typeof _room_prop === 'object') {
            _room_prop = Object.assign(_room_prop, fields[str])
          } else {
            _room_prop = fields[str]
          }
        }
      })
    },

    processModal(state, payload) {
      if (!payload) {
        closeAllModals(state.modals)
        return
      }
      const { name, type = 'open' } = payload

      if (!name || !(name in state.modals)) {
        console.error(`need to add correct modal name: received: ${name}`)
        return
      }
      if (type === 'close') {
        state.modals[name] = false
      } else if (type === 'open') {
        state.modals[name] = true
      }
    },

    setDataForModal(state, { data, reset }) {
      if (reset) {
        state.data_for_modal = null
        return
      }
      if (!state.data_for_modal) {
        state.data_for_modal = {}
      }
      for (let key in data) {
        state.data_for_modal[key] = clone(data[key])
      }
    },
  },
}

function isUnic(array, field, unit) {
  return !array.some((entity) => entity[field] === unit[field])
}
function closeAllModals(modals) {
  Object.keys(modals).forEach((key) => {
    modals[key] = false
  })
}

const mock_user = {
  name: 'HUI',
  password: '',
  avatars: [],
  email: {
    value: '',
    verified: false,
  },
  room: 'mainRoom',
  current_games: [],
  sessions: [],
  id: '1234',
  role: {
    title: 'bastard',
    value: 'bastard',
  },
  online: false,
  friends: [],
  locale: 'uk',
  creation_time: Date.now(),
  my_friends: [],
  i_am_friend: [],
  black_list: [],
  recent_emoji: [],
  money: {
    initial: 100,
    total: 100,
    donate: 0,
    earned: 0,
  },
  nicknames_log: [],
  money_logs: {
    spend_log: [],
    send_log: [],
    earn_log: [],
    donate_log: [],
  },
  game_logs: [],
  sound_settings: {
    main: true,
    battle: true,
    message: true,
    exec: true,
    timer: true,
    round: true,
  },
  complaints: {
    play: [],
    chat: [],
    private_chat: [],
    avatar: [],
    nickname: [],
  },
  ban_counter: {
    play: 0,
    chat: 0,
    private_chat: 0,
    avatar: 0,
    nickname: 0,
  },
  ban: {
    play: {
      value: false,
      duration: null,
      ban_start: null,
      duration_ms: null,
      duration_type: null,
    },
    chat: {
      value: false,
      duration: null,
      ban_start: null,
      duration_ms: null,
      duration_type: null,
    },
    avatar: {
      value: false,
      duration: null,
      ban_start: null,
      duration_ms: null,
      duration_type: null,
    },
    nickname: {
      value: false,
      duration: null,
      ban_start: null,
      duration_ms: null,
      duration_type: null,
    },
    private_chat: {
      value: false,
      duration: null,
      ban_start: null,
      duration_ms: null,
      duration_type: null,
    },
  },
  stats: {
    win_overal: 0,
    intelligence_overal: 0,
    intelligence_stark: 0,
    intelligence_baratheon: 0,
    intelligence_lannister: 0,
    intelligence_tyrell: 0,
    intelligence_martell: 0,
    intelligence_greyjoy: 0,
    charm: 0,
    total_games_count: 0,
    leaved_games_count: 0,
    full_games_count: 0,
    full_games_percent: 0,
    total_hours_count: 0,
    saved_games_count: 0,

    more: {
      stark: {
        home: 'stark',
        types: {
          classic_6: {
            total: 0,
            wins: [0, 0, 0, 0, 0, 0],
          },
          classic_5: {
            total: 0,
            wins: [0, 0, 0, 0, 0],
          },
          classic_4: {
            total: 0,
            wins: [0, 0, 0, 0],
          },
          classic_3: {
            total: 0,
            wins: [0, 0, 0],
          },
        },
      },
      lannister: {
        home: 'lannister',
        types: {
          classic_6: {
            total: 0,
            wins: [0, 0, 0, 0, 0, 0],
          },
          classic_5: {
            total: 0,
            wins: [0, 0, 0, 0, 0],
          },
          classic_4: {
            total: 0,
            wins: [0, 0, 0, 0],
          },
          classic_3: {
            total: 0,
            wins: [0, 0, 0],
          },
        },
      },
      baratheon: {
        home: 'baratheon',
        types: {
          classic_6: {
            total: 0,
            wins: [0, 0, 0, 0, 0, 0],
          },
          classic_5: {
            total: 0,
            wins: [0, 0, 0, 0, 0],
          },
          classic_4: {
            total: 0,
            wins: [0, 0, 0, 0],
          },
          classic_3: {
            total: 0,
            wins: [0, 0, 0],
          },
        },
      },
      martell: {
        home: 'martell',
        types: {
          classic_6: {
            total: 0,
            wins: [0, 0, 0, 0, 0, 0],
          },
          classic_5: {
            total: 0,
            wins: [0, 0, 0, 0, 0],
          },
          classic_4: {
            total: 0,
            wins: [0, 0, 0, 0],
          },
          classic_3: {
            total: 0,
            wins: [0, 0, 0],
          },
        },
      },
      tyrell: {
        home: 'tyrell',
        types: {
          classic_6: {
            total: 0,
            wins: [0, 0, 0, 0, 0, 0],
          },
          classic_5: {
            total: 0,
            wins: [0, 0, 0, 0, 0],
          },
          classic_4: {
            total: 0,
            wins: [0, 0, 0, 0],
          },
          classic_3: {
            total: 0,
            wins: [0, 0, 0],
          },
        },
      },
      greyjoy: {
        home: 'greyjoy',
        types: {
          classic_6: {
            total: 0,
            wins: [0, 0, 0, 0, 0, 0],
          },
          classic_5: {
            total: 0,
            wins: [0, 0, 0, 0, 0],
          },
          classic_4: {
            total: 0,
            wins: [0, 0, 0, 0],
          },
          classic_3: {
            total: 0,
            wins: [0, 0, 0],
          },
        },
      },
    },
  },
}
