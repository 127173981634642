<template>
  <div id="wrapper" ref="wrapper" class="layer_modal_container">
    <Transition appear>
      <InfoBankModal v-if="modals.bank_log" />
    </Transition>
    <Transition appear>
      <HintWildingCardModal v-if="modals.wilding_card" />
    </Transition>
    <Transition appear>
      <ConfirmBidModal v-if="modals.bid" />
    </Transition>
    <Transition appear>
      <HintHouseCardModal v-if="modals.card_hint" />
    </Transition>
    <Transition appear>
      <InfoGameOverModal v-if="modals.game_over" />
    </Transition>
    <Transition appear>
      <HistoryWesterosModal v-if="modals.westeros_history" />
    </Transition>
    <Transition appear>
      <HistoryBidModal v-if="modals.bid_history" />
    </Transition>
    <Transition appear>
      <HistoryWildingsModal v-if="modals.wildings_history" />
    </Transition>

    <Transition appear>
      <RedactorLandModal v-if="modals.redactor_land" />
    </Transition>
    <Transition appear>
      <ConfirmComplaintModal v-if="modals.complaint" />
    </Transition>
    <Transition appear>
      <HistoryComplaintModal v-if="modals.complaint_list" />
    </Transition>
    <Transition appear>
      <HistoryPlayersModal v-if="modals.players_log" />
    </Transition>
    <Transition appear>
      <ConfirmInvitationModal v-if="modals.invitation" />
    </Transition>
    <Transition appear>
      <ConfirmTransferMoneyModal v-if="modals.transfer" />
    </Transition>

    <Transition appear>
      <ConfirmDeleteUserModal v-if="modals.delete_user_confirm" />
    </Transition>
    <Transition appear>
      <RedactorUserModal v-if="modals.user_edit" />
    </Transition>

    <Transition appear>
      <ConfirmLeaveRoomModal v-if="modals.leave_room" />
    </Transition>
    <Transition appear>
      <CreateEmailModal v-if="modals.new_email" />
    </Transition>
    <Transition appear>
      <ResetPasswordModal v-if="modals.reset_password" />
    </Transition>
    <Transition appear>
      <CreatePasswordModal v-if="modals.new_password" />
    </Transition>
    <Transition appear>
      <CreateUserModal v-if="modals.new_user" />
    </Transition>
    <Transition appear>
      <CreateGameModal v-if="modals.create_game" />
    </Transition>
    <Transition appear>
      <SoundSettingsModal v-if="modals.sound_settings" />
    </Transition>
    <Transition appear>
      <InfoPersonModal v-if="modals.person" />
    </Transition>
    <Transition>
      <ConfirmPayModal v-if="modals.confirm_pay" />
    </Transition>
    <Transition appear>
      <HistoryGamesModal v-if="modals.games_log" />
    </Transition>
    <Transition appear>
      <HistoryUserTransactionsModal v-if="modals.money_log" />
    </Transition>
    <Transition appear>
      <HistoryAllTransactionsModal v-if="modals.pool_log" />
    </Transition>

    <Transition appear>
      <SocialUsersModal v-if="modals.social" />
    </Transition>
    <Transition appear>
      <InfoBanModal v-if="modals.ban_state" />
    </Transition>
    <Transition appear>
      <ConfirmDonateModal v-if="modals.donate" />
    </Transition>
    <Transition appear>
      <InfoUserModal v-if="modals.user_info" />
    </Transition>
    <Transition appear>
      <HintAwardModal v-if="modals.award_hint" />
    </Transition>
    <Transition appear>
      <AllCardsModal v-if="modals.all_cards" />
    </Transition>
    <Transition appear>
      <InfoCardZoomModal v-if="modals.card_zoom" />
    </Transition>

    <Transition appear>
      <InfoNewRoundModal v-if="modals.round_changed" />
    </Transition>
    <Transition appear>
      <HeepHousesModal v-if="modals.houses" />
    </Transition>

    <Transition appear>
      <ConfirmSetGamePasswordModal v-if="modals.join_password" />
    </Transition>
    <Transition appear>
      <ConfirmJoinGameModal v-if="modals.join_game" />
    </Transition>

    <Transition appear>
      <HeepEmojiiModal v-if="modals.emojii" />
    </Transition>
    <Transition appear>
      <ConfirmDelModal v-if="modals.confirm_del" />
    </Transition>
    <Transition appear>
      <InfoErrorModal v-if="modals.error" />
    </Transition>
    <Transition appear>
      <InfoHintModal v-if="modals.hint_modal" />
    </Transition>
    <Transition appear>
      <InfoModal v-if="modals.game_info" />
    </Transition>
  </div>
</template>
<script>
  import { mapState } from 'vuex'

  export default {
    computed: {
      ...mapState('general_store', ['modals']),
    },
  }
</script>
<style lang="scss" scoped>
  .layer_modal_container {
    position: absolute;
    width: auto;
    height: auto;
    z-index: 50;
    top: 0;
    left: 0;
    pointer-events: none;
  }

  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.35s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }
</style>
