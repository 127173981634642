<template>
  <div class="track_container">
    <div v-if="game.game_options.bank.buy_in" class="bank" @click="openBankLogModal">
      <img src="/images/money.png" alt="buy_in" @dragstart.prevent />
      <span> {{ bank_size }}</span>
    </div>
    <div class="wildings-wrap">
      <div v-for="n in 13" :key="n" class="wildings">
        <img
          v-if="wildings === n - 1"
          :src="`/images/tokens/wilding_token_track.png`"
          alt="wild_token"
          @dragstart.prevent
        />
      </div>
    </div>

    <div
      class="wildings_card_wrap"
      :class="{ disable: isHistoryModeOn, wild_card_hint }"
      data-type="wildingCard"
      @click="wildCardClick"
    >
      <img
        v-if="lastWildingsCard"
        class="wildings-card-img"
        :src="`/images/westeros_cards/wildings/${locale}/${lastWildingsCard}.png`"
        alt="wildings_back"
        @click="wildCardClick"
        @dragstart.prevent
      />
    </div>

    <div class="westeros_wrap">
      <span class="material-icons west_history" @click="openWestHistory"> history_edu </span>
      <div v-for="num of 3" :key="num" class="west_card_wrap">
        <div v-if="showBtns(num - 1)" class="west_btns_wrap">
          <div class="west_btn" :class="{ disable: isHistoryModeOn }" @click="westerosBtnClickHandler(1)">1</div>
          <div class="west_btn" :class="{ disable: isHistoryModeOn }" @click="westerosBtnClickHandler(2)">2</div>
          <div class="west_btn" :class="{ disable: isHistoryModeOn }" @click="westerosBtnClickHandler(3)">3</div>
        </div>
        <img
          :src="getWesterosCardSrc(num - 1)"
          :alt="`westeros_${num - 1}_card`"
          :class="{ west_card_hint: hintWestCard(num - 1) }"
          @dragstart.prevent
        />
      </div>
    </div>

    <div class="track-wrap">
      <div v-for="{ title, className, short_track } in track_labels" :key="title" class="line_wrap">
        <div v-if="game?.game_options.max_players === 3 && short_track" class="crow_track_short">
          <img class="track_short" src="/images/crow_track_short.png" alt="crow_short_track" @dragstart.prevent />
        </div>
        <div
          class="track_label"
          :class="{
            disable: isHistoryModeOn,
            [className]: true,
            hint_track: hint_track_label(title),
            cursor_pointer: handler?.allowCursorPointerOnTrack?.(hint_track_label(title)),
          }"
          :data-source="title"
          @click="trackLabelClick"
        ></div>

        <div
          v-for="(house, num) in tracks[title]"
          :id="house + '_throne'"
          :key="house"
          class="item"
          data-type="token"
          :data-house="house"
          :data-track="title"
          :data-num="num"
          :data-id="`${house}_${title}_token`"
        >
          <div class="bid_label">{{ findBid(title, house) }}</div>
          <div :class="{ pulse: checkActive(title, house) }"></div>

          <img
            :id="`${house}_${title}_token`"
            :ref="`${house}_${title}_token`"
            :data-house="house"
            :data-track="title"
            :data-num="num"
            data-type="token"
            :src="`/images/tokens/house_tokens/track_tokens/${house}_track.png`"
            :alt="`${house}_token`"
            :class="{ disable: isHistoryModeOn, conflict_bid: conflict_bid(house, title) }"
            @pointerdown="mousedownHandler"
            @dragstart.prevent="dragStart"
          />
        </div>
      </div>
    </div>

    <div class="supply-wrap">
      <div v-for="n in 7" :key="n" class="sup">
        <div v-for="(arr, idx) in tracks.supply" :key="idx">
          <img
            v-for="home in arr"
            v-show="idx === n - 1"
            :key="home"
            :src="`/images/tokens/house_tokens/track_tokens/${home}_supply.png`"
            :alt="home"
            @dragstart.prevent
          />
        </div>
      </div>
    </div>

    <div class="footer_track">
      <div class="rounds-wrap">
        <div v-for="n in 10" :key="n" class="round">
          <div v-if="round == n">
            <img src="/images/round_token.png" class="roundToken" alt="round_token.png" @dragstart.prevent />
          </div>
        </div>
      </div>

      <div class="castles-wrap">
        <div v-for="n in 8" :key="n" class="castle">
          <div v-for="(num, namme) in castles" :key="namme" class="castle-wrap">
            <img
              v-if="num === n - 1"
              :src="`/images/tokens/house_tokens/track_tokens/${namme}_victory.png`"
              :alt="namme"
              @dragstart.prevent
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters, mapMutations, mapState } from 'vuex'

  export default {
    props: {
      handler: Object,
    },

    data() {
      return {
        wild_card_hint_hover: false,
        track_labels: [
          {
            title: 'throne',
            className: 'throne_track',
            short_track: false,
          },
          {
            title: 'sword',
            className: 'sword_track',
            short_track: false,
          },
          {
            title: 'crow',
            className: 'crow_track',
            short_track: true,
          },
        ],
      }
    },

    computed: {
      ...mapGetters('general_store', [
        'user',
        'home',
        'game',
        'tracks',
        'lands',
        'state',
        'phase_name',
        'active_players',
        'westerosCards',
        'round',
        'lastWildingsCard',
        'isHistoryModeOn',
      ]),

      ...mapState('general_store', ['modals']),
      ...mapState('gui_store', ['hint_track_labels']),

      locale() {
        return this.user.locale
      },

      bank_size() {
        return this.game.game_options.bank.list.length * this.game.game_options.bank.buy_in
      },

      wild_card_hint() {
        if (!this.state) return false
        if (this.isHistoryModeOn) return false
        const phases = ['CrowCheck', 'ThroneDecision', 'WildingManual']
        return phases.includes(this.phase_name)
      },

      castles() {
        if (!this.state) return {}
        const lands = this.state.lands || []
        const states = this.state.states || []
        const castles = {}

        for (let st of states) {
          castles[st.home] = st.lands.reduce((acc, land_name) => {
            if (lands[land_name].master_points) return ++acc
            return acc
          }, 0)
        }
        return castles
      },

      wildings() {
        return +this.tracks?.wildings
      },
      conflict_bids() {
        return this.state?.conflict_bids || []
      },
      conflict_track() {
        return this.state?.current_track_bid || ''
      },
    },

    methods: {
      ...mapMutations('general_store', ['processModal']),

      openBankLogModal() {
        this.processModal({ name: 'bank_log' })
      },
      openWestHistory() {
        this.processModal({ name: 'westeros_history' })
      },
      dragStart(e) {
        return false
      },

      showBtns(num) {
        if (!this.active_players.includes(this.home)) return false
        const phases = ['BlackWings', 'ThroneOfBlades', 'PutToTheSword']
        if (!phases.includes(this.phase_name)) return false

        const cardName = this.westerosCards[num]
        if (this.phase_name !== cardName) return false
        if (!phases.includes(cardName)) return false

        return true
      },

      hintWestCard(num) {
        const phases = ['BlackWings', 'ThroneOfBlades', 'PutToTheSword']
        if (!phases.includes(this.phase_name)) return false

        const cardName = this.westerosCards[num]
        if (this.phase_name !== cardName) return false
        if (!phases.includes(cardName)) return false

        return true
      },

      hint_track_label(trackName) {
        return this.hint_track_labels.has(trackName)
      },

      conflict_bid(house, title) {
        const first = title === this.conflict_track
        const second = this.conflict_bids.includes(house)
        return first && second
      },

      westerosBtnClickHandler(num) {
        if (this.isHistoryModeOn) return
        this.handler?.westeros_btn_click?.(num)
      },

      mousedownHandler(e) {
        if (this.isHistoryModeOn) return
        this.handler?.mousedownHandler?.(e)
      },

      findBid(trackName, home) {
        const bidState = this.state?.bid_state
        const track = bidState[trackName]
        const bid = track.find((cand) => cand?.home === home)

        return bid?.bid
      },

      checkActive(trackName, home) {
        if (this.phase_name === 'WarOfKings') {
          if (trackName !== this.conflict_track) return false
          if (this.active_players?.includes(home)) return true
        }
        if (trackName !== 'throne') return false
        if (this.active_players?.includes(home)) return true

        return false
      },

      trackLabelClick(e) {
        if (this.isHistoryModeOn) return
        this.handler?.trackLabelClick?.(e)
      },

      wildCardClick(e) {
        e.preventDefault()
        if (this.phase_name !== 'CrowCheck' && !this.lastWildingsCard) return

        this.processModal({ name: 'wilding_card' })
      },

      getWesterosCardSrc(num) {
        if (!this.westerosCards[num]) return `/images/westeros_cards/westeros_${num}/westeros_${num}_back.png`
        return `/images/westeros_cards/westeros_${num}/${this.locale}/${this.westerosCards[num]}.png`
      },
    },
  }
</script>

<style lang="scss" scoped="true">
  .bank {
    cursor: pointer;
    box-sizing: border-box;
    align-items: center;
    display: flex;
    position: absolute;
    right: -600px;
    bottom: 0;
    width: 550px;
    height: 550px;

    img {
      width: auto;
      height: 250px;
    }
    span {
      // position: absolute;
      color: gold;
      display: block;
      text-align: center;
      font-size: 8rem;
      transition: all 0.25s;
    }
    &:hover span {
      transform: scale(1.2); /* Adjust the font size as needed */
    }
  }
  .crow_track_short {
    width: auto;
    left: -3px;
    top: 134px;
    position: absolute;
    .track_short {
      width: 120px;
      height: auto;
    }
  }
  .conflict_bid {
    border: 9px solid red;
    border-radius: 50%;
    margin: -9px;
  }
  .pulse_track {
    width: 112px;
    height: 158px;
    bottom: -215px;
    left: -5px;
    position: absolute;
    border-radius: 30%;
    animation: pulse_track 1s linear 0s infinite alternate;
  }
  .hint_track {
    width: 112px;
    height: 158px;
    bottom: -215px;
    left: -5px;
    border: 2px solid blue;
    position: absolute;
    border-radius: 30%;
    animation: pulse 1s linear 0s infinite alternate;
  }

  .cursor_pointer {
    cursor: pointer;
  }

  .pulse {
    position: absolute;
    text-align: center;
    margin-left: 7px;
    width: 95px;
    height: 95px;
    pointer-events: none;
    touch-action: none;
    background-color: aliceblue;
    border-radius: 50%;
    animation: pulse 1s linear 0s infinite alternate;
  }
  @keyframes pulse_track {
    from {
      background-color: rgba(237, 68, 68, 0.786);
    }
    to {
      background-color: rgba(239, 49, 49, 0.234);
    }
  }
  @keyframes pulse {
    from {
      background-color: rgba(249, 249, 249, 0.712);
    }
    to {
      background-color: rgba(255, 255, 255, 0);
    }
  }
  .tokenSelected {
    border: 5px solid white;
    border-radius: 50%;
    margin: -5px;
  }
  .track_container {
    position: absolute;
    left: 1494px;
    width: 500px;
    height: 100%;
    //  background-color: rgba(225, 217, 205, 0.399);
  }
  .footer_track {
    position: relative;
    top: 353px;
    left: 50px;
    width: 375px;
    height: 922px;
    display: flex;
    //background-color: rgba(127, 255, 212, 0.363);
  }
  .track-wrap {
    position: relative;
    margin-bottom: 20px;
    // background-color: rgba(220, 174, 105, 0.558);
    width: 410px;
    height: 848px;
    display: flex;
    justify-content: space-between;
    left: 28px;
    top: -22px;
    img {
      width: 94px;
    }
  }

  .line_wrap {
    position: relative;
    //background-color: rgba(215, 120, 65, 0.558);
    width: 113px;
    height: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
  }
  .item {
    position: relative;

    margin-top: 52px;
    // background-color: rgba(150, 211, 93, 0.558);
    text-align: center;
    // position: relative;
    width: 100%;
    height: auto;
    img {
      touch-action: none;
    }
  }
  .bid_label {
    pointer-events: none;
    touch-action: none;
    position: absolute;
    color: black;
    z-index: 50;
    font-size: 50px;
    font-weight: 600;
    left: 45px;
    top: 50px;
  }
  .supply-wrap {
    position: relative;
    flex-direction: column-reverse;
    // background-color: rgba(220, 174, 105, 0.558);
    width: 124px;
    height: 656px;
    display: flex;

    justify-content: space-between;
    left: 124px;
    top: 284px;

    img {
      width: 40px;
    }
  }

  .rounds-wrap {
    position: relative;
    // background-color: rgba(220, 174, 105, 0.558);
    width: 125px;
    height: 865px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    left: 34px;
    top: 50px;

    img {
      width: 83px;
    }
  }
  .round {
    justify-content: center;
    align-content: center;
    text-align: center;
    // background-color: rgba(198, 111, 111, 0.397);
    width: 100%;
    height: 81px;
    display: flex;
    flex-wrap: wrap;
  }
  .roundToken {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

  .sup {
    justify-content: center;
    align-content: center;
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .castles-wrap {
    position: relative;
    width: 125px;
    height: 1043px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    left: 118px;
    top: 21px;
  }

  .castle {
    justify-content: center;
    align-content: center;
    text-align: center;
    width: auto;
    height: 133px;
    display: flex;
    flex-wrap: wrap;

    // background-color: rgba(250, 235, 215, 0.352);
  }

  .castle-wrap {
    width: auto;
    height: auto;
    margin-bottom: -25px;
  }

  .wildings-wrap {
    position: relative;
    margin-bottom: 10px;
    // background-color: rgba(220, 174, 105, 0.558);
    width: 675px;
    height: 96px;
    display: flex;
    left: -1101px;
    top: 74px;
    align-items: center;

    img {
      width: 100%;
      height: auto;
    }
  }

  .wildings_card_wrap {
    position: absolute;
    width: 307px;
    height: 196px;
    display: flex;
    left: -347px;
    top: 20px;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img {
      cursor: pointer;
      width: auto;
      height: 307px;
      transform: rotate(-90deg);
    }
  }

  .wild_card_hint {
    outline: 8px solid rgb(2, 167, 213);
    border-radius: 0.4rem;

    &:hover {
      outline: 8px solid rgb(255, 255, 255);
    }
  }

  .wildings {
    justify-content: center;
    align-content: center;
    text-align: center;
    //background-color: rgba(198, 111, 111, 0.397);
    width: 90%;
    height: 52px;
    display: flex;
  }

  .status_bar {
    top: -610px;
    position: absolute;
    text-align: center;
    width: 794px;
    height: 566px;
    font-size: 60px;
    color: white;
    background-color: black;
  }

  .westeros_wrap {
    position: absolute;
    left: 500px;
    top: 600px;

    img {
      width: 800px;
      -webkit-box-shadow: 0px 0px 40px 20px #000000;
      box-shadow: 0px 0px 40px 20px #000000;
    }
  }

  .west_history {
    position: absolute;
    color: rgb(90, 36, 36);
    z-index: 100;
    font-size: 10rem;
    top: -200px;
    cursor: pointer;

    &:hover {
      color: white;
    }
  }

  .west_card_wrap {
    position: relative;
    margin-bottom: 35px;
  }

  .west_btns_wrap {
    z-index: 5;
    position: absolute;
    display: flex;
    font-size: 38px;
    font-weight: 800;
    color: rgb(127, 123, 123);
    top: 269px;
    left: 269px;
  }
  .west_btn {
    width: 50px;
    height: 50px;
    border: 2px solid green;
    border-radius: 80%;
    margin: 15px;
    text-align: center;
    background-color: black;
    &:hover {
      cursor: pointer;
      color: white;
      border-color: white;
    }
    transition: color 0.2s ease-in, border-color 0.2s ease-in;
    // transition: border-color 0.5s ease-in;
  }

  .west_card_hint {
    outline: 8px solid red;
    // margin: -4px;
    border-radius: 20px;
  }

  .disable {
    cursor: grab;
    pointer-events: none;
  }
</style>
