<template>
  <div class="sidebar_left_container" :style="{ transform: container_hide }">
    <TabUsersList
      :class="{
        hide: hide('usersActive'),
        fast_hide: main_menu_open,
        tab: true,
      }"
    />
    <TabPrivateChat
      :tab-type="'main_private_chat'"
      :class="{
        hide: hide('privateChatActive'),
        fast_hide: main_menu_open,
        tab: true,
      }"
      :room="mainRoom"
    />
    <TabChat
      :class="{
        hide: hide('chatActive'),
        fast_hide: main_menu_open,
        tab: true,
      }"
      :room="mainRoom"
      :tab-type="'main_chat'"
    />
    <NavButtonsLeft :game="game" />
  </div>
</template>

<script>
  import { mapGetters, mapMutations, mapState } from 'vuex'
  export default {
    props: {
      game: {
        type: Object,
        default: () => {
          return {}
        },
      },
    },

    data() {
      return {
        chatActive: false,
      }
    },

    computed: {
      ...mapGetters('general_store', ['mainRoom', 'users', 'user']),

      ...mapState('gui_store', ['main_side_bar']),

      container_hide() {
        if (this.main_menu_open) {
          if (window.innerWidth / window.innerHeight > 4 / 3 && window.innerHeight < 400) {
            return `translate(305px)`
          }
          return `translate(100%)`
        }
        return `none`
      },

      // container_hide() {
      //   if (this.menu_open) {
      //     if (window.innerWidth / window.innerHeight > 4 / 3 && window.innerHeight < 400) {
      //       return `translateX(-300px)`
      //     }
      //     return `translateX(-100%)`
      //   }

      //   return `none`
      // },

      main_menu_open() {
        return Object.keys(this.main_side_bar).some((k) => this.main_side_bar[k] === true)
      },
    },

    watch: {
      main_menu_open() {
        if (this.main_menu_open) {
          if (window.innerWidth < 1285) {
            this.setGameSideTab()
          }
        }
      },
    },

    methods: {
      ...mapMutations('gui_store', ['setGameSideTab']),

      hide(type) {
        if (this.main_side_bar[type]) {
          return false
        }
        return true
      },
    },
  }
</script>

<style lang="scss" scoped>
  .sidebar_left_container {
    position: absolute;
    z-index: 15;
    display: flex;
    justify-content: flex-end;
    height: 100dvh;
    width: 400px;
    max-width: 80%;
    left: -400px;
    bottom: 0;
    box-sizing: border-box;
    background-color: $theme_background;
    transition: all 1s;
    border: 3px solid white;
    border-top: none;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none;
    @media screen and (max-width: 700px) {
      left: -65%;
      width: 65%;
    }
    @media screen and (max-width: 400px) {
      left: -80%;
      width: 80%;
    }
    .tab {
      position: absolute;
      right: 0;
      width: 100%;
      @media (min-aspect-ratio: 4/3) and (max-height: 400px) {
        width: 300px;
      }
    }
  }

  .hide {
    transform: translateX(-250%);
    transition-delay: 1s;
    transition-property: all;
  }

  .fast_hide {
    transition-delay: 0s;
    transition-property: all;
  }
</style>
