<template>
  <div class="searching_timer no_select">
    <span class="material-icons">search</span>
    <div class="timer">{{ calc_searching_timer }}</div>
  </div>
</template>

<script>
  import { searching_timer } from '#static_data'
  import { mapState, mapGetters } from 'vuex'
  export default {
    computed: {
      ...mapState('general_store', ['timers']),
      ...mapState('gui_store', ['freeze']),

      ...mapGetters('general_store', ['game', 'phase_name', 'active_players', 'isHistoryModeOn', 'home', 'isAdmin']),

      searching() {
        return this.game?.state.status === 'searching'
      },

      user_timer() {
        if (this.home === 'watcher') return

        return this.formatTimer(this.timers[this.home])
      },

      calc_searching_timer() {
        return this.formatTimer(this.timers[this.game.id] || searching_timer)
      },

      isMyTurn() {
        if (this.phase_name === 'GameOver') return false
        if (this.active_players?.includes(this.home) && !this.isHistoryModeOn && !this.freeze.freeze) {
          return true
        }
        return false
      },
    },

    methods: {
      formatTimer(duration) {
        const hrs = ~~(duration / 3600)
        const mins = ~~((duration % 3600) / 60)
        const secs = ~~duration % 60

        // Output like "1:01" or "4:03:59" or "123:03:59"
        let ret = ''

        if (hrs > 0) {
          ret += '' + hrs + ':' + (mins < 10 ? '0' : '')
        }

        ret += '' + mins + ':' + (secs < 10 ? '0' : '')
        ret += '' + secs

        return ret
      },
    },
  }
</script>

<style lang="scss" scoped>
  .no_select {
    -webkit-user-select: none; /* Для iOS и Safari */
    -moz-user-select: none; /* Для Firefox */
    -ms-user-select: none; /* Для старых версий Edge */
    user-select: none; /* Для предотвращения выделения */
  }

  .searching_timer {
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translateX(-60%);
    color: rgba(128, 128, 128, 0.63);
    display: flex;
    align-items: center;
    pointer-events: none;
  }
</style>
