<template>
  <div id="modal_base" class="modal_base" @click="close">
    <div id="wrap" class="modal_container hide_scrollbar red_box_shadow" ref="wrap">
      <div class="inner_wr">
        <div class="ava_field">
          <div class="top-wr">
            <img :src="`/images/avatars/${processed_user?.avatars[0]}`" alt="avatar" />
          </div>

          <div class="stat_wr_simple">
            <div class="field">
              <div class="field_title_wr">
                <span
                  class="material-icons"
                  :style="{
                    color: '#75cf75',
                  }"
                >
                  accessibility
                </span>
                <span>{{ $t(`message.username`) }} - </span>
              </div>
              <span class="nickname">{{ processed_user?.name }}</span>
            </div>
            <div class="field">
              <div class="field_title_wr">
                <span
                  class="material-icons"
                  :style="{
                    color: '#75cf75',
                  }"
                >
                  calendar_month
                </span>
                <span>{{ $t(`message.registration`) }} - </span>
              </div>

              <span>{{ new Date(processed_user?.creation_time).toLocaleDateString() }}</span>
            </div>
            <div class="field">
              <div class="field_title_wr">
                <span
                  class="material-icons"
                  :style="{
                    color: '#da1717',
                  }"
                >
                  emoji_events
                </span>
                <span>{{ $t(`message.hint_award_count`) }} - </span>
              </div>
              <span>{{ awardsCount }}</span>
            </div>
            <div class="field">
              <div class="field_title_wr">
                <span
                  class="material-icons"
                  :style="{
                    color: '#da1717',
                  }"
                >
                  favorite
                </span>
                <span>{{ $t(`message.charm`) }} - </span>
              </div>
              <span>{{ processed_user?.stats?.charm }}</span>
            </div>
            <div class="field">
              <div class="field_title_wr">
                <span
                  class="material-icons"
                  :style="{
                    color: '#e26464',
                  }"
                >
                  query_builder
                </span>
                <span>{{ $t(`message.hint_hours_overal`) }} - </span>
              </div>
              <span>{{ processed_user?.stats.total_hours_count.toFixed() }}</span>
            </div>
            <div class="field">
              <div class="field_title_wr">
                <span
                  class="material-icons"
                  :style="{
                    color: '#75cf75',
                  }"
                >
                  hourglass_empty
                </span>
                <span>{{ $t(`message.hint_games_overal`) }} - </span>
              </div>
              <span>{{ processed_user?.stats.total_games_count }}</span>
            </div>
            <div class="field">
              <div class="field_title_wr">
                <span
                  class="material-icons"
                  :style="{
                    color: '#75cf75',
                  }"
                >
                  verified
                </span>
                <span>{{ $t(`message.hint_fullgames_overal_short`) }} - </span>
              </div>
              <span>{{ processed_user?.stats.full_games_count }}</span>
            </div>
            <div class="field">
              <div class="field_title_wr">
                <span
                  class="material-icons"
                  :style="{
                    color: 'orange',
                  }"
                >
                  stars
                </span>
                <span>{{ $t(`message.honor`) }} - </span>
              </div>
              <span>{{ processed_user?.stats.full_games_percent }}</span>
            </div>
            <div class="field">
              <div class="field_title_wr">
                <span
                  class="material-icons"
                  :style="{
                    color: 'rgb(253 248 0)',
                  }"
                >
                  emoji_events
                </span>
                <span>{{ $t(`message.hint_win_overal`) }} - </span>
              </div>
              <span>{{ processed_user?.stats.win_overal }}</span>
            </div>
            <div class="field">
              <div class="field_title_wr">
                <span
                  class="material-icons"
                  :style="{
                    color: '#bfbff1',
                  }"
                >
                  psychology
                </span>
                <span>{{ $t(`message.intelligence`) }} - </span>
              </div>
              <span>{{ processed_user?.stats.intelligence_overal }}</span>
            </div>
            <div class="field">
              <div class="field_title_wr">
                <span
                  class="material-icons"
                  :style="{
                    color: 'green',
                  }"
                >
                  currency_bitcoin
                </span>
                <span>{{ $t(`message.hint_money`) }} - </span>
              </div>
              <span>{{ processed_user?.money.total }}</span>
            </div>
          </div>
        </div>
        <div class="tabs-wr">
          <div
            v-for="n in types_game"
            :key="n"
            class="tab"
            :class="{ active: +n === +selected_tab }"
            @click="selected_tab = n"
          >
            {{ n }}
          </div>
        </div>
        <div class="stat_wr">
          <div v-for="num in types_game" :key="num" class="field pie_field" :class="{ hidden: num !== selected_tab }">
            <span>{{ $t(`message.hint_${num}_classic`) }} </span>
            <div class="chart_container">
              <canvas :id="num" :ref="setItemRef"></canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Chart from 'chart.js/auto'

  import { mapGetters, mapMutations, mapState } from 'vuex'

  export default {
    data() {
      return {
        canvasItems: [],
        createdCharts: [],
        selected_tab: 6,
        types_game: [6, 5, 4, 3],
        isOpen: false,

        options: {
          borderRadius: 3,

          scales: {
            x: { stacked: true, display: false },
            y: {
              stepSize: 1,
              suggestedMax: 1,
              // suggestedMin: 1,
              stacked: false,
              ticks: {
                stepSize: 1,
                beginAtZero: true,
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },

          responsive: true,
          animation: false,
        },
      }
    },

    computed: {
      ...mapState('general_store', ['data_for_modal']),
      ...mapGetters('general_store', ['mainRoom']),
      ...mapState({
        usersActive(state) {
          return state.gui_store.main_side_bar.usersActive
        },
      }),
      processed_user() {
        return this.data_for_modal?.processed_user
      },

      awardsCount() {
        return Object.values(this.mainRoom.main_stats.awards).reduce((acc, user_id) => {
          if (user_id === this.processed_user.id) return ++acc
          return acc
        }, 0)
      },
    },

    watch: {
      selected_tab() {
        this.$refs.wrap.scrollTop = this.$refs.wrap.scrollHeight
      },
    },

    mounted() {
      this.init_user()
    },

    methods: {
      ...mapMutations('general_store', ['processModal', 'setDataForModal']),
      close(e) {
        if (e && e.target.id !== 'modal_base') return
        this.createdCharts.forEach((chart) => {
          chart.destroy()
        })
        this.canvasItems.forEach((chart) => {
          chart.remove()
        })

        this.processModal()

        this.setDataForModal({ reset: true })
        this.createdCharts = []
        this.canvasItems = []
      },

      setItemRef(el) {
        if (el) {
          this.canvasItems.push(el)
        }
      },

      init_user() {
        for (let n of this.types_game.keys()) {
          const ctx = this.canvasItems[+n]

          if (!ctx) continue
          const data = this.getBarData('classic', this.types_game[n])

          const chart = new Chart(ctx, {
            type: 'bar',
            data,
            options: this.options,
          })

          this.createdCharts.push(chart)
        }
      },

      getBarData(type, num) {
        const game_stats = this.getGamesStats(type, num)
        return {
          labels: [
            this.$t(`home.stark`),
            this.$t(`home.lannister`),
            this.$t(`home.baratheon`),
            this.$t(`home.greyjoy`),
            this.$t(`home.tyrell`),
            this.$t(`home.martell`),
          ].slice(0, num),
          datasets: [
            {
              label: this.$t(`message.hint_fullgames_overal_short`),
              data: game_stats.games_overal,
              backgroundColor: ['#777575', '#e9adad', '#eaefd2', '#454c44', '#7ca386', '#ecd0a3'].slice(0, num),
              barPercentage: 0.8,
              order: 1,
            },
            {
              label: this.$t(`message.hint_win_overal`),
              data: game_stats.wins_homes,
              backgroundColor: ['#e0e2de', '#ed2525', '#f3f86c', '#212220', '#0c6d08', '#f78f19'].slice(0, num),
              // categoryPercentage: 1, // grouped: false,
              barPercentage: 0.8,
              order: 0,
            },
          ],
        }
      },
      getGamesStats(type, num) {
        let games_overal = []
        let wins_homes = []
        const queue = ['stark', 'lannister', 'baratheon', 'martell', 'tyrell', 'greyjoy']
        queue.forEach((home) => {
          const total = this.processed_user.stats.more[home].types[`${type}_${num}`]?.total || 0.1
          const wins = this.processed_user.stats.more[home].types[`${type}_${num}`]?.wins[0]
          games_overal.push(total)
          wins_homes.push(wins)
        })

        return {
          games_overal,
          wins_homes,
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .modal_base {
    position: relative;
    pointer-events: all;
    width: 100dvw;
    height: 100dvh;
    background-color: $modals_background_color;
  }

  .modal_container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 120;
    width: 400px;
    min-width: 280px;
    max-width: $modals_max_width;
    height: 100%;
    max-height: $modals_max_height;
    padding: 1rem;
    background-color: $modals_color;
    border-radius: $theme_border_radius;

    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }

  .inner_wr {
    position: relative;
    height: fit-content;
    max-height: 100%;
    width: 100%;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    align-items: center;
    background-color: $modals_color;
    overflow-y: auto;
    .ava_field {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: white;
      width: 100%;
      gap: 1rem;
      .top-wr {
        display: flex;
        justify-content: center;

        img {
          width: 200px;
          border-radius: 0.4rem;
        }
      }

      .stat_wr_simple {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
        width: 80%;

        .field {
          display: flex;
          width: 100%;
          height: fit-content;
          justify-content: space-between;

          .field_title_wr {
            display: flex;
            align-items: center;
            gap: 0.4rem;
            width: 70%;
          }
          .nickname {
            text-align: right;
            font-size: 1rem;
            word-wrap: break-word;
            width: auto;
            overflow-x: hidden;
          }
        }
      }
    }

    .tabs-wr {
      display: flex;
      justify-content: center;
      gap: 1rem;
      .tab {
        cursor: pointer;
        color: black;
        width: 2rem;
        background-color: antiquewhite;
        text-align: center;
        padding: 5px;
        border-radius: 3px;
      }
      .active {
        color: white;
        background-color: rgb(7, 92, 7);
      }
    }
    .stat_wr {
      position: relative;
      display: flex;
      flex-direction: column;
      height: auto;
      gap: 0.3rem;
      align-items: center;
      width: 100%;
      color: white;
      font-weight: 400;
      .pie_field {
        align-self: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: auto;
        width: 100%;
        .chart_container {
          height: 200px;
          display: flex;
          justify-content: center;
          width: 100%;
          position: relative;
        }
      }
      .hidden {
        display: none;
      }
    }
  }
</style>
