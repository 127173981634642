<template>
  <div id="modal_base" class="modal_base" @click="close">
    <div class="pool_log_container">
      <div class="nav_bar">
        <div :class="{ tab: true, active: active_tab === 'overal_log' }" @click="active_tab = 'overal_log'">
          <span
            class="material-icons"
            @mouseenter="hint_label_hover($event, { msg: 'money_pool_history' })"
            @mouseleave="resetHint"
          >
            format_list_numbered
          </span>
        </div>
        <div :class="{ tab: true, active: active_tab === 'send_log' }" @click="active_tab = 'send_log'">
          <span
            class="material-icons"
            @mouseenter="hint_label_hover($event, { msg: 'money_send_log' })"
            @mouseleave="resetHint"
          >
            forward_to_inbox
          </span>
        </div>
        <div :class="{ tab: true, active: active_tab === 'donate_log' }" @click="active_tab = 'donate_log'">
          <span
            class="material-icons"
            @mouseenter="hint_label_hover($event, { msg: 'money_donate_log' })"
            @mouseleave="resetHint"
          >
            bloodtype
          </span>
        </div>
        <div :class="{ tab: true, active: active_tab === 'spend_log' }" @click="active_tab = 'spend_log'">
          <span
            class="material-icons"
            @mouseenter="hint_label_hover($event, { msg: 'money_spend_log' })"
            @mouseleave="resetHint"
          >
            shopping_cart
          </span>
        </div>
      </div>
      <div class="charts_wr">
        <div class="tables">
          <div v-for="log in log_stats" v-show="active_tab === log" :key="log" class="wr">
            <table v-if="getTableData(log)?.length">
              <tr>
                <th class="small_cell">#</th>
                <th class="small_cell">{{ $t('message.game_type') }}</th>
                <th>{{ $t('message.date') }}</th>
                <th>{{ $t('message.money_type') }}</th>
                <th>{{ $t('message.player') }}</th>
                <th v-if="log === 'donated_history'">{{ $t(`message.dollars_count`) }}</th>
                <th class="small_cell">{{ $t('message.tokens_count') }}</th>
                <th class="small_cell">{{ $t('message.money_total') }}</th>
              </tr>
              <tr v-for="(log_item, idx) in getTableData(log)" :key="log_item">
                <td>{{ idx + 1 }}</td>
                <td v-if="log_item.type === 'send_log'">
                  <span
                    v-if="log_item.type === 'send_log' && log_item.title === 'comission'"
                    class="material-icons red_arrow"
                  >
                    south
                  </span>
                  <span v-else class="material-icons"> - </span>
                </td>
                <td v-else>
                  <span v-if="!log_item.transfer_direction" class="material-icons red_arrow"> south </span>

                  <span v-else class="material-icons green_arrow"> north </span>
                </td>
                <td>{{ getDate(log_item.time) }}</td>
                <td>{{ $t(`message.${log_item.title}`) }}</td>
                <td>{{ getUserName(log_item) }}</td>
                <td v-if="log === 'donated_history'">{{ +log_item.dollars_count }}</td>
                <td class="small_cell yellow">{{ log_item.diff || log_item.tokens_count }}</td>

                <td class="small_cell green">{{ log_item.app_total_money || '0' }}</td>
              </tr>
            </table>
            <div v-else class="empty">---</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters, mapMutations, mapState } from 'vuex'

  export default {
    data() {
      return {
        hint_delay: null,
        clear_delay: null,
        active_tab: 'overal_log',
        log_stats: ['overal_log', 'send_log', 'donate_log', 'spend_log'],
      }
    },

    computed: {
      ...mapState('general_store', ['data_for_modal', 'users']),
      ...mapGetters('general_store', ['mainRoom', 'user']),
    },

    methods: {
      ...mapMutations('gui_store', ['setHintLabel']),
      ...mapMutations('general_store', ['processModal']),

      getUserName(log_item) {
        const user = this.users.find((u) => u.id === log_item.target)
        if (!user) return log_item.target_name

        return user?.name
      },

      close(e) {
        if (e.target.id !== 'modal_base') return
        this.processModal({ name: 'pool_log', type: 'close' })
      },

      getDate(ms) {
        const date = new Date(ms)
        const options = {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        }
        const formattedDate = date.toLocaleString(undefined, options).replace(',', '')
        const [datePart, timePart] = formattedDate.split(' ')
        const [month, day, year] = datePart.split('/')
        const reorderedDate = `${day}.${month}.${year}, ${timePart}`
        return reorderedDate
      },
      resetHint() {
        clearTimeout(this.hint_timer_delay)
        clearTimeout(this.clear_hint_delay)

        this.clear_hint_delay = null
        this.hint_timer_delay = null
        this.setHintLabel({ reset: true })
      },
      hint_label_hover(e, item) {
        if (e.pointerType !== 'mouse') return
        if (!item) return
        this.resetHint()

        this.hint_timer_delay = setTimeout(() => {
          const coords = e.target.getBoundingClientRect()
          const logYpos = `translate(${coords.left}px, ${coords.top + 30}px)`
          this.setHintLabel({
            msg: item.msg,
            coords: logYpos,
          })

          this.clear_hint_delay = setTimeout(() => {
            this.resetHint()
          }, 2000)
        }, this.hint_delay)
      },

      getTableData(key) {
        if (key === 'overal_log') {
          const logs = ['send_log', 'donate_log', 'spend_log']
          const res = logs.reduce((main_acc, log_key) => {
            main_acc = this.users.reduce((acc, user) => {
              if (user.money_logs?.[log_key]) {
                acc = acc.concat(user.money_logs?.[log_key])
              }

              return acc
            }, main_acc)

            return main_acc
          }, [])

          res.sort((a, b) => b.time - a.time)
          return res
        }
        const res = this.users.reduce((acc, user) => {
          if (user.money_logs?.[key]) {
            acc = acc.concat(user.money_logs?.[key])
          }

          return acc
        }, [])
        res.sort((a, b) => b.time - a.time)
        return res
      },
    },
  }
</script>
<style lang="scss" scoped>
  .modal_base {
    position: relative;
    z-index: 22;
    background-color: rgba(0, 0, 0, 0.49);
    width: 100dvw;
    height: 100dvh;
    pointer-events: all;
  }
  .pool_log_container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: absolute;
    left: 50%;
    top: 50%;
    padding: 2rem;
    transform: translate(-50%, -50%);
    width: auto;
    height: auto;
    max-width: $modals_max_width;
    max-height: $modals_max_height;

    background-color: $modals_color;
    border-radius: 0.4rem; // border: 4px solid rgb(174, 173, 173);
    -webkit-box-shadow: -1px -4px 97px 31px rgba(111, 138, 179, 1);
    -moz-box-shadow: -1px -4px 97px 31px rgba(111, 138, 179, 1);
    box-shadow: -1px -4px 97px 31px rgba(111, 138, 179, 1);
  }

  @media (max-height: 400px) {
    .pool_log_container {
      padding-top: 0.7rem;
      padding-bottom: 0.7rem;
    }
  }
  @media (max-width: 600px) {
    .pool_log_container {
      padding-left: 0.3rem;
      padding-right: 0.3rem;
    }
  }
  @media (max-width: 900px) {
    .pool_log_container {
      width: 85dvw;
      height: 90dvh;
    }
  }

  .empty {
    color: white;
    font-size: 2rem;
    text-align: center;
    // min-width: 80dvw;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // flex: 1;
  }
  .charts_wr {
    height: 600px;
    width: 100%;
    overflow: auto;
  }
  .pool-chart-wr {
    min-width: 560px;
  }
  .tables {
    height: 100%;
    width: 100%;
    position: relative;
  }

  .wr {
    height: 100%;
    box-sizing: border-box;
    position: relative;
  }

  table {
    position: relative;
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    box-sizing: border-box;
    overflow: auto;
  }

  th {
    color: rgb(199, 193, 237);
    padding: 0.5rem;
    position: sticky;
    z-index: 2;
    background: rgb(22, 69, 108);
    top: 0;
    border-top: 2px solid;
    border-bottom: 2px solid;
    border-right: 2px solid;
    text-align: center;
  }

  td {
    box-sizing: border-box;
    position: relative;
    color: white;
    text-align: center;
    vertical-align: center;
    padding: 7px;
    border-bottom: 2px solid rgba(255, 255, 255, 0.625);
    border-right: 2px solid rgba(255, 255, 255, 0.625);
    font-size: 0.8rem;
  }

  .nav_bar {
    display: flex;
    justify-content: center;
    gap: 1rem;
    color: white;
  }

  .tab {
    cursor: pointer;
    position: relative;
    padding: 5px 10px;
    border-radius: 1rem;
    background-color: grey;
    transition: top 0.35s ease-in, background-color 0.35s ease-in;
    top: 0;
  }

  .small_cell {
    width: 2rem;
  }
  .red_arrow {
    color: red;
  }
  .green_arrow {
    color: green;
  }
  .yellow {
    color: yellow;
  }
  .green {
    color: rgb(73, 190, 73);
  }

  table th:first-child,
  table td:first-child {
    border-left: 2px solid rgba(255, 255, 255, 0.625);
  }

  .active {
    background-color: rgb(71, 52, 168);
    top: 5px;
  }

  $delay: 0.35s;

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
    transform: translateY(20px);
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
  }
  .fade-enter-active {
    transition: all 0.35s ease;
    transition-delay: $delay;
    // transition-delay: $delay;
  }
  .fade-leave-active {
    transition: all 0.35s ease;
    // transition-delay: $delay;
  }
  .mt-enter-from,
  .mt-leave-to {
    opacity: 0;
  }
  .mt-enter-active {
    transition: all 0.35s ease;
    transition-delay: $delay;
  }
  .mt-leave-active {
    transition: all 0.35s ease;
  }
</style>
