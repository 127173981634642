<template>
  <div class="inner_body_wr">
    <div class="container">
      <h1>Terms & Conditions</h1>

      <p>
        By registering on this website, you agree to be legally bound by the following terms. If you do not agree to be
        legally bound by all of the following terms, please do not access and/or use
        <strong>agot-board-game.com</strong>. We may change these terms from time to time, but we will do our best to
        inform you. However, it would be prudent to review this regularly, as your continued use of
        <strong>agot-board-game.com</strong> after changes means you agree to be legally bound by the updated and/or
        amended terms.
      </p>

      <p>
        You agree not to post any abusive, obscene, vulgar, slanderous, hateful, threatening, sexually-oriented, or any
        other material that may violate the laws of your country, the country where this site is hosted, or
        International Law. Doing so may result in an immediate and permanent ban. Your IP address is recorded as
        described in our <a href="#privacy_policy">Privacy Policy</a> to aid in enforcing these conditions. You agree
        that we have the right to remove, edit, or move any post at any time should we see fit.
      </p>

      <p>
        As a user, you agree to any information you have entered being stored in a database. While this information will
        not be disclosed to any third party without your consent, neither we nor the website's host shall be held
        responsible for any hacking attempt that may lead to data being compromised.
      </p>

      <p>
        Please also read our <a href="#terms_of_use">Terms of Use </a>to understand what
        <strong>agot-board-game.com</strong> expects from its users.
      </p>

      <h2>Contact Information</h2>
      <p>If you have any questions or concerns regarding the terms, feel free to contact us at:</p>
      <p>Email: <strong>karasinho555@gmail.com</strong></p>

      <h1 id="terms_of_use">Terms Of Use & Conditions</h1>

      <div class="scroll_section">
        <p><strong>Last revision:</strong> Sep 20, 2024</p>

        <h2>General Statement</h2>
        <p>
          Please read this page carefully. It contains the Terms and Conditions concerning the access and use of
          <strong>agot-board-game.com</strong> website and its services:
        </p>

        <ul>
          <li>
            <strong>1. Acceptance</strong><br />
            Your access and use of the <strong>agot-board-game.com</strong> website indicates your acceptance of these
            Terms and Conditions. If you do not accept these Terms and Conditions you must not use the
            <strong>agot-board-game.com</strong> website.
          </li>

          <li>
            <strong>2. Updating the Terms & Conditions</strong><br />
            <strong>agot-board-game.com</strong> may from time to time review and change these Terms and Conditions with
            updates on this page. The date of the most recent updates to these Terms and Conditions will be posted at
            the top of this page. Your continued access and use of the <strong>agot-board-game.com</strong> website
            after the date of the most recent update indicates your acceptance of the updated Terms and Conditions. If
            you do not accept the updated Terms and Conditions you must not continue to use the
            <strong>agot-board-game.com</strong> website.
          </li>

          <li>
            <strong>3. Gaming conduct of registered members</strong><br />
            Registered members of <strong>agot-board-game.com</strong> website must abide by the relevant Game Rules and
            have a responsibility to behave reasonably and fairly towards other registered members in any game currently
            being played. The relevant Game Rules are listed here
            <router-link :to="`/info_page`"> {{ $t('message.info') }}</router-link
            >. Registered members must not directly or indirectly breach the responsibility to behave reasonably and
            fairly, including but not limited to: insulting other members, quitting or spoiling games, 'kingmaking,' or
            using multiple accounts. Members should to report breaches of these rules to the administration.
          </li>

          <li>
            <strong>5. Termination or suspension of use</strong><br />
            The administration of <strong>agot-board-game.com</strong> reserves the right to terminate or suspend any
            registered member's access and use of the website at their discretion, without obligation to provide a
            warning or explanation.
          </li>

          <li>
            <strong>6. Questions</strong><br />
            Any questions regarding the access and use of <strong>agot-board-game.com</strong> website and its services
            must be sent to: <strong>karasinho555@gmail.com</strong>.
          </li>

          <li>
            <strong>7. Disclaimer</strong><br />
            The administration of <strong>agot-board-game.com</strong> cannot guarantee the continued functionality of
            the website, and is not liable for any damage or loss arising from the discontinued functionality.
          </li>
        </ul>

        <p>&copy; Copyright 2024</p>
        <p>
          The intellectual property of these pages, as well as any information and material that appears here, mainly
          belongs to Fantasy Flight Publishing, Inc. The source code and concept of this community website is the
          intellectual property of <strong>agot-board-game.com</strong>. Users commit not to reproduce, modify,
          distribute, or sell the content of this website, including its code, graphics, buttons, and logos, without
          express permission.
        </p>
      </div>
    </div>
    <h1 id="privacy_policy">Account Information & Privacy Policy</h1>

    <p>
      Your account will, at a bare minimum, contain a uniquely identifiable name, a personal password used for logging
      into your account, and a personal, valid email address. Your account information is protected by data-protection
      laws applicable in the country that hosts us and by the General Data Protection Regulation of the EU (GDPR).
    </p>
    <h1>Privacy Policy of <strong>agot-board-game.com</strong></h1>

    <div class="scroll_section">
      <p>Last revised: Sep 20, 2024</p>
      <h2>Privacy Policy Statement</h2>
      <p>
        <strong>agot-board-game.com</strong> is committed to protecting your privacy. We adhere to the General Data
        Protection Regulation (GDPR) by the EU and aim to comply fully. This Privacy Policy applies to
        <strong>agot-board-game.com</strong> and its subdomains. It governs the collection, usage, and handling of your
        data on our websites and services. It does not apply to any other online or offline sites, products, or
        services. Please read this Privacy Policy carefully to understand how we collect and use your data.
      </p>

      <h2>Personal Data</h2>
      <p>
        Personal data refers to any data that can be used to directly or indirectly identify an individual. Examples
        include your name, IP address, email, or other identifiers. We collect minimal personal data to provide our
        services and comply with GDPR. Personal data collection and use are clearly outlined in this policy.
      </p>

      <h2>Data Subject</h2>
      <p>
        A data subject is any person whose personal data is collected, held, or processed. This includes users of our
        website. By using <strong>agot-board-game.com</strong>, you may provide personal data that we process in
        accordance with GDPR.
      </p>

      <h2>User Account Registration</h2>
      <p>
        <strong>agot-board-game.com</strong> does not collect any information from visitors. Only registered users have
        access to our full services. For registered users, we collect minimal information such as a username, email
        address, and basic session data (e.g., IP address, browser type). Users may delete their accounts and all
        associated data at any time.
      </p>

      <h2>Collection of Personal Data</h2>
      <p>
        As a non-commercial site, <strong>agot-board-game.com</strong> only collects essential personal data, such as
        your email address, username (which can be a nickname), and anonymized IP addresses. Further profile information
        is optional and not required for using our services.
      </p>

      <h2>Usage of Personal Data</h2>
      <p>
        Your email is primarily used for account identification, restoration, and password resets. Notifications for
        forums, messages, or issues in our system may also be sent to your email.
      </p>

      <h2>Security & Protection of Personal Data</h2>
      <p>
        We use secure web protocols (HTTPS) to protect your data. All session data and sensitive information are stored
        in an encrypted format to ensure the security of your personal data. Sessions and cookies are automatically
        deleted after 30 days for safety.
      </p>

      <h2>Control over Personal Data</h2>
      <p>
        You have full control over the collection, usage, and sharing of your personal data. You may contact us at
        <strong>karasinho555@gmail.com</strong> for any concerns or data requests.
      </p>

      <h2>Right of Access by the Data Subject</h2>
      <p>
        You may request access to your personal data at any time via your account settings. The information will be sent
        to your registered email address. We ensure data requests are processed within the GDPR-compliant timeframe of
        four weeks.
      </p>

      <h2>Right to Object & Right to Erasure</h2>
      <p>
        Under GDPR, you have the right to object to the processing of your data. You may unregister or request data
        deletion at any time. If you request immediate data deletion, contact us using our contact form or email. The
        account ownership verification process must be completed before processing the request.
      </p>

      <h2>Sharing Data with Third Parties</h2>
      <p>
        <strong>agot-board-game.com</strong> does NOT share your personal data with third parties and does NOT sell your
        data. Your privacy is our priority.
      </p>

      <h2>User Tracking</h2>
      <p>
        We only track users locally via IP and cookies to ensure session security and proper use of our services.
        Tracking is limited to enforcing our Terms of Use and protecting users from unauthorized account access.
      </p>

      <h2>Analyzing Tools</h2>
      <p>
        <strong>agot-board-game.com</strong> does not use third-party analytics software like Google Analytics. We value
        your privacy and believe there is no justification for tracking our users without their explicit consent.
      </p>

      <h2>External Links</h2>
      <p>
        Our site may contain links to external websites. We are not responsible for the content or privacy practices of
        these sites. We encourage you to review the privacy policies of any external site you visit.
      </p>

      <h2>PayPal Donations</h2>
      <p>
        We offer PayPal as a method for users to support our project. If you choose to donate via PayPal, please review
        PayPal's Privacy Policy. You are not obligated to use PayPal, and we also accept Bitcoin and ETH donations for
        those who prefer more privacy.
      </p>

      <h2>Children's Privacy</h2>
      <p>
        <strong>agot-board-game.com</strong> is intended for users 16 years and older, as required by GDPR. Children
        under 16 must have permission from their legal guardian to use our platform and submit personal data.
      </p>

      <h2>Contact Information</h2>
      <p>
        If you have questions about this Privacy Policy, feel free to reach out to us at
        <strong>karasinho555@gmail.com</strong>. We will be happy to address your concerns.
      </p>
    </div>
    <h2>Changes to this Policy</h2>
    <p>
      <strong>agot-board-game.com</strong> reserves the right to update this Privacy Policy based on changes in laws or
      user feedback. Please review this Policy periodically for updates.
    </p>
  </div>
</template>
<script>
  export default {}
</script>
<style lang="scss" scoped>
  .scroll_section {
    box-sizing: border-box;
    padding: 1rem;
    height: 75dvh;
    overflow: auto;
    background-color: grey;
    -webkit-box-shadow: inset 0px 0px 31px -5px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: inset 0px 0px 31px -5px rgba(0, 0, 0, 0.75);
    box-shadow: inset 0px 0px 31px -5px rgba(0, 0, 0, 0.75);
    border-radius: 1rem;
  }

  .scroll_section::-webkit-scrollbar {
    width: 1rem;
  }

  .scroll_section::-webkit-scrollbar-track {
    background: grey;
    border-left: 0.45rem solid $theme_background;
    border-right: 0.45rem solid $theme_background; /* color of the tracking area */
  }

  .scroll_section::-webkit-scrollbar-thumb {
    background: grey; /* color of the scroll thumb */
    border-radius: 0.6rem; /* roundness of the scroll thumb */

    border-left: 0.3rem solid $theme_background;
    border-right: 0.3rem solid $theme_background;
  }
</style>
