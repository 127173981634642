<template>
  <div id="card_modal_wr" ref="hint" class="hint_wraper" @click="closeModal">
    <div class="card" :class="{ [`${card.home}_shadow`]: true }">
      <div class="img_wr">
        <img :src="`/images/cards/${card.home}/${locale}/${card.title}.png`" :alt="card.title" />
        <span v-if="isAdmin" class="material-icons swap_card" @click="swapCardHandler(e, card)">
          swap_vertical_circle
        </span>
      </div>
      <div v-if="showChoiceBtn(card)" class="btn" @click="cardClickHandler($event, card)">
        {{ $t(`message.select_card`) }}
      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters, mapMutations, mapState } from 'vuex'
  import { getHandler } from '../../utils/getPhaseHandler.js'
  export default {
    inject: ['socket'],

    computed: {
      ...mapGetters('general_store', [
        'mainRoom',
        'home',
        'game',
        'phase_name',
        'isAdmin',
        'user',
        'active_players',
        'myRole',
        'enemyRole',
        'wildingsState',
        'lastWildingsCard',
      ]),
      ...mapState('general_store', ['data_for_modal']),

      locale() {
        return this.user.locale
      },
      card() {
        return this.data_for_modal?.card
      },
    },

    mounted() {
      const options = {
        phase_name: this.phase_name,
        role: this.myRole,
        wildings_card: this.lastWildingsCard,
        wildingsState: this.wildingsState,
      }
      this.handler = getHandler(options)
    },

    methods: {
      ...mapMutations('general_store', ['processModal']),
      ...mapMutations('gui_store', ['setFreeze']),

      showChoiceBtn(card) {
        if (!this.active_players.includes(this.home)) return false
        if (this.phase_name === 'LastCardAction') {
          return card.home === this.enemyRole.home
        }
        if (this.phase_name === 'SelectCard') {
          return card.home === this.home
        }
        if (this.phase_name === 'WildingManual') {
          return card.home === this.home && card.active
        }
        return false
      },

      closeModal(e) {
        if (e.target.id !== 'card_modal_wr') return
        this.processModal()
      },
      swapCardHandler(e, card) {
        if (!this.isAdmin) return
        if (this.freeze) return
        this.socket.emit('client_action', {
          action_type: 'RedactorApply',
          data: {
            phase_name: 'Redactor',
            data: {
              room_id: this.game.id,
              card: { title: card.title, home: card.home },
            },
          },
        })
        this.setFreeze({ freeze: true, color: 'pink' })
      },

      cardClickHandler(e, card) {
        this.handler?.cardClickHandler?.(e, card)
      },
    },
  }
</script>
<style lang="scss" scoped>
  .hint_wraper {
    position: fixed;
    z-index: 200;
    width: 100dvw;
    height: 100dvh;
    background-color: rgba(0, 0, 0, 0.653);
    pointer-events: all;
    .card {
      position: absolute;
      z-index: 201;
      top: 50%;
      left: 50%;
      display: flex;
      flex-direction: column;
      transform: translate(-50%, -50%);
      // width: 200px;
      height: auto; /* Adjust automatically based on image */
      width: auto;

      border-radius: 0.4rem;
      -webkit-box-shadow: -1px -4px 97px 31px rgb(95, 211, 134);
      -moz-box-shadow: -1px -4px 97px 31px rgb(95, 211, 134);
      box-shadow: -1px -4px 97px 31px rgb(95, 211, 134);

      .img_wr {
        position: inline-block;
        height: auto; /* Adjust automatically based on image */
        width: auto;

        // pointer-events: none;
        // width: 200px;
        img {
          max-height: 80vh; /* Limit to 80% of viewport height */
          max-width: 100dvw; /* Limit to full width of viewport */
          height: auto; /* Keep aspect ratio */
          width: auto;
        }
      }
    }

    .stark_shadow {
      -webkit-box-shadow: -1px -4px 97px 31px rgb(127, 132, 129);
      -moz-box-shadow: -1px -4px 97px 31px rgb(127, 132, 129);
      box-shadow: -1px -4px 97px 31px rgb(127, 132, 129);
    }
    .lannister_shadow {
      -webkit-box-shadow: -1px -4px 97px 31px rgb(193, 8, 8);
      -moz-box-shadow: -1px -4px 97px 31px rgb(193, 8, 8);
      box-shadow: -1px -4px 97px 31px rgb(193, 8, 8);
    }
    .baratheon_shadow {
      -webkit-box-shadow: -1px -4px 97px 31px rgb(231, 220, 26);
      -moz-box-shadow: -1px -4px 97px 31px rgb(231, 220, 26);
      box-shadow: -1px -4px 97px 31px rgb(231, 220, 26);
    }
    .greyjoy_shadow {
      -webkit-box-shadow: -1px -4px 97px 31px rgb(37, 37, 35);
      -moz-box-shadow: -1px -4px 97px 31px rgb(37, 37, 35);
      box-shadow: -1px -4px 97px 31px rgb(37, 37, 35);
    }
    .tyrell_shadow {
      -webkit-box-shadow: -1px -4px 97px 31px rgb(6, 90, 8);
      -moz-box-shadow: -1px -4px 97px 31px rgb(6, 90, 8);
      box-shadow: -1px -4px 97px 31px rgb(6, 90, 8);
    }
    .martell_shadow {
      -webkit-box-shadow: -1px -4px 97px 31px rgb(165, 90, 29);
      -moz-box-shadow: -1px -4px 97px 31px rgb(165, 90, 29);
      box-shadow: -1px -4px 97px 31px rgb(165, 90, 29);
    }

    .btn {
      width: 100%;
      box-sizing: border-box;
      padding: 1rem;
      background-color: rgb(62, 13, 69);
      border-radius: 0.3rem;
      color: white;
      text-align: center;
      cursor: pointer;
      &:hover {
        background-color: rgb(116, 24, 137);
      }
    }

    .swap_card {
      position: absolute;
      z-index: 202;
      left: 0;
      top: 40px;
      z-index: 11;
      color: white;
      &:hover {
        color: red;
        cursor: pointer;
      }
    }
  }
</style>
