<template>
  <div class="container">
    <div class="tabs_wr">
      <div
        v-for="num in tabs"
        :key="num"
        class="tab_wr"
        :class="{ activeTab: active == num }"
        @click="active = num"
      ></div>
    </div>
    <div class="body_wr">
      <div class="btns_wr">
        <div class="btn_wr" @click="pageBack"></div>
        <div class="btn_wr" @click="pageForward"></div>
      </div>

      <div v-if="active === 1" key="page1" class="page_container">FIRST PAGE</div>

      <div v-if="active === 2" key="page2" class="page_container">SECOND PAGE</div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        tabs: 5,
        active: 1,
      }
    },
    methods: {
      pageBack() {
        this.active--
        if (this.active < 1) {
          this.active = 1
        }
      },
      pageForward() {
        this.active++
        if (this.active > this.tabs) {
          this.active = this.tabs
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    // background-color: rgba(71, 128, 179, 0.685);
    gap: 0.8rem;
  }

  .tabs_wr {
    width: 100%;
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    gap: 2rem;
    // background-color: rgb(36, 37, 36);

    .tab_wr {
      flex: 1;
      font-size: 1rem;
      height: 0.8rem;
      color: white;
      border-bottom: 1px solid white;
      border-radius: 0.4rem;
      overflow: auto;
      cursor: pointer;
      //   background-color: red;
      display: flex;
      justify-content: center;
    }
    .activeTab {
      background-color: rgb(71, 71, 70);
    }
  }

  .body_wr {
    flex: 1;
    position: relative;
    // background-color: rgba(255, 255, 0, 0.623);
    overflow: auto;

    &::-webkit-scrollbar {
      width: 1rem;
    }

    &::-webkit-scrollbar-track {
      background: grey;
      border-left: 0.45rem solid $theme_background;
      border-right: 0.45rem solid $theme_background; /* color of the tracking area */
    }

    &::-webkit-scrollbar-thumb {
      background: grey; /* color of the scroll thumb */
      border-radius: 0.6rem; /* roundness of the scroll thumb */

      border-left: 0.3rem solid $theme_background;
      border-right: 0.3rem solid $theme_background;
    }
  }
  .btns_wr {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    gap: 0.2rem;
    .btn_wr {
      width: 100%;
      height: 100%;

      background-color: rgba(139, 81, 81, 0.603);
    }
    // background-color: palegoldenrod;
  }
</style>
