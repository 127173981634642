<template>
  <div class="exec-wrap no_select" data-type="exec">
    <div class="exec" data-type="exec" @click="$emit('exec')">
      <span data-type="exec" class="material-icons"> ads_click </span>
      <div class="timer" data-type="exec">{{ user_timer }}</div>
    </div>
  </div>
</template>

<script>
  import { searching_timer } from '#static_data'
  import { mapState, mapGetters } from 'vuex'
  export default {
    emits: ['exec'],

    computed: {
      ...mapState('general_store', ['timers']),
      ...mapState('gui_store', ['freeze']),

      ...mapGetters('general_store', ['game', 'phase_name', 'active_players', 'isHistoryModeOn', 'home', 'isAdmin']),

      searching() {
        return this.game?.state.status === 'searching'
      },

      user_timer() {
        if (this.home === 'watcher') return

        return this.formatTimer(this.timers[this.home])
      },

      searching_timer() {
        return this.formatTimer(this.timers[this.game.id] || searching_timer)
      },

      isMyTurn() {
        if (this.phase_name === 'GameOver') return false
        if (this.active_players?.includes(this.home) && !this.isHistoryModeOn && !this.freeze.freeze) {
          return true
        }
        return false
      },
    },

    methods: {
      formatTimer(duration) {
        const hrs = ~~(duration / 3600)
        const mins = ~~((duration % 3600) / 60)
        const secs = ~~duration % 60

        // Output like "1:01" or "4:03:59" or "123:03:59"
        let ret = ''

        if (hrs > 0) {
          ret += '' + hrs + ':' + (mins < 10 ? '0' : '')
        }

        ret += '' + mins + ':' + (secs < 10 ? '0' : '')
        ret += '' + secs

        return ret
      },
    },
  }
</script>

<style lang="scss" scoped>
  .no_select {
    -webkit-user-select: none; /* Для iOS и Safari */
    -moz-user-select: none; /* Для Firefox */
    -ms-user-select: none; /* Для старых версий Edge */
    user-select: none; /* Для предотвращения выделения */
  }
  .exec-wrap {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: auto;
    height: auto;
    overflow: hidden;
    position: absolute;
    z-index: 14;
    bottom: env(safe-area-inset-bottom);
    left: env(safe-area-inset-left);
    transform: translateX(50%);
    height: auto;
    pointer-events: all;
  }

  .exec {
    box-sizing: border-box;
    display: flex;
    gap: 0.5rem;
    border: 2px solid rgb(171, 84, 84);
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 0.2rem;
    background-color: rgba(50, 47, 47, 0.897);
    color: white;
    font-weight: 600;
    border-radius: $theme_border_radius;

    .timer {
      font-size: 1rem;
      color: red;
    }

    &:hover {
      cursor: pointer;
      background-color: rgb(50, 47, 47);
    }
  }
</style>
