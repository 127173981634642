<template>
  <div class="wrapper">
    <div class="back_btn" @click="goBack">&larr;</div>
    <div class="body_wr">
      <PoliciesAndTermsUK v-if="$i18n?.locale === 'uk'" />
      <PoliciesAndTermsEN v-else />
      <!-- <PoliciesAndTermsEN /> -->
      <!-- <PoliciesAndTermsUK /> -->
    </div>
  </div>
</template>
<script>
  export default {
    methods: {
      goBack() {
        this.$router.push('/')
      },
    },
  }
</script>
<style lang="scss" scoped>
  .wrapper {
    background-image: url('/images/map_en.jpg');
    background-size: cover; /* Adjusted for better background fitting */
    background-position: center; /* Optional: Center the background image */
    background-repeat: no-repeat;
    height: 100dvh;
    background-color: aquamarine;
    overflow: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    user-select: text;
  }

  .wrapper::-webkit-scrollbar {
    width: 1rem;
  }

  .wrapper::-webkit-scrollbar-track {
    background: grey;
    border-left: 0.45rem solid $theme_background;
    border-right: 0.45rem solid $theme_background; /* color of the tracking area */
  }

  .wrapper::-webkit-scrollbar-thumb {
    background: grey; /* color of the scroll thumb */
    border-radius: 0.6rem; /* roundness of the scroll thumb */

    border-left: 0.3rem solid $theme_background;
    border-right: 0.3rem solid $theme_background;
  }
  .back_btn {
    width: 5rem;
    background-color: rgb(165, 152, 39);
    position: fixed;
    left: 1rem;
    color: white;
    text-align: center;
    font-size: 2rem;
    border-radius: 0.6rem;
    -webkit-box-shadow: 0px 0px 34px 7px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 34px 7px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 34px 7px rgba(0, 0, 0, 0.75);
    cursor: pointer;
    &:hover {
      background-color: rgb(172, 97, 27);
    }
  }
  .body_wr {
    height: auto;
    background-color: rgb(75, 77, 76);
    width: 70%;
    color: rgb(0, 0, 0);
    background-image: url('/images/paper.jpg');
    background-size: cover; /* Adjusted for better background fitting */
    background-position: center;
    background-size: 100% 100%;
    -webkit-box-shadow: 0px 0px 34px 11px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 34px 11px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 34px 11px rgba(0, 0, 0, 0.75);
    padding: 1rem 4rem;
    box-sizing: border-box;
    border-radius: 3rem;
  }
</style>
