<template>
  <div id="camera" class="zoom_outer prevent-select">
    <div class="map" id="zoom" ref="zoom" :style="{ transform: transform_style }">
      <div class="rotate_wr">
        <div class="inner_rotate_wr" ref="rotate" :style="{ transform: `rotate(${rotate}deg)` }">
          <div class="map_inner" id="inner_zoom" ref="inner_zoom">
            <img :src="`/images/map_${locale}.jpg`" alt="map" class="map_img" />
            <LandsLayer />
            <GarrisonLayer />
            <ArrowLayer />
            <UnitsLayer ref="unitsLayer" :handler="handler" />
            <TracksLayer ref="tracksLayer" :handler="handler" />
            <OrdersLayer :handler="handler" />
            <TokensLayer />

            <AdminLayer v-if="isAdmin" />

            <HeepUnitsModal v-if="allowUnitsHeepOpen" :handler="handler" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapMutations, mapState } from 'vuex'

  export default {
    props: {
      handler: Object,
    },

    data() {
      return {
        scale: 0.28,
        initial_scale: 0.1,
        rotate: 0,
        zoom_percentage: 1,
        mapTranslateX: 0,
        mapTranslateY: 0,
        xs: 0,
        ys: 0,
        currentTranslate: {
          x: 0,
          y: 0,
        },
        transform_style: '',
        map: null,
        timeout: true,
      }
    },

    computed: {
      ...mapGetters('general_store', ['game', 'phase_name', 'isHistoryModeOn', 'user', 'isAdmin']),
      ...mapState('game_store', ['mastering_state']),
      ...mapState('gui_store', ['mapPosition']),

      allowUnitsHeepOpen() {
        if (this.isHistoryModeOn) return false
        return this.phase_name !== 'LastCardAction' && this.mastering_state.land_name && this.mastering_state.points
      },

      locale() {
        return this.user.locale
      },
    },

    watch: {
      async mapPosition(newV, oldV) {
        if (this.mapPosition.centerMap) {
          this.mapTranslateX = this.mapPosition.x
          this.mapTranslateY = this.mapPosition.y
          this.scale = this.mapPosition.scale
          this.setTransform()
          this.setMapPosition({
            centerMap: false,
          })
        }
        if (this.mapPosition.rotate === 0) {
          this.$refs.rotate.style.transition = 'none'
          this.rotate = this.mapPosition.rotate
        } else {
          this.$refs.rotate.style.transition = `all 0.5s`
          this.rotate = this.mapPosition.rotate
        }
      },
    },

    mounted() {
      this.map = this.$refs.zoom

      this.hammertime = new this.$hammer(this.map)

      const pan = new this.$hammer.Pan()
      const pinch = new this.$hammer.Pinch()

      pan.requireFailure(pinch)
      pinch.requireFailure(pan)

      this.hammertime.add([pan, pinch])

      this.hammertime.get('pinch').set({ enable: true })
      this.hammertime.get('pan').set({ direction: Hammer.DIRECTION_ALL })

      this.hammertime.on('pinch', this.pinchHandler)
      this.hammertime.on('pinchstart', () => {
        this.hammertime.get('pan').set({ enable: false })
      })
      this.hammertime.on('pinchend', () => {
        this.hammertime.get('pan').set({ enable: true })
      })
      this.hammertime.on('pinchcancel', () => {
        this.hammertime.get('pan').set({ enable: true })
      })
      this.hammertime.on('pan', this.panHandler)
      this.hammertime.on('panstart', (e) => {
        if (e.maxPointers > 1) {
          return
        }

        this.currentTranslate = {
          x: e.center.x - this.mapTranslateX,
          y: e.center.y - this.mapTranslateY,
        }
      })

      this.validateMapPosition()
      this.setTransform()
      this.centerMap()
      this.map.addEventListener('wheel', this.onWheel, { passive: true })
      this.map.addEventListener('pointerover', this.showStrokeLand.bind(this))
      this.map.addEventListener('pointerleave', this.hideStrokeLand.bind(this))

      this.game?.players.forEach((pl) => {
        const { home, timer } = pl
        this.setTimer({ home, time: timer })
      })
    },

    beforeUnmount() {
      window.removeEventListener('mouseup', this.mouseUp)
    },

    methods: {
      ...mapMutations('game_store', ['setCurrentArea']),
      ...mapMutations('general_store', ['setDataForModal', 'setTimer', 'selectPrivateChat']),
      ...mapMutations('gui_store', ['setHintLabel', 'setMainSideTab', 'setAvailableLands', 'setMapPosition']),

      centerMap() {
        const map = this.$refs.zoom
        const map_styles = window.getComputedStyle(map)
        const scale = this.initial_scale
        const map_width = parseFloat(map_styles.getPropertyValue('width'))
        const map_height = parseFloat(map_styles.getPropertyValue('height'))
        const window_width = window.innerWidth
        const window_height = window.innerHeight
        const map_width_after = map_width * scale
        const map_height_after = map_height * scale
        const height_delta = window_height + 40 - map_height_after

        this.setMapPosition({
          x: window_width / 2 - map_width_after / 2,
          y: height_delta / 2,
          scale,
          centerMap: true,
        })
      },

      panHandler(e) {
        if (e.maxPointers > 1) return
        if (e.srcEvent.target.dataset.type === 'unit') return
        if (e.srcEvent.target.dataset.type === 'token') return

        this.mapTranslateX = e.center.x - this.currentTranslate.x
        this.mapTranslateY = e.center.y - this.currentTranslate.y
        this.validateMapPosition()
        this.setTransform()
      },

      pinchHandler(e) {
        if (e.srcEvent.pointerType.includes('mouse')) return

        const center = e.center
        this.xs = (center.x - this.mapTranslateX) / this.scale
        this.ys = (center.y - this.mapTranslateY) / this.scale
        const scale = +e.scale
        this.zoom_percentage
        if (scale > 1) {
          this.zoom_percentage = (scale - 1) * 100
        } else {
          this.zoom_percentage = -(1 - scale) * 100
        }
        this.scale = this.initial_scale + this.initial_scale * (this.zoom_percentage / 100)

        if (e.eventType === 4) {
          this.initial_scale = this.scale
          if (this.scale < 0.1) {
            this.scale = this.initial_scale = 0.1
          }
        }
        if (this.zoom_percentage > 0 && this.scale < this.initial_scale) {
          this.scale = this.initial_scale
        }

        if (this.scale > 1.4) {
          this.scale = 1.4
        }

        this.mapTranslateX = center.x - this.xs * this.scale
        this.mapTranslateY = center.y - this.ys * this.scale
        this.validateMapPosition()
        this.setTransform()
      },

      validateMapPosition() {
        const boundingBox = this.map.getBoundingClientRect()
        const originalWidth = boundingBox.width
        const originalHeight = boundingBox.height
        if (!originalHeight) return

        const topEdge = -originalHeight + 100
        const bottomEdge = window.innerHeight - 100
        const leftEdge = -originalWidth + 100
        const rightEdge = window.innerWidth - 100

        if (this.mapTranslateY < topEdge) {
          this.mapTranslateY = topEdge
        }
        if (this.mapTranslateY > bottomEdge) {
          this.mapTranslateY = bottomEdge
        }
        if (this.mapTranslateX < leftEdge) {
          this.mapTranslateX = leftEdge
        }
        if (this.mapTranslateX > rightEdge) {
          this.mapTranslateX = rightEdge
        }
      },

      setTransform() {
        this.transform_style = `translate(${this.mapTranslateX}px, ${this.mapTranslateY}px) scale(${this.scale})`

        this.setMapPosition({ x: this.mapTranslateX, y: this.mapTranslateY, scale: this.scale })
      },

      showStrokeLand(e) {
        const curLand = e.target?.dataset?.source
        const _home = e.target?.dataset?.home || e.target?.dataset?.owner
        if (!curLand) return

        this.setCurrentArea({ land_name: curLand, owner: _home })
      },
      hideStrokeLand(e) {
        this.setCurrentArea({ land_name: '', owner: '' })
      },

      onWheel(event) {
        this.xs = (event.clientX - this.mapTranslateX) / this.scale
        this.ys = (event.clientY - this.mapTranslateY) / this.scale
        let delta = event.wheelDelta ? event.wheelDelta : -event.deltaY

        if (delta > 0) {
          this.scale *= 1.2
          if (this.scale > 1.4) {
            this.scale = 1.4
          }
        } else {
          this.scale /= 1.2
          if (this.scale < this.initial_scale) {
            this.scale = this.initial_scale
          }
        }

        this.mapTranslateX = event.clientX - this.xs * this.scale
        this.mapTranslateY = event.clientY - this.ys * this.scale
        this.setTransform()
      },
    },
  }
</script>
<style lang="scss" scoped>
  .zoom_outer {
    position: relative;
    overflow: hidden;
    width: 100dvw;
    height: 100dvh;
    background: rgb(215, 236, 240);
    background: radial-gradient(circle, rgba(215, 236, 240, 1) 0%, rgb(0, 0, 0) 80%);
  }

  .load_screen {
    background-color: rgb(193, 118, 118);
    // background-image: url("/load_screen.jpg");
    background-size: cover;
    position: fixed;
    z-index: 1000;
    width: 100dvw;
    height: 100dvh;
    top: 0;
    left: 0;
    img {
      height: auto;
      width: 100%;
    }
  }

  .map {
    position: absolute;

    width: 1978px;
    height: 2970px;
    transform-origin: 0px 0px;
    cursor: grab;
  }

  .rotate_wr {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .inner_rotate_wr {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-box-shadow: 0px 0px 150px 100px #000000;
    box-shadow: 0px 0px 80px 50px #000000;
  }

  .map_inner {
    // outline: 10px solid black;
    position: relative;
    width: 100%;
    height: 100%;
    cursor: grab;
  }

  .map_img {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .prevent-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }

  .shrink {
    margin-left: -30px;
  }
</style>
