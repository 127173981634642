<template>
  <div id="modal_base" class="modal_base" @click="close">
    <div id="wrap" class="wrap">
      <button @click="resetStat">Reset Statistics</button>
      <button @click="applyStat">Apply</button>
      <div v-for="(value, key) of simple_fields" :key="key" class="input_simple_wr">
        <label :for="key">{{ key }}</label>
        <input v-model="simple_fields[key]" type="number" :name="key" min="0" />
      </div>
      <div v-for="(value, key) of money" :key="key" class="input_simple_wr">
        <label :for="key">{{ key }}</label>
        <input v-model="money[key]" type="number" :name="key" min="0" />
      </div>
      <div v-for="(value, key) of home_fields" :key="key" class="input_home_wr">
        <div class="home_inner_item">
          <label :for="key">{{ `${key}.total` }}</label>
          <input v-model="home_fields[key].total" type="number" :name="`${key}.total`" min="0" />
        </div>
        <div class="home_inner_item">
          <label :for="key">{{ `${key}.wins` }}</label>
          <input v-model="home_fields[key].wins" type="number" :name="`${key}.wins`" min="0" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapMutations, mapState } from 'vuex'
  export default {
    inject: ['socket'],

    data() {
      return {
        simple_fields: {
          win_overal: null,
          total_hours_count: null,
          total_games_count: null,
          full_games_count: null,
          leaved_games_count: null,
          saved_games_count: null,
          charm: null,
        },
        money: {
          initial: null,
          donate: null,
          earned: null,
        },
        home_fields: {
          stark: {
            total: null,
            wins: null,
          },
          baratheon: {
            total: null,
            wins: null,
          },
          lannister: {
            total: null,
            wins: null,
          },
          greyjoy: {
            total: null,
            wins: null,
          },
          martell: {
            total: null,
            wins: null,
          },
          tyrell: {
            total: null,
            wins: null,
          },
        },
      }
    },

    computed: {
      ...mapState('general_store', ['data_for_modal']),
      processed_user() {
        return this.data_for_modal?.processed_user
      },
    },

    mounted() {
      if (!this.processed_user) return
      this.init_user(this.processed_user)
    },

    methods: {
      ...mapMutations('gui_store', ['setHintLabel']),
      ...mapMutations('general_store', ['processModal', 'setDataForModal']),
      init_user(user) {
        this.simple_fields.win_overal = user.stats.win_overal
        this.simple_fields.total_hours_count = user.stats.total_hours_count
        this.simple_fields.total_games_count = user.stats.total_games_count
        this.simple_fields.full_games_count = user.stats.full_games_count
        this.simple_fields.leaved_games_count = user.stats.leaved_games_count
        this.simple_fields.saved_games_count = user.stats.saved_games_count
        this.simple_fields.charm = user.stats.charm
        this.money.initial = user.money.initial
        this.money.donate = user.money.donate
        this.money.earned = user.money.earned

        this.home_fields.stark.total = user.stats.more.stark.types.classic_6.total
        this.home_fields.stark.wins = user.stats.more.stark.types.classic_6.wins[0]

        this.home_fields.lannister.total = user.stats.more.lannister.types.classic_6.total
        this.home_fields.lannister.wins = user.stats.more.lannister.types.classic_6.wins[0]

        this.home_fields.baratheon.total = user.stats.more.baratheon.types.classic_6.total
        this.home_fields.baratheon.wins = user.stats.more.baratheon.types.classic_6.wins[0]

        this.home_fields.martell.total = user.stats.more.martell.types.classic_6.total
        this.home_fields.martell.wins = user.stats.more.martell.types.classic_6.wins[0]

        this.home_fields.tyrell.total = user.stats.more.tyrell.types.classic_6.total
        this.home_fields.tyrell.wins = user.stats.more.tyrell.types.classic_6.wins[0]

        this.home_fields.greyjoy.total = user.stats.more.greyjoy.types.classic_6.total
        this.home_fields.greyjoy.wins = user.stats.more.greyjoy.types.classic_6.wins[0]
      },

      close(e) {
        if (e.target.id !== 'modal_base') return
        this.processModal()
        this.setDataForModal({ reset: true })
      },
      resetStat() {
        const data = {
          user_id: this.processed_user.id,
          room_id: 'mainRoom',
          reset_stat: true,
        }
        this.socket.emit('client_action', {
          action_type: 'ChangeUserByAdmin',
          data,
        })
      },
      applyStat() {
        const stats = { ...this.simple_fields, money: this.money, home_fields: this.home_fields }

        if (stats.full_games_count > stats.total_games_count) {
          stats.total_games_count = stats.full_games_count
        }

        if (stats.win_overal > stats.total_games_count) {
          stats.total_games_count = stats.win_overal
          if (stats.full_games_count > stats.total_games_count) {
            stats.total_games_count = stats.full_games_count
          }
        }

        const data = {
          user_id: this.processed_user.id,
          room_id: 'mainRoom',
          reset_stat: false,
          stats,
        }
        this.socket.emit('client_action', {
          action_type: 'ChangeUserByAdmin',
          data,
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .modal_base {
    position: absolute;
    pointer-events: all;
    width: 100dvw;
    height: 100dvh;
    background-color: $modals_background_color;
    z-index: 35;
    top: 0;
    left: 0;
  }
  form {
    display: flex;
    flex-direction: column;
  }

  .wrap {
    position: absolute;
    z-index: 35;
    top: 50%;
    left: 50%;
    box-sizing: border-box;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    width: auto;
    height: auto;
    max-height: $modals_max_height;
    max-width: $modals_max_width;
    padding: 1rem;
    gap: 0.3rem;
    align-items: center;
    justify-content: flex-start;
    background-color: $modals_color;
    border-radius: 0.4rem;
    color: white;
    font-weight: 400;
    overflow: auto;
    -webkit-box-shadow: -1px -4px 97px 31px rgba(171, 97, 159, 1);
    -moz-box-shadow: -1px -4px 97px 31px rgba(171, 97, 159, 1);
    box-shadow: -1px -4px 97px 31px rgba(171, 97, 159, 1);
  }
  .user-edit-popup {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 80%;
    height: auto;
    border: 1px solid black;
    padding: 15px;
    gap: 0.3rem;
    align-items: center;
    justify-content: center;
    background-color: rgb(70, 34, 154);
    color: white;
    font-weight: 400;
  }

  .input_simple_wr {
    display: flex;
    width: 70%;
    justify-content: space-between;

    input {
      width: 50px;
    }
  }
  .input_home_wr {
    border-bottom: 1px solid rgb(0, 0, 0);
    display: flex;
    width: 100%;
    gap: 2rem;
    justify-content: space-between;

    input {
      width: 30px;
    }
  }

  .home_inner_item {
    display: flex;
    justify-content: space-between;
    flex: 1;
  }
</style>
