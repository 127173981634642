import axios from 'axios'
import Hammer from 'hammerjs'
import VueAxios from 'vue-axios'
import { createI18n } from 'vue-i18n'
import { createRouter, createWebHistory } from 'vue-router'
import { createApp } from 'vue/dist/vue.esm-bundler.js'
import App from './App.vue'
import messages from './locales/messages.js'
import store from './store/index.js'

import AdminInitView from '@/views/AdminInitView.vue'
import LobbyView from '@/views/LobbyView.vue'
import LoginView from '@/views/LoginView.vue'
import PrivacyPolicyView from '@/views/PrivacyPolicyView.vue'

import ExecBtn from './components/ExecBtn.vue'
import SearchingTimer from './components/SearchingTimer.vue'
import LayerGameField from './components/LayerGameField.vue'
import LayerModalWrapper from './components/LayerModalWrapper.vue'
import ListGames from './components/ListGames.vue'
import TabPlayersList from './components/TabPlayersList.vue'
import ListUsers from './components/ListUsers.vue'
import LoggerComponent from './components/LoggerComponent.vue'
import MessageCard from './components/MessageCard.vue'
import NavButtonsLeft from './components/NavButtonsLeft.vue'
import NavButtonsRight from './components/NavButtonsRight.vue'
import NavButtonsTop from './components/NavButtonsTop.vue'
import SideBarLeft from './components/SideBarLeft.vue'
import SideBarRight from './components/SideBarRight.vue'
import TabCards from './components/TabCards.vue'
import TabChat from './components/TabChat.vue'
import TabPrivateChat from './components/TabPrivateChat.vue'
import TabRedactorPhase from './components/TabRedactorPhase.vue'
import TabUsersList from './components/TabUsersList.vue'
import TabWar from './components/TabWar.vue'
import PoliciesAndTermsEN from './components/policies_and_terms/PoliciesAndTermsEN.vue'
import PoliciesAndTermsUK from './components/policies_and_terms/PoliciesAndTermsUK.vue'
import GameGameplay from './components/game_info_components/GameGameplay.vue'
import GameFeatures from './components/game_info_components/GameFeatures.vue'

import AdminLayer from './components/game_field_layers/AdminLayer.vue'
import ArrowLayer from './components/game_field_layers/ArrowLayer.vue'
import GarrisonLayer from './components/game_field_layers/GarrisonLayer.vue'
import LandsLayer from './components/game_field_layers/LandsLayer.vue'
import OrdersLayer from './components/game_field_layers/OrdersLayer.vue'
import SVGLayer from './components/game_field_layers/SVGLayer.vue'
import TokensLayer from './components/game_field_layers/TokensLayer.vue'
import TracksLayer from './components/game_field_layers/TracksLayer.vue'
import UnitsLayer from './components/game_field_layers/UnitsLayer.vue'

import AllCardsModal from './components/modals/AllCardsModal.vue'
import ConfirmBidModal from './components/modals/ConfirmBidModal.vue'
import ConfirmComplaintModal from './components/modals/ConfirmComplaintModal.vue'
import ConfirmDelModal from './components/modals/ConfirmDelModal.vue'
import ConfirmDeleteUserModal from './components/modals/ConfirmDeleteUserModal.vue'
import ConfirmDonateModal from './components/modals/ConfirmDonateModal.vue'
import ConfirmInvitationModal from './components/modals/ConfirmInvitationModal.vue'
import ConfirmJoinGameModal from './components/modals/ConfirmJoinGameModal.vue'
import ConfirmLeaveRoomModal from './components/modals/ConfirmLeaveRoomModal.vue'
import ConfirmPayModal from './components/modals/ConfirmPayModal.vue'
import ConfirmSetGamePasswordModal from './components/modals/ConfirmSetGamePasswordModal.vue'
import CreateEmailModal from './components/modals/CreateEmailModal.vue'
import CreateGameModal from './components/modals/CreateGameModal.vue'
import CreatePasswordModal from './components/modals/CreatePasswordModal.vue'
import CreateUserModal from './components/modals/CreateUserModal.vue'
import HeepEmojiiModal from './components/modals/HeepEmojiiModal.vue'
import HeepHousesModal from './components/modals/HeepHousesModal.vue'
import HintAwardModal from './components/modals/HintAwardModal.vue'
import HintHouseCardModal from './components/modals/HintHouseCardModal.vue'
import HistoryBidModal from './components/modals/HistoryBidModal.vue'
import HistoryComplaintModal from './components/modals/HistoryComplaintModal.vue'
import HistoryGamesModal from './components/modals/HistoryGamesModal.vue'
import HistoryPlayersModal from './components/modals/HistoryPlayersModal.vue'
import HistoryUserTransactionsModal from './components/modals/HistoryUserTransactionsModal.vue'
import InfoBanModal from './components/modals/InfoBanModal.vue'
import InfoBankModal from './components/modals/InfoBankModal.vue'
import InfoCardZoomModal from './components/modals/InfoCardZoomModal.vue'
import InfoErrorModal from './components/modals/InfoErrorModal.vue'
import InfoGameOverModal from './components/modals/InfoGameOverModal.vue'
import InfoHintModal from './components/modals/InfoHintModal.vue'
import InfoNewRoundModal from './components/modals/InfoNewRoundModal.vue'
import InfoPersonModal from './components/modals/InfoPersonModal.vue'
import InfoModal from './components/modals/InfoModal.vue'

import ConfirmTransferMoneyModal from './components/modals/ConfirmTransferMoneyModal.vue'
import HeepUnitsModal from './components/modals/HeepUnitsModal.vue'
import HintWildingCardModal from './components/modals/HintWildingCardModal.vue'
import HistoryAllTransactionsModal from './components/modals/HistoryAllTransactionsModal.vue'
import HistoryWesterosModal from './components/modals/HistoryWesterosModal.vue'
import HistoryWildingsModal from './components/modals/HistoryWildingsModal.vue'
import InfoUserModal from './components/modals/InfoUserModal.vue'
import RedactorLandModal from './components/modals/RedactorLandModal.vue'
import RedactorUserModal from './components/modals/RedactorUserModal.vue'
import ResetPasswordModal from './components/modals/ResetPasswordModal.vue'
import SocialUsersModal from './components/modals/SocialUsersModal.vue'
import SoundSettingsModal from './components/modals/SoundSettingsModal.vue'

import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

import 'material-icons/iconfont/material-icons.css'
import './assets/styles/index.scss'

const routes = [
  {
    name: 'privacy_policy',
    path: '/privacy_policy',
    component: PrivacyPolicyView,
  },

  {
    name: 'home',
    path: '/',
    component: LoginView,
    beforeEnter: async () => {
      let res
      try {
        if (!store.state.general_store.authenticated) {
          res = await axios.post('/app/refresh')
        } else {
          return { name: 'lobby' }
        }
      } catch (e) {
        if (e.response && e.response.data) {
          const { code = '000', message = '' } = e.response.data.error
          store.commit('general_store/showError', { error: { code, message } })
        }
        return true
      }
      const { auth, user, sid } = res.data

      if (auth) {
        store.commit('general_store/logIn', {
          user_id: user.id,
          sid,
        })
        return { name: 'lobby' }
      }

      store.commit('general_store/logOut')
      return true
    },
  },

  {
    name: 'lobby',
    path: '/lobby',
    component: LobbyView,
    beforeEnter: async () => {
      let res
      try {
        if (!store.state.general_store.authenticated) {
          res = await axios.post('/app/refresh')
        } else {
          return true
        }
      } catch (e) {
        if (e.response && e.response.data) {
          const { code = '000', message = '' } = e.response.data.error
          store.commit('general_store/showError', { error: { code, message } })
        }
        return { name: 'home' }
      }
      const { auth, user, sid, error } = res.data

      if (error) {
        store.commit('general_store/showError', { error: { code: error.code } })
        return { name: 'home' }
      }

      if (auth) {
        store.commit('general_store/logIn', {
          user_id: user.id,
          sid,
        })
        return true
      }
      return { name: 'home' }
    },
  },
  {
    name: 'admin_init',
    path: '/admin_init',
    component: AdminInitView,
    beforeEnter: async (to, from) => {
      let res
      try {
        res = await axios.post('/app/refresh')
      } catch (e) {
        if (e.response && e.response.data) {
          const { code = '000', message = '' } = e.response.data.error
          store.commit('general_store/showError', { error: { code, message } })
        }
        return { name: 'home' }
      }
      const { auth, user, sid, error } = res.data

      if (auth) {
        store.commit('general_store/logIn', {
          user_id: user.id,
          sid,
        })
        return true
      } else {
        store.commit('general_store/showError', { code: error.code, data: error.data })
        return { name: 'home' }
      }
    },
  },
  {
    name: 'not_found',
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
]

const router = createRouter({
  history: createWebHistory(),
  mode: 'hash',
  routes,
})

const i18n = createI18n({
  locale: 'uk',
  messages,
  missingWarn: false, // Suppress warnings for missing translations
  fallbackWarn: false,
})

function facebook_sdk_init() {
  return new Promise((resolve) => {
    window.fbAsyncInit = function () {
      FB.init({
        appId: import.meta.env.VITE_FACEBOOK_APP_ID,
        xfbml: false, // Parse social plugins on this webpage.
        version: 'v17.0', // Use this Graph API version for this call.
      })
      resolve()
    }
    ;(function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return
      }
      js = d.createElement(s)
      js.id = id
      js.src = 'https://connect.facebook.net/en_US/sdk.js'
      fjs.parentNode.insertBefore(js, fjs)
    })(document, 'script', 'facebook-jssdk')
  })
}
const app = createApp(App)

function startApp() {
  app

    .component('LayerGameField', LayerGameField)
    .component('TabChat', TabChat)
    .component('TabWar', TabWar)
    .component('LayerModalWrapper', LayerModalWrapper)
    .component('NavButtonsRight', NavButtonsRight)
    .component('NavButtonsLeft', NavButtonsLeft)
    .component('NavButtonsTop', NavButtonsTop)
    .component('MessageCard', MessageCard)
    .component('ListUsers', ListUsers)
    .component('ListGames', ListGames)
    .component('TabPlayersList', TabPlayersList)
    .component('TabRedactorPhase', TabRedactorPhase)
    .component('TabUsersList', TabUsersList)
    .component('TabPrivateChat', TabPrivateChat)
    .component('SideBarRight', SideBarRight)
    .component('SideBarLeft', SideBarLeft)
    .component('LoggerComponent', LoggerComponent)
    .component('TabCards', TabCards)
    .component('ExecBtn', ExecBtn)
    .component('SearchingTimer', SearchingTimer)
    .component('PoliciesAndTermsUK', PoliciesAndTermsUK)
    .component('PoliciesAndTermsEN', PoliciesAndTermsEN)
    .component('GameFeatures', GameFeatures)
    .component('GameGameplay', GameGameplay)

    .component('GarrisonLayer', GarrisonLayer)
    .component('AdminLayer', AdminLayer)
    .component('SVGLayer', SVGLayer)
    .component('ArrowLayer', ArrowLayer)
    .component('LandsLayer', LandsLayer)
    .component('UnitsLayer', UnitsLayer)
    .component('TokensLayer', TokensLayer)
    .component('OrdersLayer', OrdersLayer)
    .component('TracksLayer', TracksLayer)

    .component('ConfirmInvitationModal', ConfirmInvitationModal)
    .component('SocialUsersModal', SocialUsersModal)
    .component('ConfirmComplaintModal', ConfirmComplaintModal)
    .component('InfoPersonModal', InfoPersonModal)
    .component('SoundSettingsModal', SoundSettingsModal)
    .component('RedactorUserModal', RedactorUserModal)
    .component('InfoUserModal', InfoUserModal)
    .component('HintWildingCardModal', HintWildingCardModal)
    .component('RedactorLandModal', RedactorLandModal)
    .component('HintHouseCardModal', HintHouseCardModal)
    .component('HistoryGamesModal', HistoryGamesModal)
    .component('HistoryBidModal', HistoryBidModal)
    .component('HistoryPlayersModal', HistoryPlayersModal)
    .component('HeepUnitsModal', HeepUnitsModal)
    .component('HeepEmojiiModal', HeepEmojiiModal)
    .component('InfoGameOverModal', InfoGameOverModal)
    .component('InfoErrorModal', InfoErrorModal)
    .component('InfoHintModal', InfoHintModal)
    .component('HeepHousesModal', HeepHousesModal)
    .component('CreateGameModal', CreateGameModal)
    .component('InfoBanModal', InfoBanModal)
    .component('InfoBankModal', InfoBankModal)
    .component('ConfirmDonateModal', ConfirmDonateModal)
    .component('HistoryUserTransactionsModal', HistoryUserTransactionsModal)
    .component('HistoryAllTransactionsModal', HistoryAllTransactionsModal)
    .component('InfoNewRoundModal', InfoNewRoundModal)
    .component('HistoryWesterosModal', HistoryWesterosModal)
    .component('HistoryWildingsModal', HistoryWildingsModal)
    .component('HistoryComplaintModal', HistoryComplaintModal)
    .component('ConfirmBidModal', ConfirmBidModal)
    .component('CreateEmailModal', CreateEmailModal)
    .component('CreatePasswordModal', CreatePasswordModal)
    .component('CreateUserModal', CreateUserModal)
    .component('ResetPasswordModal', ResetPasswordModal)
    .component('ConfirmPayModal', ConfirmPayModal)
    .component('ConfirmJoinGameModal', ConfirmJoinGameModal)
    .component('ConfirmLeaveRoomModal', ConfirmLeaveRoomModal)
    .component('ConfirmTransferMoneyModal', ConfirmTransferMoneyModal)
    .component('ConfirmDeleteUserModal', ConfirmDeleteUserModal)
    .component('ConfirmDelModal', ConfirmDelModal)
    .component('ConfirmSetGamePasswordModal', ConfirmSetGamePasswordModal)
    .component('HintAwardModal', HintAwardModal)
    .component('AllCardsModal', AllCardsModal)
    .component('InfoCardZoomModal', InfoCardZoomModal)
    .component('InfoModal', InfoModal)

    .component('pulse-loader', PulseLoader)
    .component('clip-loader', ClipLoader)

  app.use(store)
  app.use(router)
  app.use(VueAxios, axios)
  app.use(i18n)

  app.config.globalProperties.$hammer = Hammer

  app.mount('#app')
}

facebook_sdk_init().then(startApp)
export default app
