<template>
  <div id="modal_base" class="modal_base" @click="close">
    <div class="game_creator_container hide_scrollbar red_box_shadow">
      <div class="border">
        <div class="create_btn_wr" @click="newGame">
          {{ $t(`message.new_game`) }}
        </div>
        <div class="inner_area">
          <div class="top_wr">
            <div class="row">
              <div class="row_title"><span class="material-icons"> text_fields </span></div>
              <div class="row_input">
                <input v-model="options.name" type="text" minlength="3" maxlength="30" />
              </div>
            </div>
            <div class="row">
              <div class="row_title"><span class="material-icons"> key </span></div>
              <div class="row_input">
                <input v-model="options.password" type="text" minlength="3" maxlength="8" />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="row_title"><span class="material-icons"> groups </span></div>
            <div class="row_input">
              <span class="output">
                <output id="rangevalue_max_players">{{ options.max_players }}</output>
              </span>
              <input v-model="options.max_players" type="range" min="3" max="6" />
            </div>
          </div>

          <div class="row">
            <div class="row_title">
              <span
                class="material-icons"
                :style="{
                  color: '#bfbff1',
                }"
              >
                psychology
              </span>
            </div>
            <div class="row_input">
              <span class="output">
                <output id="rangevalue_intelligence">{{ options.intelligence }}</output>
              </span>
              <input v-model="options.intelligence" type="range" min="0" max="50" />
            </div>
          </div>
          <div class="row">
            <div class="row_title">
              <span
                class="material-icons"
                :style="{
                  color: 'orange',
                }"
              >
                stars
              </span>
            </div>
            <div class="row_input">
              <span class="output">
                <output id="rangevalue_honor">{{ options.honor }}</output>
              </span>
              <input v-model="options.honor" type="range" min="0" max="90" />
            </div>
          </div>
          <div class="row">
            <div class="row_title">
              <span
                class="material-icons"
                :style="{
                  color: '#da1717',
                }"
              >
                favorite
              </span>
            </div>
            <div class="row_input">
              <span class="output">
                <output id="rangevalue_charm"> {{ options.charm }} </output>
              </span>

              <input v-model="options.charm" type="range" min="-20" max="50" />
            </div>
          </div>
          <div class="row">
            <div class="row_title">
              <span
                class="material-icons"
                :style="{
                  color: '#e26464',
                }"
              >
                query_builder
              </span>
            </div>
            <div class="row_input">
              <span class="output">
                <output id="rangevalue_bank"> {{ options.timebank }} </output>
              </span>

              <input v-model="options.timebank" type="range" min="10" max="600" step="10" />
            </div>
          </div>
          <div class="row">
            <div class="row_title">
              <span
                class="material-icons"
                :style="{
                  color: '#e26464',
                }"
              >
                more_time
              </span>
            </div>
            <div class="row_input">
              <span class="output">
                <output id="rangevalue_add"> {{ options.time_to_move }} </output>
              </span>

              <input v-model="options.time_to_move" type="range" min="10" max="300" step="10" />
            </div>
          </div>
          <div class="row">
            <div class="row_title image">
              <img src="/images/westeros_cards/random_cards/random_back.png" alt="random_back" />
            </div>
            <div class="row_input">
              <input v-model="options.allow_random_cards" type="checkbox" />
            </div>
          </div>

          <div v-show="options.allow_random_cards" class="row">
            <div class="row_title">
              <span
                class="material-icons"
                :style="{
                  color: 'red',
                }"
              >
                pin
              </span>
            </div>
            <div class="row_input">
              <span class="output">
                <output id="force_range"> {{ options.random_force }} </output>
              </span>

              <input v-model="options.random_force" type="range" min="0" max="3" />
            </div>
          </div>

          <div v-show="options.allow_random_cards" class="row">
            <div class="row_title image">
              <img src="/images/westeros_cards/random_cards/0_s.png" alt="random_back" />
            </div>
            <div class="row_input">
              <input v-model="options.scull" type="checkbox" />
            </div>
          </div>

          <div class="row">
            <div class="row_title">
              <span
                class="material-icons"
                :style="{
                  color: 'green',
                }"
              >
                paid
              </span>
            </div>
            <div class="row_input">
              <span class="output">
                <output id="buy_range"> {{ options.bank.buy_in }} </output>
              </span>
              <div class="buyin_range_wr">
                <input v-model="options.bank.buy_in" type="range" min="0" :max="maxBuyIn" step="10" />
                <div class="range_btns">
                  <span class="material-icons" @click="changeBuyIn(-10)"> chevron_left </span>
                  <span class="material-icons" @click="changeBuyIn(10)"> chevron_right </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import bcrypt from 'bcryptjs'
  import shortid from 'shortid'
  import { mapGetters, mapMutations } from 'vuex'
  export default {
    inject: ['socket'],

    data() {
      return {
        room_id: null,
        helper: null,
        options: {
          max_players: 6,
          type: 'classic',
          name: '',
          password: '',
          intelligence: 0,
          honor: 0,
          charm: -11,
          timebank: 180,
          time_to_move: 30,
          random_force: '0',
          allow_random_cards: false,
          scull: false,
          bank: {
            buy_in: 0,
            list: [],
          },
        },
        maxBuyIn: 1000,
      }
    },
    computed: {
      ...mapGetters('general_store', ['user']),
    },
    mounted() {
      this.maxBuyIn = Math.floor(+this.user?.money.total / 10) * 10
    },

    methods: {
      ...mapMutations('general_store', ['processModal', 'setDataForModal']),
      close(e) {
        if (e.target.id !== 'modal_base') return
        this.processModal()
        this.setDataForModal({ reset: true })
      },

      changeBuyIn(num) {
        this.options.bank.buy_in = +this.options.bank.buy_in + num
        if (this.options.bank.buy_in < 0) {
          this.options.bank.buy_in = 0
        }
        if (this.options.bank.buy_in > this.maxBuyIn) {
          this.options.bank.buy_in = this.maxBuyIn
        }
      },

      async createNewGame(settings) {
        const room_id = `game--${shortid.generate()}`
        const options = { ...settings, id: room_id }

        this.socket.emit('client_action', {
          action_type: 'NewGame',
          data: { options },
        })
        this.setDataForModal({
          data: { room_id },
        })
        this.processModal({ name: 'create_game', type: 'close' })
        this.processModal({ name: 'houses' })
      },

      async newGame() {
        if (this.options.name.length < 3) {
          return
        }
        if (this.options.password && this.options.password.length < 3) {
          return
        }

        this.setGameType('classic', this.options.max_players)

        if (this.options.password) {
          this.options.password = await bcrypt.hash(this.options.password, 10)
        }

        this.createNewGame(this.options)

        this.options = {
          max_players: 6,
          type: 'classic',
          name: '',
          password: '',
          intelligence: 0,
          honor: 0,
          charm: -11,
          timebank: 180,
          time_to_move: 30,
          random_force: '0',
          allow_random_cards: false,
          scull: false,
          bank: {
            buy_in: 0,
            list: [],
          },
        }
      },

      setGameType(type, max_players) {
        this.options.type = type
        this.options.max_players = +max_players
      },
    },
  }
</script>
<style lang="scss" scoped>
  .modal_base {
    position: relative;
    width: 100dvw;
    height: 100dvh;
    background-color: $modals_background_color;
    pointer-events: all;
  }
  .game_creator_container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0rem 1.5rem;
    max-width: $modals_max_width;
    max-height: $modals_max_height;
    min-width: 280px;
    width: auto;
    height: auto;
    color: white;
    border-radius: $theme_border_radius;
    background-color: $modals_color;
  }

  .border {
    margin: 1rem;
    margin-bottom: 3rem;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;
    .create_btn_wr {
      position: fixed;
      bottom: 0.4rem;
      left: 50%;
      transform: translateX(-50%);
      padding: 0.4rem;
      background-color: red;
      border-radius: $theme_border_radius;
      cursor: pointer;
      text-align: center;
      &:hover {
        background-color: rgb(119, 30, 30);
      }
    }
  }

  .inner_area {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    .top_wr {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .row {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .row_title {
        width: 20%;
      }
      .image {
        width: 20%;
        img {
          width: 100%;
        }
      }
      .row_input {
        width: 60%;
        display: flex;
        justify-content: flex-end;
        padding: 1px;
        align-items: center;

        .output {
          width: 25%;
          height: 100%;
          align-self: flex-start;
          margin-top: 5px;
        }

        input {
          border: 1px solid white;
          height: 1.5rem;
          margin-bottom: 1rem;
          width: 80%;
          background-color: #292828;
          color: white;
          font-size: 1rem;
        }

        .buyin_range_wr {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 0.4rem;
          input {
            width: 100%;
            margin-bottom: 0;
          }

          .range_btns {
            display: flex;
            gap: 1rem;
            span {
              cursor: pointer;
            }
            span:hover {
              color: red;
            }
          }
        }
      }

      .row_input {
        // margin-left: auto;

        input[type='checkbox'] {
          width: 1rem;
        }
        input[type='text'] {
          width: 100%;
        }

        input:hover {
          border-color: rgb(87, 194, 236);
        }
        input:focus {
          outline: none;
          border-color: rgb(87, 194, 236);
        }
        input:invalid {
          border: 2px dashed red;
        }

        input[type='range'] {
          overflow: hidden;
          -webkit-appearance: none;
          height: 1.2rem;
          touch-action: manipulation;
          background-color: rgb(134, 131, 131);
        }

        input[type='range']::-webkit-slider-runnable-track {
          -webkit-appearance: none;
          box-shadow: none;
          border: none;
        }
        input[type='range']::-moz-range-track {
          -webkit-appearance: none;
          box-shadow: none;
          border: none;
        }
        input[type='range']::-ms-track {
          -webkit-appearance: none;
          box-shadow: none;
          border: none;
        }

        /* Input Thumb */
        input[type='range']::-webkit-slider-thumb {
          -webkit-appearance: none;

          height: 28px;
          width: 10px;
          border-radius: 5%;
          box-shadow: -210px 0 0 200px rgb(202, 202, 202);
          background-image: linear-gradient(rgb(238, 252, 253), rgb(238, 252, 253));
          cursor: ew-resize;
        }

        input[type='range']::-moz-range-thumb {
          -webkit-appearance: none;
          height: 28px;
          width: 10px;
          border-radius: 5%;
          box-shadow: -210px 0 0 200px rgb(202, 202, 202);
          background-image: linear-gradient(rgb(238, 252, 253), rgb(238, 252, 253));
          cursor: ew-resize;
        }

        input[type='range']::-ms-thumb {
          -webkit-appearance: none;
          height: 28px;
          width: 10px;
          border-radius: 5%;
          background-image: linear-gradient(rgb(238, 252, 253), rgb(238, 252, 253));
          cursor: ew-resize;
          box-shadow: -210px 0 0 200px rgb(202, 202, 202);
        }

        input[type='range']::-webkit-slider-thumb:hover {
          background-image: linear-gradient(rgb(238, 252, 253), rgb(113, 205, 248));
        }

        input[type='range']::-moz-range-thumb:hover {
          background-image: linear-gradient(rgb(238, 252, 253), rgb(113, 205, 248));
        }
        input[type='range']::-ms-thumb:hover {
          background-image: linear-gradient(rgb(238, 252, 253), rgb(113, 205, 248));
        }

        input[type='range']::-webkit-slider-thumb:active {
          background-image: linear-gradient(rgb(238, 252, 253), rgb(4, 107, 155));
        }
        input[type='range']::-moz-range-thumb:active {
          background-image: linear-gradient(rgb(238, 252, 253), rgb(4, 107, 155));
        }
        input[type='range']::-ms-thumb:active {
          background-image: linear-gradient(rgb(238, 252, 253), rgb(4, 107, 155));
        }
      }
    }

    .button {
      justify-content: center;
      cursor: pointer;
      height: 2rem;
      width: 80%;
      background-color: rgb(13, 104, 101);
      border-radius: 0.5rem;
      font-size: 1.2rem;
      text-align: center;
      margin-top: 1rem;

      &:hover {
        background-color: rgb(5, 82, 79);
      }
    }
  }

  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.35s;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }
</style>
