<template>
  <div id="modal_base" class="modal_base" @click="close">
    <div class="complaints_container">
      <!-- <h3>{{ $t(`message.complaints`) }}</h3> -->
      <div class="nav_bar_wr">
        <div class="nav_bar">
          <div
            v-for="tab in tabs"
            :key="tab"
            class="tab"
            :class="{ active: tab === active_tab }"
            @click="active_tab = tab"
          >
            {{ $t(`message.${tab}`) }}
          </div>
        </div>
      </div>

      <div class="tables">
        <div class="headers">
          <div class="sender_name">{{ $t(`message.complaint_sender`) }}</div>
          <div class="explain_msg">{{ $t(`message.complaint_message`) }}</div>
          <div class="reason">{{ $t(`message.complaint_reason`) }}</div>
          <div class="date">{{ $t('message.time') }}</div>
        </div>

        <div class="table">
          <TransitionGroup name="fade" mode="out-in" tag="div">
            <div class="table_wr" v-for="c in processed_user.complaints[active_tab]" :key="c">
              <div class="sender_name">{{ getSenderName(c.sender_id) }}</div>
              <div class="explain_msg">{{ c.message || '---' }}</div>

              <div v-if="active_tab !== 'avatar'" class="reason">{{ getReason(c.data) }}</div>
              <div v-else><img :src="getReason(c.data)" alt="ava" class="reason_img" /></div>
              <div class="date">{{ getDate(c.time) }}</div>
            </div>
          </TransitionGroup>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapState, mapGetters, mapMutations } from 'vuex'
  export default {
    data() {
      return {
        tabs: ['chat', 'private_chat', 'play', 'nickname', 'avatar'],
        active_tab: 'chat',
      }
    },

    computed: {
      ...mapState('general_store', ['data_for_modal', 'users']),
      ...mapGetters('general_store', ['mainRoom', 'user']),

      processed_user() {
        return this.data_for_modal.processed_user
      },
    },

    methods: {
      ...mapMutations('general_store', ['processModal']),
      close(e) {
        if (e.target.id !== 'modal_base') return
        this.processModal({ name: 'complaint_list', type: 'close' })
      },
      getSenderName(id) {
        const user = this.users.find((u) => u.id === id)
        return user.name
      },
      getDate(ms) {
        return new Date(ms).toLocaleString()
      },
      getReason(data) {
        if (this.active_tab === 'chat' || this.active_tab === 'private_chat') {
          return data.complaint_data.text
        }
        if (this.active_tab === 'avatar') {
          return `/images/avatars/${data.avatars[0]}`
        }
        if (this.active_tab === 'nickname') {
          return data.name
        }
        if (this.active_tab === 'play') {
          return data.text
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .modal_base {
    width: 100dvw;
    height: 100dvh;
    z-index: 400;
    background-color: rgba(0, 0, 0, 0.478);
    pointer-events: all;
    position: relative;

    .complaints_container {
      position: absolute;
      box-sizing: border-box;
      padding: 1rem;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 0.4rem;
      background-color: black;
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      max-width: 90%;
      max-height: 90%;
      gap: 0.5rem;
      background-color: $modals_color;
      -webkit-box-shadow: -1px -4px 97px 31px rgba(33, 93, 158, 0.845);
      -moz-box-shadow: -1px -4px 97px 31px rgba(33, 94, 158, 0.845);
      box-shadow: -1px -4px 97px 31px rgba(33, 94, 158, 0.845);
      .nav_bar_wr {
        width: 100%;
        overflow-x: auto;
        // margin: 0.5rem auto;

        .nav_bar {
          display: flex;
          gap: 0.5rem;
          box-sizing: border-box;
          margin: 0.5rem auto;
          width: fit-content;
          // overflow-x: auto;

          .tab {
            box-sizing: border-box;
            padding: 0.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            background-color: rgb(136, 136, 185);
            border-radius: 0.5rem;
            cursor: pointer;
            transition: all 0.5s;
            &:hover {
              background-color: rgb(50, 52, 51);
              color: white;
            }
          }

          .active {
            background-color: aliceblue;
            color: black;
          }
        }
      }

      .tables {
        flex: 1;
        width: 100%;
        overflow-y: auto;

        .headers {
          position: sticky;
          top: 0;
          box-sizing: border-box;
          display: flex;
          justify-content: space-around;
          width: 100%;
          color: rgb(214, 211, 211);
          border: 2px solid white;
          background-color: black;

          div {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            box-sizing: border-box;
            padding: 0.3rem;
            border-right: 2px solid white;
            flex: 1;
            &:last-child {
              border-right: none;
              // flex: 1;
            }
          }
        }
        .table {
          width: 100%;
          box-sizing: border-box;
          // background-color: beige;
          color: black;

          .table_wr {
            width: 100%;
            // background-color: rgba(127, 255, 212, 0.586);
            display: flex;

            div {
              min-width: 0;
              flex: 1;
              max-height: 10rem;
              overflow-y: auto;
              text-align: center;
              box-sizing: border-box;
              padding: 0.2rem;
              word-wrap: break-word;
              // border: 1px solid red;
              color: white;
              border-bottom: 1px dashed white;
              scrollbar-width: none;
              &::-webkit-scrollbar {
                display: none;
              }

              .reason_img {
                width: 5rem;
                height: auto;
              }
            }
          }
        }
      }
    }
  }
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
    // transform: translateY(20px);
    position: absolute;
    left: 0;
    top: 0;
    // transition-delay: $delay;
  }
  .fade-enter-active {
    transition: all 0.35s ease;
    transition-delay: 0.35s;
    // transition-delay: $delay;
  }
  .fade-leave-active {
    transition: all 0.35s ease;
    // transition-delay: $delay;
  }
</style>
