<template>
  <div class="side_center_container">
    <div v-for="(game, idx) in games" :key="idx" class="game">
      <div class="game_title" :class="`${game.state.status}`">
        <div class="main_btns prevent-select">
          <div
            v-if="allowJoinGame(game.id)"
            class="btn_wr"
            :class="{
              login_color:
                game.players.some((p) => p.id === user?.id) || user?.current_games.some((g) => g.sid === sid),
            }"
            @click="tryJoinGame(game)"
            @pointerenter="hint_label_hover($event, { msg: 'join_game' })"
            @pointerleave="resetHint"
          >
            <span class="material-icons"> login </span>
          </div>
          <div
            v-if="allowWatchGame(game.id)"
            class="btn_wr"
            @click="watchGame(game.id)"
            @pointerenter="hint_label_hover($event, { msg: 'watch_game' })"
            @pointerleave="resetHint"
          >
            <span class="material-icons"> visibility </span>
          </div>
          <div v-if="user.role?.value.includes('admin')" class="btn_wr" @click="deleteGame(game.id, user.id)">
            <span class="material-icons"> delete </span>
          </div>
        </div>
        <div class="game_name" @pointerenter="hint_label_hover($event, { msg: 'game_name' })" @pointerleave="resetHint">
          <span
            v-if="user.role?.value.includes('admin') && game.state.status === 'gathering'"
            class="searching_timer"
            >{{ getGatheringTimer(game) }}</span
          >
          <span>{{ getFieldData('name', game) }}</span>
        </div>
        <div class="data_field" @pointerenter="hint_label_hover($event, { msg: 'time' })" @pointerleave="resetHint">
          <span class="prevent-select">{{ getFieldData('time', game) }}</span>
        </div>
      </div>
      <div class="info_body">
        <div class="main_info">
          <div
            v-for="field in info_fields"
            :key="field[0]"
            class="info_field prevent-select"
            @pointerenter="hint_label_hover($event, { msg: field[0] })"
            @pointerleave="resetHint"
          >
            <span class="material-icons" :style="{ color: field[2] }">{{ field[1] }}</span>
            <span class="span">{{ getFieldData(field[0], game) }}</span>
          </div>
        </div>
        <!-- <div
            class="status_img"
            @pointerenter="hint_label_hover($event, { msg: game.state.status })"
            @pointerleave="resetHint"
          >
            <span
              v-if="user.role?.value.includes('admin') && game.state.status === 'searching'"
              class="searching_timer"
              >{{ getSearchingTimer(game) }}</span
            >
            <span
              v-if="user.role?.value.includes('admin') && game.state.status === 'gathering'"
              class="searching_timer"
              >{{ getGatheringTimer(game) }}</span
            >
            <img :src="`/images/${game.state.status}.png`" alt="status" />
          </div> -->
        <div class="houses">
          <div v-for="player in game.players" :key="player.home" class="house_wr">
            <img :src="`/images/${player.home}_house.jpg`" :alt="player.home" :class="{ filter_grey: player.id }" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapMutations, mapState } from 'vuex'

  export default {
    inject: ['socket'],

    data() {
      return {
        hint_delay: 250,
        clear_hint_delay: null,
        hint_timer_delay: null,
        info_fields: [
          ['bank.buy_in', 'paid', 'green'],
          ['password', 'lock', 'white'],
          ['time_to_move', 'more_time', '#babae2'],
          ['timebank', 'query_builder', '#babae2'],
          ['random_force', 'library_add', 'red'],
          ['scull', 'cancel', 'white'],
          ['charm', 'favorite', 'red'],
          ['intelligence', 'psychology', '#babae2'],
          ['honor', 'stars', 'orange'],
        ],
      }
    },

    computed: {
      ...mapGetters('general_store', ['mainRoom', 'user', 'current_game_id']),
      ...mapState('general_store', ['timers', 'gathering_timers', 'sid', 'rooms', 'users']),

      games() {
        return this.rooms?.filter((room) => room.id !== 'mainRoom').reverse()
      },
    },

    mounted() {},

    methods: {
      ...mapMutations('gui_store', ['setHintLabel']),
      ...mapMutations('general_store', ['processModal', 'setDataForModal', 'setInsideRoom']),

      resetHint() {
        clearTimeout(this.hint_timer_delay)
        clearTimeout(this.clear_hint_delay)

        this.clear_hint_delay = null
        this.hint_timer_delay = null
        this.setHintLabel({ reset: true })
      },

      async deleteGame(room_id, user_id) {
        this.setDataForModal({
          data: {
            cb: function (result) {
              if (!result) return
              this.socket.emit('client_action', {
                action_type: 'DeleteGame',
                data: { room_id, user_id },
              })
            }.bind(this),
            type: 'delete_game',
          },
        })
        this.processModal({ name: 'confirm_del' })
      },

      watchGame(room_id) {
        const cur_game = this.user.current_games.find((g) => g.room_id === room_id)
        if (cur_game?.room_id === room_id) {
          this.setInsideRoom({
            value: !this.inside_room,
            room_id,
          })
        } else {
          this.socket.emit('client_action', {
            action_type: 'JoinGame',
            data: { user_id: this.user.id, room_id, home: 'watcher', sid: this.sid },
          })
        }
      },

      getSearchingTimer(game) {
        if (!this.timers[game.id]) return
        let date = new Date(this.timers[game.id] * 1000)
        let timeString = date.toISOString().substring(11, 19)

        return timeString
      },
      getGatheringTimer(game) {
        if (!this.gathering_timers[game.id]) return
        let date = new Date(this.gathering_timers[game.id] * 1000)
        let timeString = date.toISOString().substring(11, 19)

        return timeString
      },

      tryJoinGame(game) {
        if (this.user.ban.play.value) {
          this.socket.emit('client_action', {
            action_type: 'BanUser',
            data: {
              user_id: this.user.id,
              target_user_id: this.user.id,
              update: true,
            },
          })
          return
        }

        const cur_game_in_user = this.user.current_games.find((g) => g.room_id === game.id)

        if (cur_game_in_user && cur_game_in_user.home !== 'watcher') {
          this.socket.emit('client_action', {
            action_type: 'JoinGame',
            data: {
              room_id: game.id,
              user_id: this.user.id,
              home: game.home,
              sid: this.sid,
              buy: false,
            },
          })
          return
        }

        let av_houses = game.players.reduce((acc, player) => {
          if (!player.id) {
            acc.push(player.home)
          }
          return acc
        }, [])

        if (av_houses.length === 1 && !game.game_options.password) {
          const home = av_houses[0]

          if (game.game_options.bank.buy_in && !game.game_options.bank.list.includes(this.user.id)) {
            this.setDataForModal({
              data: {
                buyin: game.game_options.bank.buy_in,
                select_home: buy,
                cb: (result) => {
                  if (!result) return
                  this.socket.emit('client_action', {
                    action_type: 'JoinGame',
                    data: {
                      room_id: game.id,
                      user_id: this.user.id,
                      home,
                      sid: this.sid,
                      buy: false,
                    },
                  })
                },
              },
            })
            this.processModal({ name: 'join_game' })
            return
          }

          this.socket.emit('client_action', {
            action_type: 'JoinGame',
            data: {
              room_id: game.id,
              user_id: this.user.id,
              home,
              sid: this.sid,
              buy: false,
            },
          })
          return
        }
        this.setDataForModal({ data: { room_id: game.id } })

        this.processModal({ name: 'houses' })
      },

      hint_label_hover(e, item) {
        if (e.pointerType !== 'mouse') return
        if (!item) return
        this.resetHint()

        this.hint_timer_delay = setTimeout(() => {
          const coords = e.target.getBoundingClientRect()
          const logYpos = `translate(${coords.left}px, ${coords.top + 30}px)`
          this.setHintLabel({
            msg: item.msg,
            coords: logYpos,
          })

          this.clear_hint_delay = setTimeout(() => {
            this.resetHint()
          }, 2000)
        }, this.hint_delay)
      },

      getFieldData(key, game) {
        // const game = this.rooms.find((r) => r.id === room_id);
        let key_1 = key,
          key_2
        if (key.includes('.')) {
          const keys = key.split('.')
          key_1 = keys[0]
          key_2 = keys[1]
        }
        let data
        if (key_2) {
          data = game.game_options[key_1][key_2]
        } else {
          data = game.game_options[key_1]
        }

        if (key_1 === 'password' && data) {
          return '***'
        }
        if (key_1 === 'random_force' && data === '0') {
          if (game.game_options.scull) {
            return '0'
          }
          return '-'
        }

        if (key_1 !== 'charm' && (!data || data === '0')) {
          return '-'
        }
        return data
      },

      allowWatchGame(game_id) {
        const currentGames = this.user?.current_games || []
        const room = this.rooms.find((r) => r.id === game_id)
        if (!room) return false
        const r_obj = currentGames.find((r) => r.room_id === game_id)
        if (!r_obj) return true

        let user_is_in_room = room.players.some((p) => p.id === this.user.id)
        if (!user_is_in_room) {
          user_is_in_room = room.users.some((p) => p.id === this.user.id)
          if (!user_is_in_room) return true
        }
        return false
      },

      allowJoinGame(game_id) {
        if (this.current_game_id && game_id === this.current_game_id) return true
        if (this.current_game_id && game_id !== this.current_game_id) return false

        const room = this.rooms.find((r) => r.id === game_id)
        if (!room) return false
        if (room.state.status === 'end') return false
        if (this.user?.current_games.some((g) => g.room_id === game_id)) return true
        if (room.players.some((player) => !player.id)) {
          return true
        }

        return false
      },
    },
  }
</script>

<style lang="scss" scoped>
  .side_center_container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-self: center;
    gap: 0.5rem;
    align-items: center;
    height: 100dvh;
    min-width: 280px;
    width: 40%;
    background-color: #29282846;
    overflow-y: auto;
    padding-top: calc($nav_buttons_top_height + 0.2rem);
    pointer-events: all;
    @media screen and (max-height: 400px) {
      padding-top: calc($nav_buttons_top_height_shrinked + 0.2rem);
    }
  }

  .game {
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.4rem;
    width: 90%;
    min-width: 280px;
    height: auto;
    padding: 0.4rem;
    background-color: #000000;
    border-radius: 0.4rem;
  }

  .game_title {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    width: 100%;
    color: white;
    padding: 0.4rem;
    border-radius: 0.2rem;

    @media screen and (max-width: 400px) {
      flex-direction: column;
      gap: 1rem;
    }

    .data_field {
      flex: 1;
      text-align: center;
      font-size: 0.8rem;
    }

    .game_name {
      flex: 4;
      overflow: hidden;
      text-align: center;
      font-size: 1.4rem;
      font-weight: 900;
      color: rgb(255, 255, 255);
    }

    .main_btns {
      flex: 1;
      display: flex;
      align-items: center;
      gap: 0.2rem;
      @media screen and (max-width: 670px) {
        gap: 0.4rem;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        span {
          font-size: 1.4rem;
        }
      }
      @media screen and (max-width: 400px) {
        gap: 1rem;
        flex-direction: row;
      }

      .btn_wr {
        display: flex;
        align-items: center;
        span {
          cursor: pointer;
          &:hover {
            color: rgb(208, 56, 96);
          }
        }
      }

      .login_color {
        color: rgb(12, 78, 158);
      }
    }
  }

  .info_body {
    flex: 4;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    gap: 1rem;
  }

  .searching_timer {
    position: absolute;
    z-index: 2;
    bottom: 0.3rem;
    color: red;
    background-color: black;
  }
  .main_info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.2rem;
    height: 8rem;

    .info_field {
      color: White;
      display: flex;
      align-items: center;
      width: 20%;
      gap: 0.5rem;
      justify-content: space-between;

      span {
        font-size: 1rem;

        @media screen and (max-width: 520px) {
          font-size: 0.8rem;
        }
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .filter_grey {
    filter: grayscale(100%);
    opacity: 0.7;
  }
  .houses {
    display: flex;
    gap: 0.2rem;
    justify-content: center;
    height: 100%;

    .house_wr {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 120px;
      }
    }
  }

  .prevent-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }

  .gathering {
    background-color: green;
  }
  .end {
    background-color: rgb(0, 0, 0);
  }
  .searching {
    background-color: rgb(229, 135, 41);
  }
  .playing {
    background-color: rgb(224, 12, 12);
  }
  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.35s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }

  .list_wrapper::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .list_wrapper {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
</style>
