2
<template>
  <div id="background" class="modal_wr" @click="modalCloser">
    <div v-if="wildingCard" class="wild-card" data-type="wilding_modal">
      <div class="inner_wr_wilding">
        <div v-if="wildingCard" class="wild_card_img" data-type="wilding_modal">
          <img
            data-type="wilding_modal"
            :src="`/images/westeros_cards/wildings/${locale}/${wildingCard}.png`"
            :alt="wildingCard"
          />
        </div>
        <div v-if="wildingCard && phase_name === 'CrowCheck' && i_am_active" class="btns">
          <div class="btn" data-value="true" @click="modalCloserAlt">
            <span data-value="true">{{ $t('message.dig_wild') }}</span>
          </div>
          <div class="btn" data-value="false" @click="modalCloserAlt">
            <span data-value="false">{{ $t('message.not_dig_wild') }}</span>
          </div>
        </div>
        <div v-if="phase_name !== 'CrowCheck' && wildingsState?.card" class="throne_decision" data-type="wilding_modal">
          <span data-type="wilding_modal">{{ wilding_res_message }}</span>
          <div v-if="candidates.length" class="homes_wr" data-type="wilding_modal">
            <span class="material-icons wildings_history" @click.stop="openWildingsHistory"> history_edu </span>
            <img
              v-for="home in candidates"
              :key="home"
              :src="`/images/tokens/house_tokens/track_tokens/${home}_track.png`"
              :alt="home"
              data-type="wilding_modal"
              class="wilding_modal_img"
              :class="{ hoverEnable: homeTokenHoverEnable }"
              @mousedown="wilding_candidate_choice_handler(home)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters, mapMutations } from 'vuex'

  export default {
    data() {
      return {
        next_wild_card: null,
      }
    },
    computed: {
      ...mapGetters('general_store', [
        'game',
        'home',
        'phase_name',
        'myRole',
        'wildingsState',
        'lastWildingsCard',
        'user',
        'active_players',
      ]),

      locale() {
        return this.user.locale
      },

      wildingCard() {
        if (this.phase_name === 'CrowCheck' && this.i_am_active) {
          return this.next_wild_card
        }
        return this.game?.state.last_wildings_card
      },

      candidates() {
        if (this.wildingsState?.is_victory) {
          return this.wildingsState?.bigest_candidates
        }
        return this.wildingsState?.lowest_candidates
      },

      wilding_res_message() {
        const is_victory = this.wildingsState?.is_victory

        let msg = ''
        if (this.phase_name === 'ThroneDecision') {
          if (is_victory) {
            msg = this.$t(`message.throne_decision_victory`)
          } else {
            msg = this.$t(`message.throne_decision_loose`)
          }
          return msg
        }

        msg = is_victory ? this.$t(`message.bigest_rate`) : this.$t(`message.lowest_rate`)

        return msg
      },

      i_am_active() {
        return this.active_players?.includes(this.home)
      },
      homeTokenHoverEnable() {
        const userActive = this.game?.state.phase.active_players.includes(this.home)
        const isThroneDesision = this.phase_name === 'ThroneDecision'
        return isThroneDesision && userActive
      },
    },

    async mounted() {
      this.setThroneDecision({ choice: '' })
      if (this.phase_name === 'CrowCheck' && this.i_am_active) {
        const res = await this.axios.get('/app/get_next_wild_card')

        this.next_wild_card = res?.data?.card
      }
    },

    methods: {
      ...mapMutations('game_store', ['setThroneDecision', 'setDigWildingsCard', 'setExecTriger']),
      ...mapMutations('general_store', ['processModal']),

      openWildingsHistory() {
        this.processModal({ name: 'wildings_history' })
      },

      modalCloser(e) {
        if (e.target.id !== 'background') return
        const is_crow_check = this.phase_name === 'CrowCheck'
        const isPlayerActive = this.active_players?.includes(this.home)
        if (is_crow_check && isPlayerActive) {
          const value = e.target?.dataset?.value === 'true' ? true : false
          this.processModal()
          this.setDigWildingsCard({ dig: value })
          this.setExecTriger({ doit: true })
          return
        }
        this.processModal()
      },

      modalCloserAlt(e) {
        const is_crow_check = this.phase_name === 'CrowCheck'
        const isPlayerActive = this.active_players?.includes(this.home)
        if (is_crow_check && isPlayerActive) {
          // if (!e.target?.dataset?.value) return;
          const value = e.target?.dataset?.value === 'true' ? true : false
          this.processModal()
          this.setDigWildingsCard({ dig: value })

          this.setExecTriger({ doit: true })
          return
        }
      },

      wilding_candidate_choice_handler(home) {
        if (!this.active_players?.includes(this.home)) return
        if (this.phase_name !== 'ThroneDecision') return
        this.setThroneDecision({ choice: home })
        this.setExecTriger({ doit: true })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .modal_wr {
    position: absolute;
    width: 100dvw;
    height: 100dvh;
    pointer-events: all;
    background-color: $modals_background_color;
    z-index: 20;
    top: 0;
    left: 0;
    bottom: 0;
  }
  .wild-card {
    pointer-events: all;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -55%);
    width: auto;
    height: auto;
    max-width: 80dvw;
    max-height: $modals_max_height;
  }
  .inner_wr_wilding {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
  }
  .wild_card_img {
    // width: auto;
    // max-width: 80%;
    // height: fit-content;
    height: auto;
    max-height: 70dvh;
    border-radius: 10px;

    img {
      height: 100%;
      max-height: 70dvh;
      width: auto;
    }
  }

  .throne_decision {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    background-color: rgb(47, 55, 55);
    border-radius: 10px;
    span {
      font-size: 1rem;
      text-align: center;
      color: white;
      white-space: pre-wrap;
      cursor: default;
    }
  }

  .homes_wr {
    width: 100%;
    display: flex;
    justify-content: space-around;
    gap: 1rem;
    img {
      width: 3rem;
    }
  }

  .wildings_history {
    position: absolute;
    font-size: 2.5rem !important;
    bottom: 0;
    right: -2rem;
    color: rgb(202, 73, 73) !important;
    cursor: pointer !important;
    &:hover {
      color: white !important;
    }
  }

  .wilding_modal_img {
    margin: 10px 0px;
    width: 60px;
    cursor: not-allowed;
  }

  .hoverEnable {
    border-radius: 50%;
    box-sizing: border-box;
    outline: solid 3px rgb(240, 46, 29);
    outline-offset: -3px;
    cursor: pointer;
  }

  .btns {
    justify-content: space-between;
    display: flex;
    width: 100%;
  }
  .btn {
    color: white;
    font-weight: 900;
    background-color: black;
    border: 2px solid white;
    border-radius: 5px;
    padding: 5px;
    text-align: center;
    display: flex;

    justify-content: center;
    align-items: center;
    width: 40%;
    height: 30px;
    &:hover {
      cursor: pointer;
      background-color: rgb(70, 66, 66);
    }
    span {
      pointer-events: none;
    }
  }
</style>
