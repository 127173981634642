<template>
  <div class="buttons_container">
    <div class="btn_wr">
      <span class="material-icons side-btn" :class="{ active: game_side_bar.playersActive }" @click="openPlayersList">
        people
      </span>
    </div>
    <!-- <div class="btn_wr">
      <span class="material-icons side-btn" :class="{ active: game_side_bar.infoActive }" @click="openInfo">
        settings_accessibility
      </span>
    </div> -->
    <div class="btn_wr">
      <span class="material-icons side-btn" :class="{ active: game_side_bar.warActive }" @click="openWar">
        security
      </span>
    </div>
    <div v-show="home !== 'watcher'" class="btn_wr">
      <span
        class="material-icons side-btn"
        :class="{ active: game_side_bar.privateGameChatActive }"
        @click="openPrivateChat"
      >
        forum
      </span>
      <div v-show="total_unread_private_messages_count" class="unread_messages_label">
        {{ total_unread_private_messages_count }}
      </div>
    </div>

    <div class="btn_wr">
      <span class="material-icons side-btn" :class="{ active: game_side_bar.chatActive }" @click="openChatBar">
        insert_comment
      </span>
      <div v-show="total_unread_main_messages_count" class="unread_messages_label">
        {{ total_unread_main_messages_count }}
      </div>
    </div>

    <div class="btn_wr">
      <span class="material-icons side-btn" :class="{ hint_bid }" @click="processModal({ name: 'bid' })">
        payments
      </span>
    </div>

    <div class="btn_wr" v-if="isAdmin">
      <span
        class="material-icons side-btn"
        :class="{ active: game_side_bar.stateRedactorActive }"
        @click="openRedactor"
      >
        settings
      </span>
    </div>
    <div class="btn_wr">
      <span class="material-icons side-btn" @click="hideSideBar"> keyboard_double_arrow_right </span>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapMutations, mapState } from 'vuex'

  export default {
    inject: ['socket'],

    computed: {
      ...mapGetters('general_store', [
        'game_private_chats',
        'game',
        'isAdmin',
        'home',
        'phase_name',
        'active_players',
        'home',
        'user',
      ]),

      ...mapState('gui_store', ['game_side_bar']),
      ...mapState('general_store', ['modals']),

      total_unread_private_messages_count() {
        let sum = 0
        for (let chat of this.game_private_chats) {
          sum += chat.messages.reduce((acc, msg) => {
            if (!msg.read.some((arr) => arr[0] === this.user.id) && msg.sender_id !== this.user.id && !msg.temp) {
              acc++
            }
            return acc
          }, 0)
        }
        return sum
      },
      total_unread_main_messages_count() {
        if (!this.game) return 0
        return this.game.messages.reduce((sum, msg) => {
          if (!msg.read.some((arr) => arr[0] === this.user.id) && msg.sender_id !== this.user.id && !msg.temp)
            return ++sum
          return sum
        }, 0)
      },

      hint_bid() {
        return (
          ['WarOfKings', 'Wildings'].includes(this.phase_name) &&
          this.active_players.includes(this.home) &&
          !this.modals.bid
        )
      },
    },

    methods: {
      ...mapMutations('gui_store', ['setGameSideTab', 'setMainSideTab']),
      ...mapMutations('general_store', ['processModal']),
      openRedactor() {
        this.setGameSideTab({ tab: 'stateRedactorActive' })
      },

      openPlayersList() {
        this.setGameSideTab({ tab: 'playersActive' })
      },
      openWar() {
        this.setGameSideTab({ tab: 'warActive' })
      },
      openChatBar() {
        this.setGameSideTab({ tab: 'chatActive' })
      },
      hideSideBar() {
        this.setGameSideTab()
      },
      openInfo() {
        this.setGameSideTab({ tab: 'infoActive' })
      },
      openPrivateChat() {
        this.setGameSideTab({ tab: 'privateGameChatActive' })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .buttons_container {
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    width: auto;
    height: 100%;
    padding-top: $space_for_topbtns_and_logger;
    padding-bottom: env(safe-area-inset-bottom);
    transform: translateX(calc(-1 * env(safe-area-inset-left) - 2.1rem));
    background-color: #77243200;

    @media (min-aspect-ratio: 4/3) and (max-height: 400px) {
      padding-top: $space_for_topbtns_and_logger_shrinked;
      gap: 0.15rem;
    }
  }
  .btn_wr {
    position: relative;
    .side-btn {
      font-size: 1.8rem;
      border-radius: $theme_border_radius;
      cursor: pointer;
      background-color: rgba(161, 163, 163, 0.727);

      width: calc(env(safe-area-inset-right) + 1.8rem);
      &:hover {
        color: white;
      }

      @media screen and (max-height: 340px) {
        // width: 2rem;
        width: calc(env(safe-area-inset-right) + 2rem);
        font-size: 1.6rem;
      }

      &:hover {
        color: white;
      }
    }
    .unread_messages_label {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 1.2rem;
      height: 1.2rem;
      bottom: 0;
      left: 0;
      transform: translateX(-20%);
      border-radius: 50%;
      background-color: rgb(172, 0, 0);
      color: white;
      font-size: 0.9rem;
      pointer-events: none;
    }
  }

  .active {
    color: red;
    &:hover {
      color: rgb(255, 0, 0);
      background-color: rgb(48, 50, 50);
      border-radius: 7px;
    }
  }

  .hint_bid {
    background-color: rgb(84, 204, 32);
  }
</style>
