<template>
  <div class="modal_base" @click="close">
    <div class="modal_container">
      <div class="inner_wr">
        <img :src="card_src" :alt="card_title" />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapMutations, mapState } from 'vuex'

  export default {
    computed: {
      ...mapGetters('general_store', ['user']),
      ...mapState('general_store', ['data_for_modal']),
      locale() {
        return this.user.locale
      },
      card_title() {
        return this.data_for_modal.card
      },
      card_type() {
        return this.data_for_modal.type
      },
      card_src() {
        if (this.card_type === 'wildings') {
          return `/images/westeros_cards/wildings/${this.locale}/${this.card_title}.png`
        }
        return `/images/westeros_cards/${this.card_type}/${this.locale}/${this.card_title}.png`
      },
    },

    methods: {
      ...mapMutations('general_store', ['processModal', 'setDataForModal']),
      close() {
        this.processModal({ name: 'card_zoom', type: 'close' })
        this.setDataForModal({ reset: true })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .modal_base {
    position: relative;
    pointer-events: all;
    width: 100dvw;
    height: 100dvh;
    background-color: rgba(2, 2, 2, 0.312);
    z-index: 2000;
    top: 0;
    left: 0;
  }

  .modal_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 50%;
    padding: 1rem;
    box-sizing: border-box;
    transform: translate(-50%, -50%);
    // width: auto;
    // height: auto;
    max-height: $modals_max_height;
    width: 90dvw;
    align-items: center;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

    border-radius: 0.4rem;
    background-color: $modals_color;
    -webkit-box-shadow: -1px -4px 97px 31px rgb(147, 54, 54);
    -moz-box-shadow: -1px -4px 97px 31px rgb(147, 54, 54);
    box-shadow: -1px -4px 97px 31px rgb(147, 54, 54);

    @media (min-width: 550px) {
      width: auto;
    }

    .inner_wr {
      position: relative;
      width: 100%;
      box-sizing: border-box;

      img {
        width: 100%;
        display: block;
        height: auto;
      }
    }

    // img {
    //   width: 100%;
    // }
  }
</style>
