<template>
  <div id="modal_base" class="modal_base" @click="close">
    <div class="inner_wr">
      <div class="error_title">
        {{ $t(`message.error`) }}
      </div>
      <div v-if="error?.code" class="error_msg">
        {{ prepareErrorData(error) }}
      </div>
      <div v-if="error?.message">
        {{ error.message }}
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapMutations, mapState } from 'vuex'

  export default {
    computed: {
      ...mapState('general_store', ['users', 'error']),
      ...mapGetters('general_store', ['user', 'game']),
    },

    methods: {
      ...mapMutations('general_store', ['processModal', 'showError']),

      close(e) {
        if (e.target.id !== 'modal_base') return
        this.processModal({ name: 'error', type: 'close' })
        this.showError({ error: false })
      },

      prepareErrorData(error = {}) {
        const { code, data = {} } = error
        const cloned_data = { ...data }

        if (code === 215) {
          if (cloned_data?.conflict_land) {
            cloned_data.conflict_land = this.$t(`land.${cloned_data.conflict_land}`)
          }
        }
        return this.$t(`error.${code}`, cloned_data)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .modal_base {
    position: fixed;
    width: 100dvw;
    height: 100dvh;
    background-color: $modals_background_color;
    z-index: 2220;
    top: 0;
    pointer-events: all;
  }

  .inner_wr {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 50%;
    padding: 2rem;
    transform: translate(-50%, -50%);
    color: white;
    box-sizing: border-box;
    width: fit-content;
    max-width: $modals_max_width;
    // height: 200px;
    height: fit-content;
    max-height: $modals_max_height;
    align-items: center;
    overflow-y: auto;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    border-radius: 0.4rem;
    background-color: $modals_color;
    -webkit-box-shadow: -1px -4px 97px 31px rgb(221, 18, 18);
    -moz-box-shadow: -1px -4px 97px 31px rgb(221, 18, 18);
    box-shadow: -1px -4px 97px 31px rgb(221, 18, 18);
    scrollbar-width: none;

    ::-webkit-scrollbar {
      display: none;
    }

    /* Optional: Hide scrollbar for other browsers */
  }

  .green_lite {
    -webkit-box-shadow: -1px -4px 97px 31px rgb(69, 192, 53);
    -moz-box-shadow: -1px -4px 97px 31px rgb(69, 192, 53);
    box-shadow: -1px -4px 97px 31px rgb(69, 192, 53);
  }

  .error_title {
    font-size: 2rem;
    color: red;
  }
  .error_msg {
    text-align: center;
    user-select: text;
    font-size: 1.2rem;
  }
</style>
