<template>
  <div class="buttons_container">
    <div class="btn_wr">
      <span class="material-icons side-btn" @click="processModal({ name: 'game_info' })"> info </span>
    </div>
    <div v-if="!inside_room && (!user?.current_games || user?.current_games?.length < 2)" class="btn_wr">
      <span class="material-icons side-btn" @click="processModal({ name: 'create_game' })"> add_box </span>
    </div>
    <div class="btn_wr">
      <span class="material-icons side-btn" :class="{ active: main_side_bar.usersActive }" @click="usersActiveOpen">
        people
      </span>
    </div>
    <div class="btn_wr">
      <div v-show="total_unread_private_messages_count" class="unread_messages_label">
        {{ total_unread_private_messages_count }}
      </div>
      <span
        class="material-icons side-btn"
        :class="{ active: main_side_bar.privateChatActive }"
        @click="privatChatOpen"
      >
        forum
      </span>
    </div>
    <div class="btn_wr">
      <span class="material-icons side-btn" :class="{ active: main_side_bar.chatActive }" @click="chatActiveOpen">
        chat
      </span>
      <div v-show="total_unread_main_messages_count" class="unread_messages_label">
        {{ total_unread_main_messages_count }}
      </div>
    </div>
    <div class="btn_wr">
      <span class="material-icons side-btn" @click="hideSideBar"> keyboard_double_arrow_left </span>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapMutations, mapState } from 'vuex'
  export default {
    computed: {
      ...mapGetters('general_store', ['mainRoom', 'user', 'main_private_chats', 'inside_room']),
      ...mapState('gui_store', ['main_side_bar']),

      total_unread_private_messages_count() {
        let sum = 0
        for (let chat of this.main_private_chats) {
          sum += chat.messages.reduce((acc, msg) => {
            if (!msg.read.some((arr) => arr[0] === this.user?.id) && msg.sender_id !== this.user?.id && !msg.temp) {
              acc++
            }
            return acc
          }, 0)
        }
        return sum
      },

      total_unread_main_messages_count() {
        if (!this.mainRoom) return 0
        return this.mainRoom.messages.reduce((sum, msg) => {
          if (!msg.read.some((arr) => arr[0] === this.user?.id) && msg.sender_id !== this.user?.id && !msg.temp)
            return ++sum
          return sum
        }, 0)
      },
    },

    methods: {
      ...mapMutations('gui_store', ['setMainSideTab']),
      ...mapMutations('general_store', ['processModal']),

      privatChatOpen() {
        this.setMainSideTab({ tab: 'privateChatActive' })
      },
      chatActiveOpen() {
        this.setMainSideTab({ tab: 'chatActive' })
      },
      usersActiveOpen() {
        this.setMainSideTab({ tab: 'usersActive' })
      },
      redactorActiveOpen() {
        this.setMainSideTab({ tab: 'redactorActive' })
      },
      hideSideBar() {
        this.setMainSideTab()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .buttons_container {
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    width: auto;
    height: 100%;
    padding-top: $space_for_topbtns_and_logger;
    padding-bottom: env(safe-area-inset-bottom);
    transform: translateX(calc(env(safe-area-inset-left) + 2.1rem));
    background-color: #77243200;

    @media (min-aspect-ratio: 4/3) and (max-height: 400px) {
      padding-top: $space_for_topbtns_and_logger_shrinked;
      gap: 0.2rem;
    }
  }
  .btn_wr {
    position: relative;
    .side-btn {
      font-size: 1.8rem;
      border-radius: $theme_border_radius;
      cursor: pointer;
      background-color: rgba(161, 163, 163, 0.727);
      direction: rtl;
      width: calc(env(safe-area-inset-left) + 1.8rem);
      &:hover {
        color: white;
      }

      @media screen and (max-height: 340px) {
        width: calc(env(safe-area-inset-left) + 2rem);
        font-size: 1.6rem;
      }
    }
    .unread_messages_label {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 1.2rem;
      height: 1.2rem;
      bottom: 0;
      right: 0;
      transform: translateX(20%);
      border-radius: 50%;
      background-color: rgb(172, 0, 0);
      color: white;
      font-size: 0.9rem;
      pointer-events: none;
    }
  }

  .active {
    color: red;
    &:hover {
      color: rgb(255, 0, 0);
      background-color: rgb(48, 50, 50);
      border-radius: 7px;
    }
  }
</style>
