<template>
  <div class="message_wr">
    <div class="inner_message_wr">
      <div
        v-if="!message.hide && message?.reply?.id"
        class="message_reply_wr"
        :class="{
          right: isMyMessage,
        }"
        @click="scrollToMessage(message.reply.id)"
      >
        <div class="message_reply">
          <div class="reply_title">
            <span class="material-icons reply"> reply </span>
            <span class="sender_name_reply">{{ getSenderTitle(message) }}</span>
            <span class="sender_date_reply">{{ getDate(message.reply.time) }}</span>
          </div>
          <div class="reply_body">
            <span>{{ normalizeReplyText(message.reply.text) }}</span>
          </div>
        </div>
      </div>

      <div
        :class="{
          right: isMyMessage,
          left: !isMyMessage,
        }"
      >
        <div v-if="message.hide" :ref="`${message.id}_del`" class="deleted_message_body">
          <span>{{ getMessageName(message) + ` ${$t(`message.removed_message`)}` }}</span>
        </div>
        <div
          v-else
          :ref="`${message.id}`"
          class="message_body"
          :class="{ [`${message.sender_home}_body`]: tabType.includes('game') }"
        >
          <div class="message_title">
            <div class="ava_wr">
              <img
                :src="getSrc(message)"
                alt="avatar"
                @pointerenter="hint_label_hover($event, { msg: 'hint_more' })"
                @click="showUserInfoModal($event, message.sender_id)"
                @pointerleave="resetHint"
                :class="{ offline: !onlineStatus }"
              />
              <span
                v-if="getAdminType(message.sender_id) === 'admin'"
                class="admin_hint material-icons"
                @pointerenter="hint_label_hover($event, { msg: 'hint_admin' })"
                @pointerleave="resetHint"
              >
                stars
              </span>
              <span
                v-if="getAdminType(message.sender_id) === 'admin_helper'"
                class="admin_hint material-icons"
                @pointerenter="hint_label_hover($event, { msg: 'hint_admin_helper' })"
                @pointerleave="resetHint"
              >
                military_tech
              </span>
            </div>
            <div class="name_wr">
              <span
                class="name_sender"
                @pointerenter="hint_label_hover($event, { msg: 'hint_more' })"
                @pointerleave="resetHint"
                @click="showUserInfoModal($event, message.sender_id)"
                >{{ getMessageName(message) }}</span
              >
            </div>
          </div>

          <div v-if="message.temp" class="dot_temp">
            <div class="typing">
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
            </div>
          </div>

          <div v-else class="text_msg">
            <span class="text">{{ message.text }}</span>

            <div class="time_msg_wr">
              <span
                v-if="showPrivateChatBtn(message)"
                class="private_chat_button material-icons"
                @click="openPrivateChatTab(message)"
                @pointerenter="hint_label_hover($event, { msg: 'hint_send_private_message' })"
                @pointerleave="resetHint"
              >
                mail
              </span>
              <span class="time_msg">{{ getDate(message.time) }}</span>
              <div v-if="tabType.includes('private')" class="private_watch_wrapper">
                <span v-show="isMyMessage && messageHaveBeenRead" class="material-icons"> done_all </span>
                <span v-if="showReaction"> {{ reactionOfMessage }} </span>
              </div>

              <div v-if="showReactionButton" class="reaction_wr" @click="emojiiPopupOpen($event, null, message)">
                <span class="material-icons"> {{ myReaction }} </span>
              </div>
            </div>
          </div>
        </div>

        <div class="reply_icon_wr">
          <span
            v-if="!isMyMessage && !message.hide && !message.temp"
            class="material-icons reply"
            @click="reply(message)"
          >
            reply
          </span>
          <span
            v-if="!isMyMessage && !message.hide && !message.temp"
            class="material-icons"
            @click="openConfirmComplaintModal(message)"
            @pointerenter="hint_label_hover($event, { msg: 'hint_complaint' })"
            @pointerleave="resetHint"
          >
            report
          </span>
          <div class="delete_icon_wr">
            <span
              v-if="!message.hide && (user.role?.value.includes('admin') || isMyMessage) && !message.temp"
              class="material-icons reply"
              @click="deleteMessage(message)"
            >
              delete
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapMutations, mapState } from 'vuex'
  export default {
    inject: ['socket'],

    props: {
      message: {
        type: Object,
      },

      tabType: String,
    },
    emits: ['reply', 'scrollToMessage', 'setReaction', 'openConfirmComplaintModal', 'openPrivateTab'],

    data() {
      return {
        hint_delay: 250,
        clear_hint_delay: null,
        hint_timer_delay: null,
      }
    },

    computed: {
      ...mapState('general_store', ['selected_game_private_chat', 'selected_main_private_chat', 'users']),
      ...mapGetters('general_store', ['mainRoom', 'game', 'home', 'user']),

      // ...mapGetters('gui_store', ['currentEmojiiReaction']),
      ...mapState({
        users_players(state) {
          if (this.tabType.includes('game')) {
            return this.game.players.concat(this.game.users)
          }
          return state?.general_store.users
        },
      }),
      user_indicator() {
        if (!this.tabType.includes('game') || !this.message.sender_home) return this.user.id
        return this.home
      },
      message_key() {
        const tabs = ['main_private_chat', 'main_chat']
        if (tabs.includes(this.tabType) || !this.message.sender_home) {
          return 'sender_id'
        }

        return 'sender_home'
      },

      isMyMessage() {
        return this.message[this.message_key] === this.user_indicator
      },

      selected_chat_id() {
        return this.message.room_id === 'mainRoom'
          ? this.selected_main_private_chat?.chat_id
          : this.selected_game_private_chat?.chat_id
      },
      showReaction() {
        if (this.message.temp) return
        if (!this.tabType.includes('private')) return false
        if (!this.isMyMessage) return false
        return true
      },
      showReactionButton() {
        if (this.message.temp) return
        if (!this.tabType.includes('private')) return false

        if (this.isMyMessage) return false
        return true
      },
      myReaction() {
        const my_cand = this.message.read.find((arr) => arr[0] === this.user.id)
        return my_cand?.[1] || 'sentiment_satisfied_alt'
      },
      messageHaveBeenRead() {
        const cand = this.message.read.find((arr) => arr[0] !== this.user.id)
        return cand
      },
      reactionOfMessage() {
        // if (message[this.message_key] !== this.user_indicator) return;
        const my_cand = this.message.read.find((arr) => arr[0] !== this.message.sender_id)
        return my_cand?.[1]
      },

      onlineStatus() {
        const user_indent = this.message[this.message_key]
        const key = this.tabType.includes('game') && this.message.sender_home ? 'home' : 'id'
        const arr = this.tabType.includes('game') ? this.users_players : this.users
        const user = arr.find((u) => u[key] === user_indent)
        return user?.online
      },
    },

    methods: {
      ...mapMutations('gui_store', ['setHintLabel']),
      ...mapMutations('general_store', ['setDataForModal', 'processModal', 'showError']),

      async avatarError(e) {
        e.target.src = 'https://robohash.org/193.93.15.48754852.png?set=set4'
      },

      showPrivateChatBtn(message) {
        if (this.tabType.includes('private')) return false
        if (!this.tabType.includes('game') && !this.isMyMessage) return true
        if (this.tabType.includes('game') && !this.isMyMessage && this.home) {
          const user_is_in_game = this.game.players.some((pl) => pl.id === message.sender_id)
          return user_is_in_game
        }
        return false
      },

      getSenderTitle(message) {
        if (this.tabType.includes('game')) {
          return this.$t(`home.${message.reply[this.message_key]}`)
        }
        return message.reply.sender_name
      },

      resetHint() {
        clearTimeout(this.hint_timer_delay)
        clearTimeout(this.clear_hint_delay)

        this.clear_hint_delay = null
        this.hint_timer_delay = null
        this.setHintLabel({ reset: true })
      },
      hint_label_hover(e, item) {
        if (e.pointerType !== 'mouse') return
        if (!item) return
        this.resetHint()
        if (this.tabType.includes('game')) return

        this.hint_timer_delay = setTimeout(() => {
          const coords = e.target.getBoundingClientRect()
          const logYpos = `translate(${coords.left}px, ${coords.top + 30}px)`
          this.setHintLabel({
            msg: item.msg,
            coords: logYpos,
          })

          this.clear_hint_delay = setTimeout(() => {
            this.resetHint()
          }, 2000)
        }, this.hint_delay)
      },

      showUserInfoModal(e, user_id) {
        this.resetHint()
        if (this.tabType.includes('game')) return
        const user = this.users.find((u) => u.id === user_id)
        const data = {
          processed_user: user,
        }
        this.setDataForModal({ data })
        this.processModal({ name: 'user_info' })
      },

      openConfirmComplaintModal(message) {
        this.resetHint()
        if (message.sender_id === this.user.id) return
        const user = this.users.find((u) => u.id === message.sender_id)
        if (!user) {
          this.showError({
            error: {
              code: '003',
              data: {
                user_id: message.sender_name,
              },
            },
          })
          return
        }
        const data = {
          processed_user: {
            avatars: user.avatars,
            nickname: user.name,
            id: user.id,
            type: this.tabType.includes('private') ? 'private_chat' : 'chat',
            complaint_data: {
              text: message.text,
            },
          },
        }
        this.setDataForModal({ data })
        this.processModal({ name: 'complaint' })
      },

      openPrivateChatTab(message) {
        this.resetHint()

        if (!this.tabType.includes('game')) {
          const user_id = message.sender_id
          if (!user_id) return
          if (user_id === this.user.id) return
          const user = this.users.find((u) => u.id === user_id)
          if (!user) {
            this.showError({
              error: {
                code: '003',
                data: {
                  user_id: message.sender_name,
                },
              },
            })
            return
          }
          this.$emit('openPrivateTab', user_id)
        } else {
          const user_id = message.sender_home

          if (!user_id) return
          if (user_id === this.home) return
          const user = this.game.players.find((u) => u.home === user_id)
          if (!user) {
            this.showError({
              error: {
                code: '003',
                data: {
                  user_id: message.sender_home,
                },
              },
            })
            return
          }
          this.$emit('openPrivateTab', user_id)
        }
      },

      getAdminType(uid) {
        const u = this.users.find((u) => u.id === uid)
        if (!u?.role.value.includes('admin')) return false
        return u?.role.value
      },

      async emojiiPopupOpen(e, user_id, message) {
        this.resetHint()
        if (this.home === 'watcher') return
        if (user_id && user_id !== this.user_indicator) return
        this.setDataForModal({
          data: {
            type: 'reaction',
            cb: function (emoji) {
              this.setReaction({ message, emoji })
            }.bind(this),
          },
        })

        this.processModal({ name: 'emojii' })
      },

      setReaction({ message, emoji }) {
        const existed_reaction = message.read.find((arr) => arr[0] === this.user.id)?.[1]
        if (existed_reaction === emoji) return

        this.socket.emit('client_action', {
          action_type: 'SetReaction',
          data: {
            room_id: this.message.room_id,
            chat_id: this.selected_chat_id,
            user_id: this.user.id,
            emoji,
            type: this.tabType,
            message,
          },
        })
      },

      scrollToMessage(reply_id) {
        this.$emit('scrollToMessage', reply_id)
      },
      normalizeReplyText(text) {
        return text.slice(0, 20) + '...'
      },

      reply(message) {
        this.$emit('reply', message)
      },

      temp_message_hint() {
        let msg_body = this.$refs[this.message.id]
        if (!msg_body) {
          msg_body = this.$refs[`${this.message.id}_del`]
        }
        if (!msg_body) return
        msg_body.classList.add('temp_message_hint')
        setTimeout(() => {
          msg_body?.classList?.remove('temp_message_hint')
        }, 1000)
      },

      getSrc(message) {
        const key = this.tabType.includes('game') && message.sender_home ? 'home' : 'id'
        const user = this.users.find((u) => u.id === message.sender_id)
        if (key === 'id' || message.sender_home === 'watcher') {
          return `/images/avatars/${user?.avatars[0]}`
        }
        return `/images/tokens/${message.sender_home}_token_new.png`
      },

      getMessageName(message) {
        const arr = ['baratheon', 'stark', 'lannister', 'tyrell', 'martell', 'greyjoy']
        if (!arr.includes(message.sender_home)) {
          return message.sender_name
        }
        if (this.tabType.includes('game')) {
          return this.$t(`home.${message.sender_home}`)
        }
        return message.sender_name
      },

      getDate(ms) {
        return new Date(ms).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
      },

      async deleteMessage(message) {
        this.resetHint()
        const { id, room_id, sender_id, target_id } = message

        this.setDataForModal({
          data: {
            cb: function (result) {
              if (!result) return
              this.socket.emit('client_action', {
                action_type: 'DeleteMessage',
                data: {
                  message_id: id,
                  room_id,
                  sender_id,
                  target_id,
                  chat_type: this.tabType,
                  chat_id: this.selected_chat_id,
                  type: 'del_one',
                },
              })
            }.bind(this),
            type: 'delete_message',
          },
        })
        this.processModal({ name: 'confirm_del' })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .message_wr {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    .inner_message_wr {
      display: flex;
      flex-direction: column;
      width: 100%;

      .right {
        width: 90%;
        display: flex;
        flex-direction: row-reverse;
        align-self: flex-end;
        .message_body {
          background-color: #add6ed;
          border-start-start-radius: $theme_border_radius $theme_border_radius;
          border-end-start-radius: $theme_border_radius $theme_border_radius;
          border-start-end-radius: unset;
          border-end-end-radius: unset;
          box-sizing: border-box;
          padding: 5px;
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 0.3rem;
          // background-color: rgb(235, 225, 183);
          transition: background-color 0.6s ease-in;
          // direction: rtl;
          // flex-direction: row-reverse;
          .message_title {
            height: 100%;
            display: flex;
            align-items: center;
            gap: 0.2rem;
            .name_wr {
              display: flex;
              flex-direction: column;
              flex: 1;
              justify-content: space-between;
              align-items: center;

              .name_sender {
                font-size: 1.2rem;
                font-weight: 900;
                width: 100%;
                cursor: pointer;
                text-align: start;
              }
            }
            .ava_wr {
              position: relative;
              display: flex;
              justify-content: flex-start;
              width: 30%;
              .admin_hint {
                position: absolute;
                font-size: 1rem;
                right: 5px;
                top: 0;
                text-align: center;
                color: rgb(223, 101, 8);
                cursor: pointer;
              }

              @media (max-width: 400px) {
                .admin_hint {
                  right: -5px;
                }
              }
              img {
                touch-action: none;
                cursor: pointer;
                background-color: rgba(38, 204, 29, 0.75);
                height: 40px;
                border-radius: $theme_border_radius;
                -webkit-box-shadow: 0px 0px 8px 6px rgba(38, 204, 29, 0.75);
                -moz-box-shadow: 0px 0px 8px 6px rgba(38, 204, 29, 0.75);
                box-shadow: 0px 0px 8px 6px rgba(38, 204, 29, 0.75);
              }
              .offline {
                background-color: rgba(250, 10, 30, 0.79);
                -webkit-box-shadow: 0px 0px 8px 6px rgba(250, 10, 30, 0.79);
                -moz-box-shadow: 0px 0px 8px 6px rgba(250, 10, 30, 0.79);
                box-shadow: 0px 0px 8px 6px rgba(250, 10, 30, 0.79);
              }
            }
          }
          .text_msg {
            display: flex;
            flex-direction: column;
            width: 100%;
            word-break: break-word;

            .text {
              text-align: right;
              font-size: 1rem;
              padding: 0.4rem;
            }

            .time_msg_wr {
              display: flex;
              justify-content: flex-end;
              align-items: flex-end;
              width: 100%;
              gap: 0.5rem;

              .time_msg {
                font-size: 0.7rem;
              }

              .reaction_wr {
                span {
                  cursor: pointer;
                  font-size: 1rem !important;
                }
              }

              .private_watch_wrapper {
                display: flex;
                align-items: flex-end;
                span {
                  font-size: 1rem;
                }
              }
            }
          }
          .message_reply {
            border-start-end-radius: unset;
            border-start-start-radius: $theme_border_radius $theme_border_radius;
          }
        }

        .deleted_message_body {
          text-align: end;
          margin-right: 10px;
        }
      }

      .left {
        width: 90%;
        display: flex;

        .message_body {
          box-sizing: border-box;
          padding: 5px;
          border-start-end-radius: $theme_border_radius $theme_border_radius;
          border-end-end-radius: $theme_border_radius $theme_border_radius;
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 0.3rem;
          background-color: rgb(235, 225, 183);
          transition: background-color 0.6s ease-in;
          .message_title {
            height: 100%;
            display: flex;
            align-items: center;
            gap: 0.2rem;

            .name_wr {
              display: flex;
              flex-direction: column;
              flex: 1;
              justify-content: space-between;
              align-items: center;

              .name_sender {
                font-size: 1.2rem;
                font-weight: 900;
                width: 100%;
                cursor: pointer;
                text-align: start;
              }
            }

            .ava_wr {
              position: relative;
              display: flex;
              justify-content: flex-start;
              width: 30%;
              .admin_hint {
                position: absolute;
                font-size: 1rem;
                right: 5px;
                top: 0;
                text-align: center;
                color: rgb(223, 101, 8);
                cursor: pointer;
              }

              @media (max-width: 400px) {
                .admin_hint {
                  right: -5px;
                }
              }
              img {
                touch-action: none;
                cursor: pointer;
                background-color: rgba(38, 204, 29, 0.75);
                height: 40px;
                border-radius: $theme_border_radius;
                -webkit-box-shadow: 0px 0px 8px 6px rgba(38, 204, 29, 0.75);
                -moz-box-shadow: 0px 0px 8px 6px rgba(38, 204, 29, 0.75);
                box-shadow: 0px 0px 8px 6px rgba(38, 204, 29, 0.75);
              }
              .offline {
                background-color: rgba(250, 10, 30, 0.79);
                -webkit-box-shadow: 0px 0px 8px 6px rgba(250, 10, 30, 0.79);
                -moz-box-shadow: 0px 0px 8px 6px rgba(250, 10, 30, 0.79);
                box-shadow: 0px 0px 8px 6px rgba(250, 10, 30, 0.79);
              }
            }
          }

          .text_msg {
            display: flex;
            flex-direction: column;
            align-content: center;
            width: 100%;

            // white-space: pre-wrap;
            word-break: break-word;

            .time_msg_wr {
              justify-content: flex-end;
              flex-direction: row-reverse;
              width: 100%;
              display: flex;
              gap: 0.5rem;

              .time_msg {
                align-self: flex-end;
                margin-right: 5px;
                font-size: 0.7rem;
              }

              .reaction_wr {
                span {
                  cursor: pointer;
                  font-size: 1rem;
                }
              }
            }

            .private_chat_button {
              font-size: 1rem;
              cursor: pointer;
            }

            .text {
              padding: 0.4rem;
              font-size: 1rem;
              text-align: left;
              white-space: pre-wrap;
              word-wrap: break-word;
              // max-width: 200px;
            }
          }
        }
      }
      .message_reply_wr {
        cursor: pointer;
        width: 65%;
        .message_reply {
          box-sizing: border-box;
          padding: 5px;
          background-color: rgb(177, 153, 87);
          width: 100%;
          border-start-end-radius: $theme_border_radius $theme_border_radius;
          border-start-start-radius: $theme_border_radius $theme_border_radius;
          &:hover {
            background-color: rgb(147, 121, 47);
          }
          .reply_title {
            border-bottom: 1px solid black;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .reply_body {
            text-align: center;
          }
          .reply {
            font-size: 1rem;
          }
          .sender_date_reply {
            font-size: 0.8rem;
          }
        }
      }
    }
  }

  .deleted_message_body {
    width: 100%;
    height: auto;
    // background-color: grey;
    color: rgb(177, 177, 177);
    font-style: italic;
    font-size: 0.9rem;
    margin-left: 15px;
  }

  .reply_icon_wr {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    // gap: 0.4rem;
    span {
      cursor: pointer;
      font-size: 1.2rem;
      color: white;
      &:hover {
        color: red;
      }
    }
  }

  .delete_icon_wr {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // width: fit-content;
    span {
      cursor: pointer;
      font-size: 1rem;
      color: white;
      &:hover {
        color: red;
      }
    }
  }

  .lannister_body {
    background-color: #b82020 !important;
    .name_wr {
      border-bottom: 1px solid #750707;
      padding-bottom: 5px;
    }
  }
  .stark_body {
    background-color: rgb(226, 225, 225) !important;
    .name_wr {
      border-bottom: 1px solid rgb(74, 73, 73);
      padding-bottom: 5px;
    }
  }
  .baratheon_body {
    background-color: rgb(241, 217, 39) !important;
    .name_wr {
      border-bottom: 1px solid rgb(74, 73, 73);
      padding-bottom: 5px;
    }
  }
  .martell_body {
    background-color: rgb(203, 151, 39) !important;
    .name_wr {
      border-bottom: 1px solid rgb(74, 73, 73);
      padding-bottom: 5px;
    }
  }
  .tyrell_body {
    background-color: rgb(26, 159, 77) !important;
    .name_wr {
      border-bottom: 1px solid rgb(74, 73, 73);
      padding-bottom: 5px;
    }
  }
  .greyjoy_body {
    background-color: rgb(109, 123, 114) !important;
    // color: rgb(237, 234, 234);
    .name_wr {
      border-bottom: 1px solid rgb(74, 73, 73);
      padding-bottom: 5px;
    }
  }

  .temp_message_hint {
    background-color: rgba(168, 16, 16, 0.94) !important;
  }

  //===============================================================

  .dot_temp {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 2rem;
    align-items: center;
  }

  .temp_msg_name {
    span {
      font-weight: 700;
    }
  }

  .typing {
    align-items: center;
    display: flex;
    height: 17px;
  }
  .typing .dot {
    animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
    background-color: #000000; //rgba(20,105,69,.7);
    border-radius: 50%;
    height: 7px;
    margin-right: 4px;
    vertical-align: middle;
    width: 7px;
    display: inline-block;
  }
  .typing .dot:nth-child(1) {
    animation-delay: 200ms;
  }
  .typing .dot:nth-child(2) {
    animation-delay: 300ms;
  }
  .typing .dot:nth-child(3) {
    animation-delay: 400ms;
  }
  .typing .dot:last-child {
    margin-right: 0;
  }

  @keyframes mercuryTypingAnimation {
    0% {
      transform: translateY(0px);
      background-color: #ce0c59; // rgba(20,105,69,.7);
    }
    28% {
      transform: translateY(-7px);
      background-color: #c665b1; //rgba(20,105,69,.4);
    }
    44% {
      transform: translateY(0px);
      background-color: #a27cc5; //rgba(20,105,69,.2);
    }
  }
</style>
