<template>
  <div class="modal_base" data-id="modal_base" @click="close">
    <div class="emoji_inner_wr">
      <div class="recent_emoji_wr">
        <div v-for="emoji in recent_emoji_list" :key="emoji" class="emoji_wr" @click="selectEmoji(emoji)">
          <span class="emoji">{{ emoji }}</span>
        </div>
      </div>
      <div class="emoji_list_wr">
        <div v-for="emoji in emoji_list" :key="emoji" class="emoji_wr" @click="selectEmoji(emoji)">
          <span class="emoji">{{ emoji }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapState, mapGetters, mapMutations } from 'vuex'

  export default {
    computed: {
      ...mapState('general_store', ['data_for_modal', 'rooms']),
      ...mapGetters('general_store', ['mainRoom', 'user']),

      emoji_list() {
        return this.mainRoom?.emoji_list
      },
      recent_emoji_list() {
        return this.user.recent_emoji
      },
    },

    methods: {
      ...mapMutations('general_store', ['processModal', 'setDataForModal']),
      selectEmoji(emojii) {
        this.data_for_modal.cb(emojii)
        if (this.data_for_modal?.type === 'reaction') {
          this.processModal({ name: 'emojii', type: 'close' })
          this.setDataForModal({ reset: true })
        }
      },

      close(e) {
        if (e.target.dataset?.id !== 'modal_base') return
        this.processModal({ name: 'emojii', type: 'close' })
        this.setDataForModal({ reset: true })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .modal_base {
    position: fixed;
    pointer-events: all;
    width: 100dvw;
    height: 100dvh;
    background-color: rgba(2, 2, 2, 0.389);
    z-index: 300;
    top: 0;
    left: 0;
  }

  .reverse_top_pos {
    top: -120px !important;
  }

  .emoji_inner_wr {
    box-sizing: border-box;
    position: absolute;
    display: flex;
    flex-direction: column;
    border: 3px solid rgb(250, 250, 250);
    border-radius: 0.5rem;
    background-color: rgb(81, 81, 81);
    padding: 1rem 0.4rem;
    width: 30%;
    height: 40%;
    max-height: 80dvh;
    max-width: 80dvw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    gap: 1rem;
    overflow-y: auto;
  }
  @media (max-width: 400px) {
    .emoji_inner_wr {
      width: 70%;
      height: 40%;
    }
  }
  @media (max-width: 700px) {
    .emoji_inner_wr {
      width: 60%;
      height: 60%;
    }
  }
  .emoji_list_wr {
    display: flex;
    flex-wrap: wrap;
  }

  .recent_emoji_wr {
    display: flex;
    flex-wrap: wrap;
    padding: 0.5rem 0;
    border-bottom: 1px solid red;
    margin-right: 10px;
  }

  .emoji_wr {
    &:hover {
      transform: scale(1.2);
    }
  }
  .emoji {
    cursor: pointer;
  }

  .close_btn {
    cursor: pointer;
    border-radius: 10px;
    color: red;
    // background-color: red;
  }

  .emoji_inner_wr::-webkit-scrollbar {
    width: 1rem;
  }

  .emoji_inner_wr::-webkit-scrollbar-track {
    background: grey;
    border-left: 0.45rem solid $theme_background;
    border-right: 0.45rem solid $theme_background; /* color of the tracking area */
  }

  .emoji_inner_wr::-webkit-scrollbar-thumb {
    background: grey; /* color of the scroll thumb */
    border-radius: 0.6rem; /* roundness of the scroll thumb */

    border-left: 0.3rem solid $theme_background;
    border-right: 0.3rem solid $theme_background;
  }
</style>
