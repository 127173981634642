<template>
  <div class="chat_container">
    <div
      class="chat_area_wr"
      :class="{ paddingTopForLobby: !inside_room }"
      :style="{
        paddingRight: tabType === 'game_chat' ? 'env(safe-area-inset-right)' : '',
        paddingLeft: tabType === 'main_chat' ? 'env(safe-area-inset-left)' : '',
      }"
    >
      <div
        class="chat_area_scroll_container"
        ref="chat_area"
        @scroll="onScrollArea"
        :class="{ reverse_scroll: room?.id !== 'mainRoom' }"
      >
        <div class="wraper_for_reverse_content_back">
          <transition-group name="messages">
            <MessageCard
              v-for="(msg, index) in messages"
              :key="index"
              :ref="msg.id"
              :tab-type="tabType"
              :message="msg"
              :user="user"
              @reply="reply"
              @scroll-to-message="scrollToMessage"
              @open-confirm-complaint-modal="openConfirmComplaintModal(msg)"
              @open-private-tab="openPrivateChatTab"
            />
          </transition-group>
        </div>
      </div>
      <div class="form_wr">
        <div v-if="reply_obj" class="reply_wr">
          <div class="reply_title">
            <span class="material-icons reply_reset" @click="resetReply"> cancel </span>
            <span class="material-icons"> reply </span>
            <span>{{ room.id !== 'mainRoom' ? $t(`home.${reply_obj.sender_home}`) : reply_obj.sender_id }}</span>
            <span>{{ getDate(reply_obj.time) }}</span>
          </div>
          <div class="reply_body">
            <span>{{ normalizeReplyText(reply_obj.text) }}</span>
          </div>
        </div>
        <div class="input_wr" :class="{ row_reverse: room?.id !== 'mainRoom' }">
          <textarea
            id="message"
            ref="textarea"
            v-model="message"
            name="message"
            cols="30"
            rows="1"
            maxlength="120"
            @input="auto_grow($event)"
            @keydown="sendMessage"
            @blur="blur_handler"
          ></textarea>

          <span class="material-icons open_emoji_button" @click="emojiPopupOpen"> sentiment_satisfied_alt </span>
          <span
            class="material-icons send_button"
            :class="{ rotate_icon: room?.id !== 'mainRoom' }"
            @click="sendMessage"
          >
            send
          </span>
          <span
            v-if="user?.role?.value.includes('admin')"
            class="material-icons clean_main_chat"
            :class="{ reverse_cleaner: room?.id !== 'mainRoom' }"
            @click="cleanChatHistory"
          >
            cleaning_services
          </span>
          <div v-if="unread_messages_count" class="new_messages_hint_wr" @click="setScrollToBottom">
            <div class="circle_wr">
              <span class="material-icons"> expand_circle_down </span>
            </div>
            <div class="msgs_count_hint">
              <span>{{ unread_messages_count }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import shortid from 'shortid'
  import { nextTick } from 'vue'
  import { mapGetters, mapMutations, mapState } from 'vuex'
  import { genPrivateChatId } from '../utils/utilsFrontend.js'

  export default {
    inject: ['socket'],
    props: {
      tabType: String,
      room: {
        type: Object,
        default: () => {
          return {}
        },
      },
    },

    data() {
      return {
        message: '',
        reply_obj: null,
        debounce_permit: true,
        temp_msg_timeout: null,
        permit_show_temp_msg: {},
        emojiis: new Set(),
      }
    },

    computed: {
      ...mapGetters('general_store', [
        'game',
        'user',
        'mainRoom',
        'show_game_map',
        'main_private_chats',
        'game_private_chats',
        'home',
        'inside_room',
      ]),

      ...mapState({
        chat_active(state) {
          if (this.tabType === 'main_chat') {
            return state.gui_store.main_side_bar.chatActive
          }
          return state.gui_store.game_side_bar.chatActive
        },
        users() {
          if (this.tabType === 'main_chat') {
            return state.general_store.users
          }
          return this.room.players.concat(this.room.users)
        },
      }),

      game_tab() {
        return this.tabType.includes('game')
      },

      messages() {
        return this.room.messages
      },
      unread_messages_count() {
        if (!this.messages || !this.messages.length) return 0
        return this.messages.reduce((sum, msg) => {
          if (
            !msg.read.some((arr) => arr[0] === this.user.id) &&
            msg[this.message.sender_id] !== this.user.id &&
            !msg.temp
          )
            return ++sum
          return sum
        }, 0)
      },
    },

    watch: {
      chat_active() {
        if (this.chat_active) {
          this.setScrollToBottom()
          this.dispatchScrollEvent()
        }
      },
    },

    mounted() {
      this.socket.on('update_from_server', async ({ process, payload } = {}) => {
        if (process !== 'processMessage') return

        const { message, type } = payload
        if (type !== 'add') return
        if (!this.chat_active) return
        const area = this.$refs.chat_area
        if (!area) return
        if (this.user.id === message?.sender_id) {
          await nextTick()
          this.setScrollToBottom()
          return
        }
        const bottom = Math.abs(area.scrollHeight - area.scrollTop - area.clientHeight) < 100
        if (!bottom) return
        await nextTick()
        this.setScrollToBottom()
        this.dispatchScrollEvent()
      })

      this.socket.on('setInputChatState', async (payload) => {
        if (!this.chat_active) return
        if (payload.chat_id) return
        if (payload.room_id !== this.room.id) return

        if (this.user.id === payload?.sender_id) return

        const area = this.$refs.chat_area
        if (!area) return

        const m_id = `message-${shortid.generate()}`

        const message = {
          id: m_id,
          time: Date.now(),
          room_id: this.room.id,
          sender_id: payload?.sender_id,
          sender_name: payload.sender_name,
          sender_avatar: payload?.sender_avatar,
          sender_home: payload?.sender_home,
          read: [],
          text: 'TEMP',
          temp: true,
        }

        if (this.temp_msg_timeout) {
          this.deleteTempMessage({ room_id: this.room.id })
          clearTimeout(this.temp_msg_timeout)
          this.temp_msg_timeout = null
        }

        this.processMessage({ message, room_id: this.room.id, type: 'add' })
        this.temp_msg_timeout = setTimeout(() => {
          this.processMessage({ message, room_id: this.room.id, type: 'del' })
          this.temp_msg_timeout = null
        }, 2500)
        await nextTick()
        const bottom = Math.abs(area.scrollHeight - area.scrollTop - area.clientHeight) < 100

        if (!bottom) return
        this.setScrollToBottom()
      })
    },

    methods: {
      ...mapMutations('general_store', [
        'processMessage',
        'selectPrivateChat',
        'setDataForModal',
        'processModal',
        'deleteTempMessage',
        'setTempPrivateChat',
      ]),
      ...mapMutations('gui_store', ['setMainSideTab', 'setGameSideTab']),

      sendMessage(e) {
        if (e.type == 'keydown') {
          if (e.keyCode !== 13) return
          if (e.shiftKey) {
            return
          }
          e.preventDefault()
        }
        const msg_clone = this.message
        if (!msg_clone.trim().length) return
        if (this.user.ban?.private_chat?.value) {
          this.socket.emit('client_action', {
            action_type: 'BanUser',
            data: {
              user_id: this.user.id,
              target_user_id: this.user.id,
              update: true,
            },
          })
          this.message = ''
          return
        }

        const reply = { ...this.reply_obj }

        this.socket.emit('client_action', {
          action_type: 'NewMessage',
          data: {
            message: {
              id: `message-${shortid.generate()}`,
              time: Date.now(),
              room_id: this.room.id,
              sender_id: this.user.id,
              sender_home: this.home,
              sender_name: this.user.name,
              reply,
              read: [[this.user.id]],
              hide: false,
              text: this.message,
              emojiis: Array.from(this.emojiis),
            },
          },
        })
        this.message = ''
        this.emojiis.clear()
        this.reply_obj = null
        this.$refs.textarea?.blur()
      },

      auto_grow(e) {
        e.target.style.height = '1rem'
        e.target.style.height = e.target.scrollHeight + 'px'
        if (!this.debounce_permit) return
        this.socket.emit('chat_action', {
          data: {
            action_type: 'ProcessChatInput',
            room_id: this.room.id,
            type: 'main_chat',
            sender_id: this.user.id,
            sender_home: this.home,
            sender_name: this.user.name,
          },
        })
        this.debounce_permit = false
        setTimeout(() => {
          this.debounce_permit = true
        }, 1000)
      },
      blur_handler(e) {
        e.target.style.height = 'auto'
      },

      emojiPopupOpen() {
        this.setDataForModal({
          data: {
            cb: function (em) {
              this.message += em
              this.emojiis.add(em)
            }.bind(this),
          },
        })
        this.processModal({ name: 'emojii' })
      },

      openPrivateChatTab(target_user_indicator) {
        const my_user_indicator = this.tabType === 'main_chat' ? this.user.id : this.home || this.user.id
        if (target_user_indicator === my_user_indicator) return

        const chat_id = genPrivateChatId(target_user_indicator, my_user_indicator)

        const private_chats = this.tabType === 'main_chat' ? this.main_private_chats : this.game_private_chats
        const target_room_id = this.tabType === 'main_chat' ? 'mainRoom' : this.game.id
        const setTab = this.tabType === 'main_chat' ? this.setMainSideTab : this.setGameSideTab
        const tabName = this.tabType === 'main_chat' ? 'privateChatActive' : 'privateGameChatActive'

        const existed_pr_chat = private_chats.find((ch) => ch.users.includes(target_user_indicator))
        if (!existed_pr_chat) {
          if (this.game_tab) {
            const player = this.game.players.find((pl) => pl.home === target_user_indicator)
            if (!player?.id) return
          }
          this.setTempPrivateChat({
            room_id: target_room_id,
            target_user_id: target_user_indicator,
            chat_id,
            my_home: this.home,
          })
        }
        this.selectPrivateChat({ chat_id: existed_pr_chat?.id || chat_id, room_id: target_room_id })
        setTab({ tab: tabName })
      },

      openConfirmComplaintModal(msg) {
        const data = {
          processed_user: {
            avatar: msg.sender_avatar,
            nickname: msg.sender_name,
            target_id: msg.original_sender_id,
            type: 'chat',
            complaint_data: {
              text: msg.text,
            },
          },
        }
        this.setDataForModal({ data })
        this.processModal({ name: 'complaint' })
      },

      onScrollArea(e) {
        const area = e.target
        const bottom = Math.abs(area.scrollHeight - area.scrollTop - area.clientHeight) < 1

        if (!bottom) return
        if (!this.unread_messages_count) return

        this.socket.emit('client_action', {
          action_type: 'ReadMyMessages',
          data: {
            user_id: this.user.id,
            room_id: this.room.id,
          },
        })
      },

      scrollToMessage(reply_id) {
        const el_component = this.$refs[reply_id]?.[0]
        const el = this.$refs[reply_id]?.[0].$el

        el.scrollIntoView({ behavior: 'smooth', block: 'center' })
        el_component.temp_message_hint()
      },
      async cleanChatHistory() {
        this.message = ''

        this.setDataForModal({
          data: {
            cb: function (result) {
              if (!result) return
              this.socket.emit('client_action', {
                action_type: 'DeleteMessage',
                data: {
                  id: '',
                  room_id: this.room.id,
                  sender_id: '',
                  target_id: '',
                  chat_type: this.tabType,
                  type: 'del_all',
                },
              })
            }.bind(this),
            type: 'delete_all_messages',
          },
        })
        this.processModal({ name: 'confirm_del' })
      },

      resetReply() {
        this.reply_obj = null
      },
      normalizeReplyText(text) {
        return text.slice(0, 12) + '...'
      },
      getDate(ms) {
        return new Date(ms).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
      },

      setScrollToBottom() {
        const el = this.$refs.chat_area
        if (!el) return
        el.scrollTop = 100000
      },
      dispatchScrollEvent() {
        const el = this.$refs.chat_area

        const scrollEvent = new Event('scroll')
        el.dispatchEvent(scrollEvent)
      },

      reply({ id, sender_name, sender_id, target_id, sender_home, text, home = 'watcher', time }) {
        this.reply_obj = {
          id,
          sender_name,
          sender_id,
          sender_home,
          target_id,
          text,
          home,
          time,
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .paddingTopForLobby {
    padding-top: $nav_buttons_top_height !important;
  }
  .row_reverse {
    flex-direction: row-reverse !important;
  }

  .rotate_icon {
    transform: rotate(180deg);
  }
  .reverse_scroll {
    direction: rtl;
  }

  .chat_container {
    display: flex;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;

    .chat_area_wr {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: $space_for_topbtns_and_logger;
      padding-bottom: env(safe-area-inset-bottom);

      @media (min-aspect-ratio: 4/3) {
        padding-top: $space_for_topbtns_and_logger_shrinked;
      }

      .chat_area_scroll_container {
        box-sizing: border-box;
        padding: 0 0.2rem;
        height: 100%;
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        &::-webkit-scrollbar {
          width: 1rem;
        }

        &::-webkit-scrollbar-track {
          background: grey;
          border-left: 0.45rem solid $theme_background;
          border-right: 0.45rem solid $theme_background; /* color of the tracking area */
        }

        &::-webkit-scrollbar-thumb {
          background: grey; /* color of the scroll thumb */
          border-radius: 0.6rem; /* roundness of the scroll thumb */

          border-left: 0.3rem solid $theme_background;
          border-right: 0.3rem solid $theme_background;
        }

        .wraper_for_reverse_content_back {
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          direction: ltr;
          gap: 0.5rem;
          height: 100%;
          width: 100%;
        }
      }

      .form_wr {
        box-sizing: border-box;
        position: relative;
        display: flex;
        width: 100%;
        flex-direction: column;
        align-content: flex-start;
        height: auto;
        padding: 0.4rem;

        .reply_wr {
          box-sizing: border-box;
          align-self: flex-start;
          width: 80%;
          padding: 0.4rem;
          background-color: rgba(174, 137, 225, 0.821);
          border-radius: $theme_border_radius;
          .reply_title {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid black;
            .reply_reset {
              cursor: pointer;
              position: absolute;
              right: -20px;
              top: -11px;
              color: rgb(133, 56, 222);
            }
          }

          .reply_body {
            text-align: center;
          }
        }

        .input_wr {
          display: flex;
          align-items: center;
          gap: 1rem;
          padding: 10px;
          padding-bottom: 0px;
          padding-left: 0px;
          .new_messages_hint_wr {
            cursor: pointer;
            position: absolute;
            bottom: 30px;
            left: 0px;

            .circle_wr {
              position: relative;
              span {
                color: green;
                font-size: 2rem;
              }
            }

            .msgs_count_hint {
              pointer-events: none;
              position: absolute;
              width: 20px;
              height: 20px;
              color: gold;
              background-color: blue;
              border-radius: 50%;
              top: 0px;
              left: 21px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }

        // border: 1px solid red;
        textarea {
          resize: none;
          min-height: 1rem;
          height: auto;
          max-height: 8rem;
          overflow: hidden;
          width: 80%;
          background-color: black;
          color: White;
          line-height: 1rem;
        }
        .send_button {
          cursor: pointer;
          color: rgb(66, 183, 222);
          &:hover {
            color: orange;
          }
        }
        .open_emoji_button {
          color: white;
          cursor: pointer;
        }
        .clean_main_chat {
          cursor: pointer;
          position: absolute;
          font-size: 1rem !important;
          color: green;
          bottom: 0;
          left: 0;
        }

        .reverse_cleaner {
          right: 0;
          left: auto;
        }
      }
    }
  }

  .messages-enter-active,
  .messages-leave-active {
    transition: all 1s ease;
  }
  .messages-enter-from,
  .messages-leave-to {
    opacity: 0;
    // transform: translateY(30px);
  }
  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.35s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }
</style>
