<template>
  <div id="modal_back" class="modal_back" @click="close">
    <div class="modal_container blue_box_shadow hide_scrollbar">
      <div class="modal_body_wrapper prevent-select">
        <div class="top_section">
          <div v-if="processed_user" class="info_section">
            <div class="info_item">
              <span
                v-if="getAdminType(processed_user.id) === 'admin'"
                @pointerenter="hintLabelOnHover($event, { msg: 'hint_admin' })"
                @pointerleave="hintLabelOnHover"
                class="admin_hint material-icons"
              >
                stars
              </span>
              <span
                v-if="getAdminType(processed_user.id) === 'admin_helper'"
                @pointerenter="hintLabelOnHover($event, { msg: 'hint_admin_helper' })"
                @pointerleave="hintLabelOnHover"
                class="admin_hint material-icons"
              >
                military_tech
              </span>
              <span v-if="!input_username" class="nickname">{{ username }}</span>
              <span v-else>
                <input
                  v-model="username"
                  list="username_input"
                  class="nickname_input"
                  type="text"
                  minlength="3"
                  maxlength="20"
                  @keypress="pressEnter"
                />

                <datalist id="username_input">
                  <option v-for="name in processed_user?.nicknames_log" :key="name" :value="name"></option>
                </datalist>
              </span>
              <span
                class="material-icons edit_hint"
                @pointerenter="hintLabelOnHover($event, { msg: 'change_nickname' })"
                @pointerleave="hintLabelOnHover"
                @click="tryChangeNickname"
              >
                edit
              </span>
              <span
                v-if="username !== processed_user.name"
                class="material-icons edit_hint"
                @pointerenter="hintLabelOnHover($event, { msg: 'change_nickname' })"
                @pointerleave="hintLabelOnHover"
                @click="changeNickname"
              >
                save
              </span>
              <span
                v-if="username !== processed_user.name || input_username"
                class="material-icons edit_hint"
                @click="clearNickNameChanges"
              >
                cancel
              </span>
            </div>

            <div v-if="processed_user?.email?.value" class="info_item">
              <span>Email: {{ processed_user.email?.value }}</span>
            </div>
            <div v-if="isAdmin && !fileSelected" class="select_user_wr" @change="onSelectUser">
              <select name="">
                <option
                  v-for="user in users"
                  :key="user.id"
                  :value="user.id"
                  :selected="user.id === processed_user?.id"
                >
                  {{ user.name }}
                </option>
              </select>
            </div>
            <div v-if="!fileSelected" class="tokens_wr">
              <div class="tokens_count_inner">
                <img src="/images/money.png" alt="money" />
                <span>
                  {{ processed_user.money?.total }}
                </span>
              </div>
              <div
                v-if="!fileSelected && user.id === processed_user.id"
                class="donate_btn"
                @click="openModal('donate')"
              >
                {{ $t(`message.donate_msg`) }}
              </div>
            </div>

            <div v-if="isAdmin && !processed_user.role?.value.includes('admin')" class="ban_wr">
              <div class="ban_set_wr">
                <select id="ban_type" v-model="ban_type" name="ban">
                  <option value="play">play</option>
                  <option value="chat">chat</option>
                  <option value="avatar">avatar</option>
                  <option value="nickname">nickname</option>
                  <option value="private_chat">private_chat</option>
                </select>
                <select id="ban_time" v-model="ban_time" name="time">
                  <option value="minutes">minutes</option>
                  <option value="hours">hours</option>
                  <option value="days">days</option>
                </select>
                <input v-model="ban_duration" type="number" min="1" max="365" class="ban_duration" />
                <input v-model="ban_apply" type="checkbox" class="ban_check" />
                <div :class="{ disabled_ban_btn: !ban_apply, ban_btn: true }" @click="apply_ban">Ban</div>
              </div>
            </div>
          </div>
          <div class="ava_wr">
            <div class="ava_img_wr">
              <img ref="avatar" :src="cur_avatar" alt="ava" class="ava" />
            </div>
            <div v-if="!fileSelected" class="ava_tabs">
              <div
                v-for="(src, idx) in processed_user.avatars"
                :key="src"
                :class="{ ava_tab: true, tab_active: idx === ava_idx }"
              ></div>
            </div>
            <div v-if="!fileSelected && processed_user?.avatars?.length > 1" class="controls">
              <div class="btn" @click="selectAva('back')">
                <span class="material-icons"> chevron_left </span>
              </div>
              <div class="btn" @click="selectAva('forvard')">
                <span class="material-icons"> navigate_next </span>
              </div>
            </div>
            <div class="controls">
              <label for="file" v-if="!fileSelected" class="btn">
                <span
                  class="material-icons"
                  @pointerenter="hintLabelOnHover($event, { msg: 'upload_photo' })"
                  @pointerleave="hintLabelOnHover"
                >
                  add_a_photo
                </span>
              </label>
              <div class="hide">
                <form action="/avatar" method="POST" enctype="multipart/form-data" @submit="submit">
                  <input
                    id="file"
                    type="file"
                    name="avatar"
                    ref="avaLoader"
                    accept=".jpg, .jpeg, .png"
                    @change="changeFile"
                    @click="inputFileClick"
                    @cancel="cancelFromHandler"
                  />
                </form>
              </div>
              <div v-if="!fileSelected" class="btn" @click="processAvatar($event, 'delete')">
                <span
                  class="material-icons"
                  :style="{ marginTop: `2px` }"
                  @pointerenter="hintLabelOnHover($event, { msg: 'delete_photo' })"
                  @pointerleave="hintLabelOnHover"
                >
                  no_photography
                </span>
              </div>
              <div
                v-if="cur_avatar_filename !== processed_user.avatars[0] && !fileSelected"
                class="btn"
                @click="processAvatar($event, 'apply')"
              >
                <span
                  class="material-icons apply_ava"
                  :style="{ marginTop: `2px` }"
                  @pointerenter="hintLabelOnHover($event, { msg: 'apply_photo' })"
                  @pointerleave="hintLabelOnHover"
                >
                  save
                </span>
              </div>
            </div>
            <div v-if="fileSelected" class="controls">
              <div v-if="fileSelected" class="btn" @click="resetFile">
                <span
                  class="material-icons"
                  @pointerenter="hintLabelOnHover($event, { msg: 'reset_photo' })"
                  @pointerleave="hintLabelOnHover"
                >
                  cancel
                </span>
              </div>
              <div class="btn" @click="submitForm">
                <span
                  class="material-icons"
                  @pointerenter="hintLabelOnHover($event, { msg: 'upload_photo' })"
                  @pointerleave="hintLabelOnHover"
                >
                  save_alt
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="btns_section">
          <div class="change_btn" @click="openModal('new_email')">
            <span>{{ $t('message.change_email') }}</span>
          </div>
          <div class="change_btn" @click="openModal('new_password')">
            <span>{{ $t('message.change_password') }}</span>
          </div>
          <div class="change_btn" @click="openModal('ban_state')">
            <span>
              {{ $t(`message.ban_show_btn`) }}
            </span>
          </div>
          <div class="change_btn" @click="openModal('games_log')">
            <span>
              {{ $t(`message.game_story_btn`) }}
            </span>
          </div>
          <div class="change_btn" @click="showFriendsList">
            <span>
              {{ $t(`message.my_friends`) }}
            </span>
          </div>
          <div class="change_btn" @click="showBlackList">
            <span>
              {{ $t(`message.black_list`) }}
            </span>
          </div>
          <div v-if="isAdmin" class="change_btn" @click="openModal('complaint_list')">
            <span>
              {{ $t(`message.complaints`) }}
            </span>
          </div>

          <div class="change_btn" @click="openModal('money_log')">
            <span>
              {{ $t('message.money_log') }}
            </span>
          </div>
          <div
            v-if="isAdmin && processed_user.role?.value === 'admin'"
            class="change_btn"
            @click="openModal('pool_log')"
          >
            <span>
              {{ $t('message.pool_log') }}
            </span>
          </div>
          <div
            v-if="isAdmin && !processed_user.role?.value.includes('admin') && !del_msgs_loading"
            class="change_btn"
            @click="deleteAllMessages"
          >
            <span>
              {{ $t('message.delete_messages') }}
            </span>
          </div>
        </div>

        <div class="table_wr">
          <span class="sub_title">{{ $t(`message.sessions`) }}</span>
          <div v-if="processed_user?.sessions?.length" class="sessions_table">
            <table class="sessions_table">
              <thead>
                <tr>
                  <th><span class="material-icons"> schedule </span></th>
                  <th>SID</th>
                  <th>IP</th>
                  <th><span class="material-icons"> computer </span></th>
                  <th>OS</th>
                  <th v-if="processed_user.sessions.length"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="session in processed_user.sessions"
                  :key="session.sid"
                  :class="{ hint_session: sid === session.sid }"
                >
                  <td>{{ session.time }}</td>
                  <td>{{ session.sid }}</td>
                  <td>{{ session.ip }}</td>
                  <td>{{ session.browser.name }}</td>
                  <td>{{ session.browser.os }}</td>
                  <td>
                    <span class="material-icons del_session" @click="closeOneSession(session.sid)"> logout </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else class="empty">---</div>
        </div>
        <div class="table_wr">
          <span class="sub_title">{{ $t(`message.games`) }}</span>
          <div v-if="processed_user.current_games.length" class="games_table">
            <table>
              <thead>
                <tr>
                  <th>{{ $t(`message.game_name`) }}</th>
                  <th>{{ $t(`message.game_type`) }}</th>
                  <th>{{ $t(`message.home`) }}</th>
                  <th>SID</th>
                  <th v-if="processed_user.current_games.length"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="game in processed_user.current_games"
                  :key="game.sid"
                  :class="{ hint_game: game.room_id === current_game_id }"
                >
                  <td>{{ game.name }}</td>
                  <td>{{ game.type }}</td>
                  <td>{{ game.home }}</td>
                  <td>{{ game.sid }}</td>
                  <td>
                    <span class="material-icons del_session" @click="leaveOneGame(game.room_id)"> logout </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else class="empty">---</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { price } from '#static_data'
  import Cropper from 'cropperjs'
  import { mapGetters, mapMutations, mapState } from 'vuex'

  export default {
    inject: ['socket'],

    data() {
      return {
        ban_type: 'play',
        ban_time: 'minutes',
        ban_duration: 1,
        ban_apply: false,
        ava_idx: 0,
        selected_user: null,
        username: null,
        input_username: false,
        price,
        hint_delay: null,
        clear_delay: null,
        listTitle: '',
        listName: '',
        fileSelName: '',
        fileSelected: false,
        cropper: null,
        photo: null,
        del_msgs_loading: false,
      }
    },

    computed: {
      ...mapState('general_store', ['sid', 'users', 'error']),
      ...mapGetters('general_store', ['mainRoom', 'game', 'user', 'isAdmin', 'current_game_id', 'inside_room']),

      processed_user() {
        if (!this.selected_user) {
          return this.user
        }
        return this.users.find((u) => u.id === this.selected_user)
      },

      cur_avatar() {
        if (!this.processed_user.avatars?.[this.ava_idx]) return `/images/avatars/${this.processed_user.avatars?.[0]}`
        return `/images/avatars/${this.cur_avatar_filename}`
        // return new URL(`./avatars/${this.cur_avatar_filename}`, import.meta.url).href;
        // return `${this.cur_avatar_filename}`;
      },
      cur_avatar_filename() {
        return this.processed_user.avatars?.[this.ava_idx || 0]
      },
      avaSrc() {
        // return `/assets/${this.processed_user.avatar}`;
        try {
          return `/images/avatars/${this.processed_user.avatars[0]}`
        } catch (e) {
          return ''
        }
      },
    },

    watch: {
      'user.avatars': function () {
        this.ava_idx = 0
      },
      error() {
        if (!this.error) return
        this.resetFile()
      },
    },

    mounted() {
      this.username = this.processed_user?.name
      // this.socket.on("resetAva", async (err) => {
      //   const avatar = this.$refs.avatar;
      //   avatar.src = this.avaSrc;
      // });
    },

    methods: {
      ...mapMutations('gui_store', ['setHintLabel']),
      ...mapMutations('general_store', ['processModal', 'setDataForModal']),

      openModal(name) {
        this.setDataForModal({
          data: { processed_user: { ...this.processed_user } },
        })
        this.processModal({ name })
      },

      close(e) {
        if (e.target.id !== 'modal_back') return
        this.processModal()
        this.setDataForModal({ reset: true })
      },

      tryChangeNickname() {
        if (this.user.ban.nickname.value) {
          this.openModal('ban_state')
          this.socket.emit('client_action', {
            action_type: 'BanUser',
            data: {
              user_id: this.user.id,
              target_user_id: this.user.id,
              update: true,
            },
          })
          this.input_username = false
          return
        }
        this.input_username = true
      },

      apply_ban() {
        if (!this.ban_apply) return
        this.socket.emit('client_action', {
          action_type: 'BanUser',
          data: {
            user_id: this.user.id,
            target_user_id: this.processed_user.id,
            type: this.ban_type,
            value: this.ban_apply,
            duration: this.ban_duration,
            duration_type: this.ban_time,
          },
        })

        this.ban_type = 'play'
        this.ban_time = 'minutes'
        this.ban_duration = 1
        this.ban_apply = false
      },

      showFriendsList() {
        this.setDataForModal({
          data: { list_type: 'my_friends', processed_user: this.processed_user },
        })
        this.processModal({ name: 'social' })
      },
      showBlackList() {
        this.setDataForModal({
          data: { list_type: 'black_list', processed_user: this.processed_user },
        })
        this.processModal({ name: 'social' })
      },

      selectAva(flag) {
        if (flag === 'back') {
          this.ava_idx--
          if (this.ava_idx < 0) {
            this.ava_idx = this.processed_user.avatars.length - 1
          }
        } else if (flag === 'forvard') {
          this.ava_idx++
          if (this.ava_idx > this.processed_user.avatars.length - 1) {
            this.ava_idx = 0
          }
        }
      },

      async onSelectUser(e) {
        this.selected_user = e.target.value
        const user = this.users.find((u) => u.id === this.selected_user)

        this.username = user?.name
      },

      async processAvatar(e, flag) {
        e.preventDefault()

        if (flag === 'apply') {
          this.socket.emit('client_action', {
            action_type: 'AddAvatar',
            data: {
              filename: this.cur_avatar_filename,
              user_id: this.processed_user.id,
              room_id: 'mainRoom',
              select: true,
            },
          })
        }
        if (flag === 'delete') {
          this.setDataForModal({
            data: {
              cb: function (result) {
                if (!result) return
                this.socket.emit('client_action', {
                  action_type: 'DeleteAvatar',
                  data: { filename: this.cur_avatar_filename, user_id: this.processed_user.id, room_id: 'mainRoom' },
                })
                this.ava_idx = 0
              }.bind(this),
              type: 'delete_avatar',
            },
          })
          this.processModal({ name: 'confirm_del' })
        }
      },

      clearNickNameChanges() {
        this.username = this.processed_user.name
        this.input_username = false
      },

      pressEnter(e) {
        let code = e.keyCode ? e.keyCode : e.which
        if (code == 13) {
          //Enter keycode
          e.preventDefault()
          this.changeNickname()
        }
      },

      async deleteAllMessages(e) {
        this.setDataForModal({
          data: {
            cb: function (result) {
              if (!result) return
              this.del_msgs_loading = true
              setTimeout(() => {
                this.del_msgs_loading = false
              }, 1000)
              this.socket.emit('client_action', {
                action_type: 'ChangeUserByAdmin',
                data: {
                  user_id: this.processed_user.id,
                  delete_messages: true,
                },
              })
            }.bind(this),
            type: 'delete_all_messages',
          },
        })
        this.processModal({ name: 'confirm_del' })
      },

      async changeNickname(e) {
        this.processModal({
          name: 'confirm_pay',
          type: 'open',
        })
        this.setDataForModal({
          data: {
            title: 'change_nickname',
            cb: (result) => {
              if (!result) {
                return
              }
              this.socket.emit('client_action', {
                action_type: 'ChangeNickname',
                data: { nickname: this.username, user_id: this.processed_user.id, room_id: 'mainRoom' },
              })
              this.input_username = false
            },
          },
        })
      },

      async closeOneSession(sid) {
        this.setDataForModal({
          data: { action_type: 'LeaveApp', sid, processed_user: this.processed_user },
        })
        this.processModal({ name: 'leave_room' })
      },

      async leaveOneGame(room_id) {
        this.setDataForModal({
          data: { action_type: 'LeaveGame', room_id, processed_user: this.processed_user },
        })
        this.processModal({ name: 'leave_room' })
      },

      inputFileClick(e) {
        if (this.user.ban.avatar.value) {
          e.preventDefault()

          this.processModal({ name: 'ban_state' })
          this.socket.emit('client_action', {
            action_type: 'BanUser',
            data: {
              user_id: this.user.id,
              target_user_id: this.user.id,
              update: true,
            },
          })
          return
        }
      },
      hintLabelOnHover(e, item) {
        if (!item) {
          window.clearTimeout(this.hint_delay)
          window.clearTimeout(this.clear_delay)
          this.hint_delay = null
          this.clear_delay = null
          this.setHintLabel({ reset: true })
          return
        }
        this.hint_delay = setTimeout(() => {
          const coords = e.target.getBoundingClientRect()
          const logYpos = `translate(${coords.left}px, ${coords.top - 20}px)`
          this.setHintLabel({
            msg: item.msg,
            coords: logYpos,
            data: item.data,
          })

          this.clear_delay = setTimeout(() => {
            window.clearTimeout(this.hint_delay)
            window.clearTimeout(this.clear_delay)
            this.hint_delay = null
            this.clear_delay = null
            this.setHintLabel({ reset: true })
          }, 3000)
        }, 550)
      },

      async submitForm(e) {
        this.processModal({
          name: 'confirm_pay',
          type: 'open',
        })
        this.setDataForModal({
          data: {
            title: 'change_avatar',
            cb: (result) => {
              if (!result) {
                this.resetFile()
                e.target.value = null
                return
              }
              const filename = this.fileSelName
              this.cropper
                .getCroppedCanvas({
                  width: 300,
                  height: 300,
                })
                .toBlob(
                  async function (blob) {
                    this.photo = await this.resizeImage({ imageFile: blob })
                    this.socket.emit('client_action', {
                      action_type: 'AddAvatar',
                      data: { photo: this.photo, filename, user_id: this.processed_user.id, room_id: 'mainRoom' },
                    })
                    // }
                    this.resetFile(false)
                  }.bind(this),
                  'image/jpg'
                )
            },
          },
        })
      },

      cancelFromHandler(e) {
        this.resetFile()
        e.target.value = null
      },

      changeFile(e) {
        if (e.target.files[0]) {
          this.fileSelected = true
          this.photo = e.target.files[0]
          this.fileSelName = e.target.files[0].name

          const avatar = this.$refs.avatar
          avatar.src = URL.createObjectURL(e.target.files[0])

          this.cropper = new Cropper(avatar, {
            aspectRatio: 1,
            viewMode: 1,
            dragMode: 'move',
            minCropBoxHeight: 300,
            minCropBoxWidth: 300,
            minContainerWidth: 300,
            minContainerHeight: 300,
            cropBoxMovable: false,
            cropBoxResizable: false,
            guides: false,
            center: false,
            background: false,
          })
        } else {
          this.photo = null
          this.fileSelName = ''
          this.fileSelected = false
        }
        e.target.value = ''
      },

      resetFile(restorAva = true) {
        const avaLoader = this.$refs.avaLoader

        avaLoader.value = ''
        this.fileSelected = false
        this.fileSelName = ''
        this.cropper?.destroy?.()
        if (restorAva) {
          const avatar = this.$refs.avatar
          if (!avatar) return
          avatar.src = `/images/avatars/${this.processed_user.avatars[0]}`
        }
      },

      resizeImage({ resize_width = 300, resize_height = 300, imageFile, quality = 1 }) {
        quality = Math.max(Math.min(quality, 1), 0) // 0 <= quality <= 1

        return new Promise((resolve) => {
          //create a FileReader
          var reader = new FileReader()

          //image turned to base64-encoded Data URI.
          reader.readAsDataURL(imageFile)
          let srcEncoded

          reader.onload = () => {
            var img = new Image() //create a image
            img.src = reader.result //result is base64-encoded Data URI

            img.onload = () => {
              var canvas = document.createElement('canvas') //create a canvas

              //scale the image to 600 (width) and keep aspect ratio
              var scaleFactor = resize_width / img.width
              canvas.width = img.width * scaleFactor
              canvas.height = img.height * scaleFactor

              //draw in canvas
              var ctx = canvas.getContext('2d')
              ctx.drawImage(img, 0, 0, canvas.width, canvas.height)

              //get the base64-encoded Data URI from the resize image
              srcEncoded = canvas.toDataURL(imageFile.type, quality)

              resolve(srcEncoded)
            }
          }
        })
      },
      getAdminType(uid) {
        const u = this.users.find((u) => u.id === uid)
        if (!u?.role.value.includes('admin')) return false
        return u?.role.value
      },
    },
  }
</script>

<style lang="scss" scoped="true">
  .hide {
    display: none;
  }
  .modal_back {
    position: relative;
    width: 100dvw;
    height: 100dvh;
    background-color: $modals_background_color;
    pointer-events: all;
  }

  .modal_container {
    position: absolute;
    padding: 1rem;
    width: auto;
    height: 100%;
    max-width: $modals_max_width;
    min-width: 80dvw;
    max-height: $modals_max_height;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: $theme_border_radius;
    background-color: $modals_color;
  }

  .modal_body_wrapper {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    gap: 1rem;
    overflow-y: auto;

    .top_section {
      display: grid;
      gap: 1rem;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

      .ava_wr {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1rem;
        .ava_img_wr {
          display: flex;
          justify-content: flex-start;
          .ava {
            display: block;

            max-width: 95%;
            border-radius: 0.4rem;
            border: 3px solid grey;
          }
        }
        .ava_tabs {
          width: 100%;
          display: flex;
          justify-content: center;
          gap: 1rem;
          height: auto;

          .ava_tab {
            width: 2rem;
            height: 5px;
            background-color: white;
          }
          .tab_active {
            background-color: rgb(165, 97, 97);
          }
        }
        .controls {
          width: 100%;
          display: flex;
          gap: 0.2rem;
        }
        .btn {
          display: flex;
          flex: 1;
          text-align: center;
          align-items: center;
          justify-content: center;
          padding: 0.2rem 0;
          cursor: pointer;
          color: white;
          background-color: rgba(102, 101, 101, 0.596);
          &:hover {
            background-color: grey;
            color: black;
          }
        }
      }
    }

    .info_section {
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 1rem;
      .info_item {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        gap: 0.5rem;

        .change_btn {
          background-color: rgb(30, 102, 110);
          padding: 5px;
          width: 5rem;
          height: 2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 0.3rem;
          cursor: pointer;
          &:hover {
            background-color: rgb(15, 72, 78);
          }
          span {
            text-align: center;
            height: fit-content;
          }
        }

        .ban_wr {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          .ban_set_wr {
            display: flex;
            width: 100%;
            height: 30px;
            gap: 1rem;
            padding: 5px;
            select {
              width: fit-content;
              background-color: grey;
            }

            .ban_duration {
              width: 35px;
              background-color: grey;
            }
            .ban_btn {
              padding: 10px;
              text-align: center;
              align-items: center;
              border-radius: 5px;
              cursor: pointer;
              background-color: rgb(109, 170, 29);
              &:hover {
                background-color: rgb(226, 12, 12);
              }
            }

            .disabled_ban_btn {
              cursor: not-allowed;
              // pointer-events: none;
              background-color: rgb(83, 80, 80);
              &:hover {
                background-color: rgb(83, 80, 80);
              }
            }
          }
        }
        .nickname {
          font-size: 1.5rem;
          white-space: nowrap;
          max-width: 90%;
          overflow-x: hidden;
        }

        .edit_hint {
          cursor: pointer;
          &:hover {
            color: rgb(121, 201, 121);
          }
        }

        .payment_hint {
          color: green;
          font-size: 1rem;
        }
      }
      .tokens_wr {
        display: flex;
        gap: 1rem;
        .tokens_count_inner {
          font-size: 1.4rem;
          color: gold;
          display: flex;
          align-items: center;
        }
        .donate_btn {
          cursor: pointer;
          background-color: rgba(31, 167, 31, 0.829);
          padding: 1rem;
          border-radius: 0.4rem;
          text-align: center;
          &:hover {
            background-color: #86ba6f;
          }
        }
        img {
          width: 50px;
        }
      }
    }

    .btns_section {
      width: 100%;
      display: flex;
      gap: 0.5rem;
      flex-wrap: wrap;
      justify-content: flex-start;

      .change_btn {
        background-color: rgb(22, 59, 63);
        font-size: 0.9rem;
        padding: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        height: auto;
        text-align: center;
        border-radius: 0.3rem;
        cursor: pointer;
        color: white;
        &:hover {
          background-color: rgb(45, 144, 155);
          color: black;
        }
      }
    }

    .table_wr {
      .empty {
        width: 100%;
        color: white;
        font-size: 2rem;
        text-align: center;
      }
      .sub_title {
        color: white;
        font-size: 1.2rem;
      }
      table {
        margin-top: 0.5rem;
      }

      .sessions_table {
        overflow-x: auto;
        .hint_session {
          font-weight: 700;
          background-color: #575454;
        }
        table {
          color: white;
          border-collapse: collapse;
          width: 100%;

          .del_session {
            color: rgb(225, 117, 117);
            font-size: 1rem;
            cursor: pointer;
            &:hover {
              color: red;
            }
          }
        }

        table,
        th,
        td {
          border: 1px solid rgb(189, 184, 184);
        }

        td {
          text-align: center;
          font-size: 0.8rem;
          padding: 5px;
        }

        th {
          padding: 5px 0;
          text-align: center;
          span {
            font-size: 1.1rem;
          }
        }
      }
      .games_table {
        overflow-x: auto;
        .hint_game {
          font-weight: 700;
          background-color: #575454;

          // text-decoration: underline;
        }
        table {
          color: white;
        }

        table,
        th,
        td {
          border: 1px solid rgb(189, 184, 184);
        }

        td {
          text-align: center;
          font-size: 1rem;
          padding: 5px;
        }

        table {
          border-collapse: collapse;
          width: 100%;
        }
        th {
          padding: 5px 0;
          text-align: center;
          font-size: 1rem;
        }
      }
    }
  }

  .admin_hint {
    cursor: pointer;
    color: gold;
  }

  .prevent-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }

  input[type='file'] {
    display: none;
  }

  .nickname_input {
    &:invalid {
      background-color: red;
    }
  }

  .apply_ava {
    color: white;
    cursor: pointer;
    &:hover {
      color: red;
    }
  }

  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.5s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }
</style>
