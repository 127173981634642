<template>
  <div id="modal_base" class="modal_base" @click="close">
    <div class="modal_container green_box_shadow">
      <div class="tabs_wr">
        <div class="tab_wr" @click="selectPage('features')" :class="{ selectedPage: pages.features }">
          {{ $t('message.features') }}
        </div>
        <div class="tab_wr" @click="selectPage('gameplay')" :class="{ selectedPage: pages.gameplay }">
          {{ $t('message.gameplay') }}
        </div>
      </div>
      <div class="body_wr">
        <GameFeatures v-if="pages.features" />
        <GameGameplay v-if="pages.gameplay" />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex'
  export default {
    data() {
      return {
        pages: {
          features: true,
          gameplay: false,
        },
      }
    },

    computed: {
      ...mapGetters('general_store', ['user']),
    },

    methods: {
      ...mapMutations('general_store', ['processModal']),
      close(e) {
        if (e.target.id !== 'modal_base') return
        this.processModal({ name: 'game_info', type: 'close' })
      },
      selectPage(page) {
        for (let key in this.pages) {
          this.pages[key] = false
        }
        this.pages[page] = true
      },
    },
  }
</script>

<style lang="scss" scoped>
  .modal_base {
    position: fixed;
    pointer-events: all;
    z-index: 50;
    background-color: $modals_background_color;
    width: 100dvw;
    height: 100dvh;
    top: 0;
    left: 0;
  }

  .modal_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    box-sizing: border-box;
    left: 50%;
    top: 50%;
    padding: 2rem;
    transform: translate(-50%, -50%);
    width: $modals_max_width;
    height: 90%;
    gap: 0.5rem;
    background-color: $modals_color;
    border-radius: 0.4rem;
    align-items: center;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    @media (max-height: 500px), (max-width: 950px) {
      padding: 1rem; // This applies if either condition is met
    }
  }

  .tabs_wr {
    display: flex;
    width: 100%;
    border: 1px solid white;
    border-radius: 0.4rem;
    // padding: 0.2rem;
    box-sizing: border-box;
    .selectedPage {
      background-color: rgb(71, 71, 70);
    }
    .tab_wr {
      flex: 1;
      display: flex;
      justify-content: center;
      color: white;
      padding: 0.5rem 0;
      border-right: 1px solid white;
      cursor: pointer;

      //   border-radius: 0.4rem;
      &:last-child {
        border-right: none;
      }
      &:hover {
        background-color: rgb(71, 71, 70);
      }
      transition: background-color 0.35s;
    }
  }
  .body_wr {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
</style>
