<template>
  <div id="confirm_modal_wr" class="confirm_modal_wr" @click="close">
    <div class="inner_wr">
      <div v-if="west_history.length" class="table">
        <div v-for="log in west_history" :key="log" class="row">
          <div class="round">{{ log.round }}</div>
          <div class="cards">
            <div class="westros_wr">
              <div class="num">I.</div>
              <div v-for="card in log.w0" :key="card" class="card">
                <div class="main">{{ $t(`phase.${card.title}`) }}</div>
                <div v-if="card.title === 'Winter'">
                  <span class="material-icons"> restart_alt </span>
                </div>
                <div v-if="card.wilding_point" class="wilding_token">
                  <img src="/images/tokens/wilding_token_track.png" alt="wild" />
                </div>
              </div>
            </div>

            <div class="westros_wr">
              <div class="num">II.</div>
              <div v-for="card in log.w1" :key="card" class="card">
                <div class="main">{{ $t(`phase.${card.title}`) }}</div>
                <div v-if="card.title === 'Winter'">
                  <span class="material-icons reset_icon"> restart_alt </span>
                </div>
                <div v-if="card.wilding_point" class="wilding_token">
                  <img src="/images/tokens/wilding_token_track.png" alt="wild" />
                </div>
              </div>
            </div>
            <div class="westros_wr">
              <div class="num">III.</div>
              <div v-for="card in log.w2" :key="card" class="card">
                <div class="main">{{ $t(`phase.${card.title}`) }}</div>
                <div v-if="card.wilding_point" class="wilding_token">
                  <img src="/images/tokens/wilding_token_track.png" alt="wild" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="empty">---</div>
    </div>
  </div>
</template>

<script>
  import { mapMutations, mapState, mapGetters } from 'vuex'
  export default {
    computed: {
      ...mapState('general_store', ['data_for_modal']),
      ...mapGetters('general_store', ['mainRoom', 'game', 'user']),

      west_history() {
        if (!this.game) return
        return this.game.state.westeros_history
      },
    },

    methods: {
      ...mapMutations('general_store', ['processModal']),

      close(e) {
        this.processModal()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .confirm_modal_wr {
    position: absolute;
    pointer-events: all;
    width: 100dvw;
    height: 100dvh;

    background-color: $modals_background_color;
    z-index: 20;
    top: 0;
    left: 0;
  }

  .inner_wr {
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 50%;
    padding: 2rem;
    box-sizing: border-box;
    transform: translate(-50%, -50%);
    color: White;
    width: auto;
    height: auto;
    max-height: $modals_max_height;
    max-width: $modals_max_width;
    overflow-y: auto;
    align-items: center;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    cursor: all-scroll;
    border-radius: 0.4rem;
    background-color: $modals_color;
    -webkit-box-shadow: -1px -4px 97px 31px rgb(95, 211, 134);
    -moz-box-shadow: -1px -4px 97px 31px rgb(95, 211, 134);
    box-shadow: -1px -4px 97px 31px rgb(95, 211, 134);
  }

  .table {
    width: 100%;
  }

  .inner_wr::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .inner_wr {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .row {
    display: flex;
    gap: 1rem;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid white;
  }
  .round {
    flex: 1;
    font-size: 1.5rem;
    color: rgb(35, 211, 223);
  }
  .cards {
    flex: 4;
  }

  .westros_wr {
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  .num {
    width: 2rem;
    text-align: center;
  }

  .reset_icon {
    color: red;
  }

  .card {
    width: auto;
    display: flex;
    align-items: center;
    gap: 0.3rem;
  }
  .wilding_token {
    img {
      width: 1rem;
    }
  }

  .main {
    white-space: nowrap;
  }
</style>
