<template>
  <div class="main_app_container">
    <router-view />
  </div>
</template>

<script>
  export default {
    // mounted() {
    //   document.addEventListener(
    //     'touchstart',
    //     function (event) {
    //       if (event.touches.length > 1) {
    //         event.preventDefault()
    //         return
    //       }
    //     },
    //     { passive: false }
    //   )
    // },
  }
</script>

<style lang="scss" scoped>
  .main_app_container {
    overflow: hidden;
    height: 100dvh;
    width: 100dvw;
    user-select: none;
    background-image: $main_background_image;
    background-size: cover; /* Adjusted for better background fitting */
    background-position: center; /* Optional: Center the background image */
    background-repeat: no-repeat;
  }
</style>
